<template>
  <div class="whitesmoke-back">
    <Header />
    <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <img src="../../assets/images/fishImg.jpg" class="eventImg" />
      <div class="bottom-left">
        <p class="eventSmall whiteText">East Canyon State Park</p>
        <h3 class="eventLarge whiteText">Fishing Challenge</h3>
      </div>
    </div>

    <div class="page-wrap">
      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>

      <!-- Bison Roundup Link -->
      <div class="shopifyFlex">
        <div class="flexWrap">
          <div class="shopifyBox">
            <div
              id="collection-component-1616176464282"
              class="collectionDiv"
            ></div>
            <div id="loader"></div>
          </div>
          <div class="shopifyWrap2">
            <div class="shopifyBox eventBox infoBox">
              <h2 class="eventInfoTitle">Official Rules</h2>
              <p class="eventTitle">CONTEST OVERVIEW</p>
              <p class="eventText">
                The East Canyon State Park Fishing Challenge (contest) begins at
                8 a.m. on September 20, 2021 and ends December 31th 2021 at 5
                p.m. Mountain Time ("MT") (the "Contest Period"). The Contest
                provides anglers to participate in the Contest, the opportunity
                to catch tagged fish that can be redeemed for prizes. Businesses
                (“Sponsors”) will be solicited to sponsor a tagged fish. By
                participating in the Contest, each entrant unconditionally
                accepts and agrees to comply with and abide by these Official
                Rules and the decisions of Utah State Parks, which shall be
                final and binding in all respects.
              </p>
              <p class="eventTitle">ELIGIBILITY</p>
              <p class="eventText">
                The Contest is open only to legal U.S. residents. Registration
                for the is required. A valid Utah Fishing or Combination license
                is required. Contest is void where prohibited by law. Employees,
                officers and directors of Sponsor, Official Contest Fish
                Taggers, prize providers, and each of their respective parents,
                subsidiaries, affiliates, distributors, retailers, sales
                representatives, advertising and promotion agencies
                (collectively, the Contest Entities), and their immediate family
                members and/or those living in the same household of each are
                ineligible to enter the Contest or win a prize. Only individuals
                may participate.
              </p>
              <p class="eventTitle">HOW IT WORKS</p>
              <p class="eventText">
                Prior to the start of the contest period, DWR and USP will
                prepare the tagged fish. The tagged rainbow trout will be
                released into East Canyon Reservoir. Participant must register
                for the event before turning in a tagged fish. If a participant
                catches a tagged fish they will have an opportunity to enter a
                drawing for a prize. Then the Officials will check the tag fish
                during the contest period, he/she must present the tag to a
                staff member at East Canyon State Park and he/she will draw for
                a prize. Once the prize has been drawn, they may redeem the
                prize at the park office or at the business sponsor that was
                drawn. Once all the prizes have been drawn, the event will end,
                regardless of the event time frame.
              </p>
              <p class="eventText">
                No prizes will be awarded to any participant presenting a tagged
                fish that has been caught other than during the contest period.
                Tags must be presented to a staff member of East Canyon State
                Park during the contest period. All tagged fish must be caught
                in accordance with all state and local laws, codes, rules and
                regulations. Rules and regulations can be found at
                http://wildlife.utah.gov/dwr/fishing/. Anglers are allowed to
                enter up to 2 tagged trout per angler. Angers may catch more
                tagged fish, but will not be able to enter more than 2 tagged
                trout. Sponsor will have no obligation to award any prize to any
                participant who does not comply with state and local laws,
                codes, rules and regulations.
              </p>
              <p class="eventTitle">PUBLICITY RELEASE</p>
              <p class="eventText">
                By participating in the Contest, in addition to any other grants
                which may be granted in any other agreement entered into between
                Officials and any participant in or winner of the Contest, each
                participant irrevocably grants the Contest Entities and their
                respective successors, assigns and licensees, the right to use
                such participant's name, likeness, and biographical information
                and any photos that may be submitted to the Website, in any and
                all media for any purpose, including without limitation,
                advertising and promotional purposes and hereby release the
                Contest Entities from any liability with respect thereto.
              </p>
              <p class="eventTitle">SPONSOR PRIZES</p>
              <p class="eventText">Upon request</p>
              <p class="eventTitle">GENERAL PRIZE CONDITIONS</p>
              <p class="eventText">
                Prize winners, if any, shall be solely responsible for all
                federal, state and/or local taxes, and the reporting
                consequences thereof, and for any other fees or costs associated
                with the applicable prize. Any potential prize winner may be
                required to execute an Affidavit of Eligibility, a Liability
                Release, and (where imposing such condition is legal) a
                Publicity Release (collectively, "Prize Claim Documents").
              </p>
              <div class="mobileShow">
                <p class="eventTitle">GENERAL LIABILITY RELEASE</p>
                <p class="eventText">
                  By participating in the Contest, each entrant agrees that the
                  Contest Entities (i) shall not be responsible or liable for
                  any losses, damages or injuries of any kind resulting from
                  participation in the Contest or in any Contest related
                  activity, or from entrant's acceptance, receipt, possession
                  and/or use or misuse of any prize, and (ii) have not made any
                  warranty, representation or guarantee express or implied, in
                  fact or in law, with respect to any prize and specifically
                  disclaim all such warranties, including without limitation,
                  the implied warranties of merchantability and fitness for a
                  particular purpose. Prizes will only be awarded to a verified
                  winner. The Contest Entities assume no responsibility for any
                  damage to an entrant's or any other person's computer system
                  which is occasioned by accessing the Website or otherwise
                  participating in the Contest, or for any computer system,
                  phone line, hardware, software or program malfunctions or
                  other errors, failures, delayed computer transmissions or
                  network connections that are human or technical in nature.
                  Without limiting the generality of the foregoing, Sponsor is
                  not responsible for incomplete, illegible, misdirected,
                  misprinted, late, lost, damaged, stolen, or (if applicable)
                  postage-due submissions or prize notifications; or for lost,
                  interrupted, inaccessible or unavailable networks, servers,
                  satellites, Internet Service Providers, web sites, or other
                  connections; or for miscommunications, failed, jumbled,
                  scrambled, delayed, or misdirected computer, telephone or
                  cable transmissions; or for any technical malfunctions,
                  failures, or difficulties, printing errors, clerical,
                  typographical or other error in the offering or announcement
                  of the prize or in any prize notification documents, or other
                  errors of any kind or nature; or for the incorrect or
                  inaccurate capture of information, or the failure to capture
                  any information. If, for any reason, the Contest is not
                  capable of running as planned, including, due to infection by
                  computer virus, bugs, tampering, unauthorized intervention,
                  fraud, technical failures, or any other causes beyond the
                  control of Sponsor which corrupt or affect the administration,
                  security, fairness, integrity or proper conduct of this
                  Contest, Sponsor reserves the right in its sole discretion to
                  cancel, terminate, modify, suspend or extend the Contest Any
                  attempt by an entrant or any other individual to deliberately
                  damage the Website or undermine the legitimate operation of
                  the Contest, or to annoy, abuse, threaten or harass any other
                  person in connection with the Contest, is in violation of
                  criminal and civil laws and should such an attempt be made,
                  Sponsor reserves the right to disqualify the applicable
                  individual and seek damages and other remedies from any such
                  person to the fullest extent permitted by law. If any
                  provisions of these Official Rules are determined to be
                  invalid or unenforceable, these Rules shall otherwise remain
                  in effect and shall be construed in accordance with their
                  terms as if the invalid or unenforceable provision were not
                  contained herein.
                </p>
                <p class="eventTitle">GOVERNING LAW/JURISDICTION</p>
                <p class="eventText">
                  ALL ISSUES AND QUESTIONS CONCERNING THE CONSTRUCTION,
                  VALIDITY, INTERPRETATION AND ENFORCEABILITY OF THESE OFFICIAL
                  RULES OR THE RIGHTS AND OBLIGATIONS OF ENTRANTS OR SPONSOR IN
                  CONNECTION WITH THE CONTEST SHALL BE GOVERNED BY AND CONSTRUED
                  IN ACCORDANCE WITH THE INTERNAL LAWS OF THE STATE OF UTAH
                  WITHOUT GIVING EFFECT TO ANY CHOICE OF LAW OF CONFLICT OF LAW
                  RULES OR PROVISIONS THAT WOULD CAUSE THE APPLICATION OF ANY
                  OTHER STATE'S LAWS.
                </p>
                <p class="eventTitle">WINNERS LIST</p>
                <p class="eventText">
                  To obtain a copy of any legally-required winners list, send a
                  self-addressed stamped envelope to: East Canyon State Park,
                  5535 S. Highway 66, Morgan, UT 84050. All such requests must
                  be received within six (6) weeks after the end of the Contest
                  Period. Winners' names (if any) will also be posted on the
                  Website.
                </p>
                <p class="eventText">
                  Special Advisory, Utah: Before boating, fishing or recreating
                  on Utah waters, familiarize yourself on how to stop the spread
                  of Aquatic Nuisance Species. See this link before heading out
                  to any Utah water body: http://wildlife.utah.gov/habitat/ans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="waiverBox">
          <p class="eventTitle">GENERAL LIABILITY RELEASE</p>
          <p class="eventText">
            By participating in the Contest, each entrant agrees that the
            Contest Entities (i) shall not be responsible or liable for any
            losses, damages or injuries of any kind resulting from participation
            in the Contest or in any Contest related activity, or from entrant's
            acceptance, receipt, possession and/or use or misuse of any prize,
            and (ii) have not made any warranty, representation or guarantee
            express or implied, in fact or in law, with respect to any prize and
            specifically disclaim all such warranties, including without
            limitation, the implied warranties of merchantability and fitness
            for a particular purpose. Prizes will only be awarded to a verified
            winner. The Contest Entities assume no responsibility for any damage
            to an entrant's or any other person's computer system which is
            occasioned by accessing the Website or otherwise participating in
            the Contest, or for any computer system, phone line, hardware,
            software or program malfunctions or other errors, failures, delayed
            computer transmissions or network connections that are human or
            technical in nature. Without limiting the generality of the
            foregoing, Sponsor is not responsible for incomplete, illegible,
            misdirected, misprinted, late, lost, damaged, stolen, or (if
            applicable) postage-due submissions or prize notifications; or for
            lost, interrupted, inaccessible or unavailable networks, servers,
            satellites, Internet Service Providers, web sites, or other
            connections; or for miscommunications, failed, jumbled, scrambled,
            delayed, or misdirected computer, telephone or cable transmissions;
            or for any technical malfunctions, failures, or difficulties,
            printing errors, clerical, typographical or other error in the
            offering or announcement of the prize or in any prize notification
            documents, or other errors of any kind or nature; or for the
            incorrect or inaccurate capture of information, or the failure to
            capture any information. If, for any reason, the Contest is not
            capable of running as planned, including, due to infection by
            computer virus, bugs, tampering, unauthorized intervention, fraud,
            technical failures, or any other causes beyond the control of
            Sponsor which corrupt or affect the administration, security,
            fairness, integrity or proper conduct of this Contest, Sponsor
            reserves the right in its sole discretion to cancel, terminate,
            modify, suspend or extend the Contest Any attempt by an entrant or
            any other individual to deliberately damage the Website or undermine
            the legitimate operation of the Contest, or to annoy, abuse,
            threaten or harass any other person in connection with the Contest,
            is in violation of criminal and civil laws and should such an
            attempt be made, Sponsor reserves the right to disqualify the
            applicable individual and seek damages and other remedies from any
            such person to the fullest extent permitted by law. If any
            provisions of these Official Rules are determined to be invalid or
            unenforceable, these Rules shall otherwise remain in effect and
            shall be construed in accordance with their terms as if the invalid
            or unenforceable provision were not contained herein.
          </p>
          <p class="eventTitle">GOVERNING LAW/JURISDICTION</p>
          <p class="eventText">
            ALL ISSUES AND QUESTIONS CONCERNING THE CONSTRUCTION, VALIDITY,
            INTERPRETATION AND ENFORCEABILITY OF THESE OFFICIAL RULES OR THE
            RIGHTS AND OBLIGATIONS OF ENTRANTS OR SPONSOR IN CONNECTION WITH THE
            CONTEST SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE
            INTERNAL LAWS OF THE STATE OF UTAH WITHOUT GIVING EFFECT TO ANY
            CHOICE OF LAW OF CONFLICT OF LAW RULES OR PROVISIONS THAT WOULD
            CAUSE THE APPLICATION OF ANY OTHER STATE'S LAWS.
          </p>
          <p class="eventTitle">WINNERS LIST</p>
          <p class="eventText">
            To obtain a copy of any legally-required winners list, send a
            self-addressed stamped envelope to: East Canyon State Park, 5535 S.
            Highway 66, Morgan, UT 84050. All such requests must be received
            within six (6) weeks after the end of the Contest Period. Winners'
            names (if any) will also be posted on the Website.
          </p>
          <p class="eventText">
            Special Advisory, Utah: Before boating, fishing or recreating on
            Utah waters, familiarize yourself on how to stop the spread of
            Aquatic Nuisance Species. See this link before heading out to any
            Utah water body: http://wildlife.utah.gov/habitat/ans.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../components/Header.vue";
import $ from "jquery";
export default {
  name: "FishingChallenge",
  metaInfo: {
    title: "East Canyon Fishing Challenge",
    meta: [
      {
        name: "description",
        content:
          "Purchase your day use pass for East Canyon Fishing Challenge.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      collectionId: "277117468862",
      breadcrumbs: [],
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "East Canyon State Park",
        disabled: false,
        href: "/parks/" + this.$route.params.name,
      },
      { text: "Fishing Challenge", disabled: true },
    ];
  },
  mounted() {
    this.loadShopifyCollection();
    document.getElementById("header").scrollIntoView();
  },
  methods: {
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.EveyShopifyBuyInit();
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.EveyShopifyBuyInit;
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
    EveyShopifyBuyInit: function () {
      // this.stopLoader();
      Evey.buy_button.buildShopifyBuyButton({
        shop: "parkstoreutah.myshopify.com",
        collectionId: this.collectionId,
        elementId: "collection-component-1616176464282",
        storefrontAccessToken: "1f4659f29b2004e36d6e8ebd35640005",
        collectLicensePlate: false,
        licensePlateRequired: false,
        options: {
          product: {
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                  width: "calc(25% - 20px)",
                },
                img: {
                  height: "calc(100% - 15px)",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                },
                imgWrapper: {
                  "padding-top": "calc(75% + 15px)",
                  position: "relative",
                  height: "0",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            buttonDestination: "cart",
            text: {
              button: "Buy now",
            },
          },
          productSet: {
            styles: {
              products: {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px",
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            text: {
              total: "Subtotal",
              button: "Checkout",
            },
          },
          toggle: {
            styles: {
              toggle: {
                "background-color": "#96a145",
                ":hover": {
                  "background-color": "#87913e",
                },
                ":focus": {
                  "background-color": "#87913e",
                },
              },
            },
          },
        },
        calendarCss: `
              .evey-calendar-container td.selected {
                background-color: #96A145 !important;
              }
              .evey-calendar-container td.selected .markers span {
                color: #96A145 !important;
              }
              .evey-calendar-container td.current-day {
                background-color: #FFFFFF !important;
              }
              .evey-calendar-container td.current-day .cell-date {
                color: #9aa656 !important;
              }
              .evey-calendar-container .available-events .selected {
                background-color: #96A145 !important;
              }
              .evey-calendar-container .modal-footer button {
                color: #96A145;
              }
              .evey-calendar-container:hover .modal-footer button {
                color: #838c41;
                cursor: pointer;
              }
            `,
        triggerCss: `
              button[data-evey-trigger="scheduler"] {
                border: 1px solid #c2c2c2;
                padding: 10px 30px 10px 7px;
                width:100%;
                text-align:left;
                font-size:inherit;
                display:flex;
                align-items:center;
                position: relative;
              }
              button[data-evey-trigger="scheduler"]:after {
                content: url("data:image/svg+xml; utf8, %3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M17.5%202H15V1a1%201%200%2010-2%200v1H6V1a1%201%200%2000-2%200v1H2.5C1.7%202%201%202.7%201%203.5v15c0%20.8.7%201.5%201.5%201.5h15c.8%200%201.5-.7%201.5-1.5v-15c0-.8-.7-1.5-1.5-1.5zM3%2018h14V8H3v10z%22%20fill%3D%22%23849235%22%2F%3E%3C%2Fsvg%3E");
                display:inline-block;
                position:absolute;
                right:10px;
                padding-left: 16px;
                width:20px;
                height:20px;
              }
            `,
      });
    },
  },
};
</script>

<style scoped>
.page-wrap {
  background-color: whitesmoke;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  margin: 0 auto;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: top;
  height: auto;
  position: relative;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  background-color: whitesmoke;
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  background-color: whitesmoke;
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  background-color: whitesmoke;
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.shopify-buy__product {
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145 !important;
}
.breadcrumbs {
  padding: 0px 0px 32px 0px;
}
@media only screen and (max-width: 850px) {
  .bottom-left {
    padding: 8px;
  }
  .page-wrap {
    padding: 8px;
  }
  .breadcrumbs {
    padding: 0px 0px 8px 0px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
}
</style>
