<template>
  <div style="background-color: white; min-height: 100vh">
    <Header></Header>
    <!-- <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <img src="../assets/images/app-snow-hero.jpeg" class="eventImg" />
      <div class="bottom-left">
        <h1 class="eventLarge whiteText">Parkspass App</h1>
      </div>
    </div> -->
    <div>
      <div class="hero-image">
        <div class="activate-pass-wrap">
          <div class="activate-pass">
            <div class="logo-text">
              <img
                src="../assets/images/dnr-app.svg"
                alt="dnr logo"
                class="dnr-logo"
              />
              <div class="text-wrap">
                <h3 class="activate">Register Your Pass</h3>
                <h2 class="download">Download the Utah State Parks App</h2>
                <h4 class="digital">
                  Haven’t Purchased a Pass?
                  <a
                    class="buy-pass-now"
                    href="https://parkspass.utah.gov/parks/PANUAL/dayuse"
                    >Buy a Pass Now!</a
                  >
                </h4>
              </div>
            </div>

            <div class="store-buttons">
              <img
                @click="goToAndroid"
                class="store-icon android"
                src="../assets/images/app_store/android.svg"
              />
              <img
                @click="goToIOS"
                class="store-icon iphone"
                src="../assets/images/app_store/ios.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="topo-lines">
      <!-- <img src="../assets/images/annual-pass-phones.png" class="" /> -->
      <div class="phone-wrap">
        <img src="../assets/images/home-mockup.png" />
        <img src="../assets/images/choose-mockup.png" class="choose-mockup" />
        <img src="../assets/images/pass-mockup.png" />
      </div>
    </div>

    <div class="page-wrap">
      <!-- <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs> -->

      <h2 class="highlights-features">Highlights & Features</h2>
      <div class="item center">
        <img
          id="qr-person-mobile"
          src="../assets/images/app_store/qr_person.svg"
        />
      </div>
      <div class="three">
        <div class="item">
          <div class="details">
            <h3>1. Annual Pass Storage</h3>
            <p>
              The Parkspass App offers a place to store your annual pass each
              year.
            </p>
          </div>
          <div class="details">
            <h3 class="low">2. Annual Pass Details</h3>
            <p>
              Pass details are included with each annual pass so that you can
              check the rules of your pass at any time.
            </p>
          </div>
        </div>
        <div class="item center">
          <img id="qr-person" src="../assets/images/app_store/qr_person.svg" />
        </div>
        <div class="item">
          <div class="details">
            <h3>3. Online Pass Renewal</h3>
            <p>A link to renew your pass online is offered in this app.</p>
          </div>
          <div class="details">
            <h3 class="low">4. Online Help</h3>
            <p>
              Our customer service representatives are available to answer your
              questions at any time.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="feat" class="footer">
      <h2>
        Follow Parkspass on <br />
        Social Media
      </h2>
      <div class="app-icon">
        <a
          v-if="!isMobile()"
          href="https://instagram.com/parkspass?igshid=NWRhNmQxMjQ="
        >
          <img class="app-icons" src="../assets/images/app_store/insta.svg" />
        </a>
        <a v-else href="instagram://user?username=parkspass">
          <img class="app-icons" src="../assets/images/app_store/insta.svg" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100068962068011"
          target="_blank"
        >
          <img
            style="margin-top: 20px"
            class="app-icons"
            src="../assets/images/app_store/fb.svg"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import $ from "jquery";
export default {
  name: "AppStore",
  metaInfo: {
    title: "App store annual pass",
    meta: [
      {
        name: "description",
        content: "Purchase your Digital Annual Pass.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      phone: "",
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      { text: "App", disabled: true },
    ];
  },
  mounted() {
    this.loadShopifyCollection();
    document.getElementById("header").scrollIntoView();
  },
  methods: {
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.EveyShopifyBuyInit();
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("is mobile");
        return true;
      } else {
        console.log("desktop");
        return false;
      }
    },
    goToAndroid() {
      // this.phone = "Android";
      // localStorage.setItem("phone", this.phone);
      // this.$router.push("/app/android");
      window.location.href =
        "http://play.google.com/store/apps/details?id=edu.utahtech.parkspass.utahstateparks";
    },
    goToIOS() {
      // this.phone = "IOS";
      // localStorage.setItem("phone", this.phone);
      // this.$router.push("/app/ios");
      window.location.href =
        "https://apps.apple.com/us/app/utah-state-parks/id1638662132";
    },
  },
};
</script>

<style scoped>
.hero-image {
  background-image: url("../assets/images/app-hero.png");
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "mediumRoboto";
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.text-wrap {
  display: flex;
  flex-direction: column;
}
.activate-pass-wrap {
  display: flex;
  justify-content: center;
  width: 90%;
}

.activate-pass {
  background-color: #0a0b0a;
  /* height: 250px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 30px;
  border-radius: 64px;
  margin-bottom: 10px;
}

.logo-text {
  display: flex;
  align-items: center;
}

.dnr-logo {
  margin-right: 1em;
}

.topo-lines {
  background-image: url("../assets/images/topo-map.svg");
  width: 100%;
  height: 881px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-wrap {
  display: flex;
  overflow: auto;
  white-space: nowrap;
}

.choose-mockup {
  margin: 0px 70px 0px 70px;
}

.white-text {
  color: white;
}

.digital {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  letter-spacing: 0.12px;
}

.buy-pass-now {
  color: #96a145;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.12px;
}

.download {
  color: #fff;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.24px;
}

.activate {
  color: #fff;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 112.5% */
  letter-spacing: 0.16px;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
p {
  font-size: 20px;
  color: #2e2e31;
}
#description {
  margin-top: 40px;
  line-height: 36px;
}
h1 {
  font-size: 96px;
  color: #ffffff;
}
h2 {
  color: #2e2e31db;
  font-size: 48px;
  line-height: 56px;
  font-family: "boldRoboto";
}
h3 {
  color: #2e2e31db;
  font-size: 32px;
  font-family: "boldRoboto";
  margin-bottom: 5px;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
  height: auto;
  position: relative;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
b {
  font-weight: bold;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
a {
  text-decoration: none;
  color: #96a145;
}
.v-breadcrumbs {
  padding: 18px 0px;
}
.three {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  text-align: center;
  margin-top: 50px;
}
.item {
  flex: 0 0 30%;
  text-align: left;
}
.item.center {
  flex: 0 0 40%;
  padding-right: 65px;
}
.store-icon {
  max-height: 48px;
  margin-right: 8px;
}

.android:hover {
  cursor: pointer;
}
.iphone:hover {
  cursor: pointer;
}
#store-icons {
  margin-top: 60px;
}

.store-buttons {
  /* margin-top: 60px; */
  display: flex;
}

.shrink {
  max-width: 45%;
  width: 300px;
  margin-right: 25px;
}
#feat {
  text-align: center;
  margin-top: 75px;
  /* margin-bottom: 100px; */
  padding: 50px 0px 50px 0px;
  background: rgba(150, 161, 69, 0.05);
}

.highlights-features {
  text-align: center;
  margin-top: 75px;
  margin-bottom: 100px;
}
#qr-person {
  max-width: 90%;
  margin-top: -92px;
}
#qr-person-mobile {
  max-width: 320px;
  display: none;
  margin: auto;
}
.low {
  margin-top: 100px;
}
.app-icon {
  margin-top: 30px;
}
.app-icons {
  margin-right: 12px;
}
/* #iphones {
  width: 40%;
} */
@media only screen and (max-width: 1440px) {
  .shrink {
    max-width: 40%;
    /* width: 300px; */
    margin-right: 25px;
  }
  #iphones {
    width: 40%;
  }
}
@media only screen and (max-width: 1440px) {
  #iphones {
    width: 40%;
  }
}

@media only screen and (max-width: 1025px) {
  .activate {
    font-size: 24px;
  }

  .download {
    font-size: 36px;
  }

  .digital {
    font-size: 18px;
  }
}

@media only screen and (max-width: 950px) {
  #store-icons {
    display: flex;
  }

  .activate-pass {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 20px;
  }

  .activate-pass-wrap {
    width: 100%;
  }
}
@media only screen and (max-width: 860px) {
  .bottom-left {
    padding: 25px;
  }

  .dnr-logo {
    height: 50%;
  }

  .download {
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  .digital {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 257.143% */
    letter-spacing: 0.07px;
  }

  .buy-pass-now {
    color: #96a145;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.07px;
  }

  .activate {
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 200% */
    letter-spacing: 0.09px;
  }
  .page-wrap {
    padding: 16px;
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 350px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
    line-height: 42px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    font-family: "Roboto";
  }
  .three {
    display: inline;
  }
  .shrink {
    display: flex;
    margin: auto;
    max-width: 95%;
  }
  .item.center {
    padding-right: 0px;
  }
  #store-icons {
    margin-top: 24px;
    margin-bottom: 50px;
  }

  .app-icon {
    display: inline-grid;
  }
  .store-icon {
    width: 130px;
    /* display: flex; */
    margin: auto;
  }
  #description[data-v-8f6132d2] {
    margin-top: 24px;
    line-height: 26px;
  }
  #feat {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  #qr-person {
    display: none;
  }
  #qr-person-mobile {
    display: flex;
  }
  .low {
    margin-top: 0;
  }
  .details {
    margin-top: 24px;
  }
  #iphones {
    width: 95%;
  }
}

@media only screen and (max-width: 395px) {
  .hero-image {
    height: 500px;
  }
  .activate-pass {
    height: 300px;
  }

  .dnr-logo {
    height: 30%;
  }
}
</style>
