import Vue from "vue";
import VueRouter from "vue-router";
// import vClickOutside from 'v-click-outside'
import VueMeta from "vue-meta";

import Home from "@/views/Home";
// import Park from '@/views/Park'
import ParkDayuse from "@/views/ParkDayuse";
import Camping from "@/views/Camping";
import InfoGraphic from "@/views/Infographic";
import PageNotFound from "@/views/PageNotFound";
import PlateUpdate from "@/views/PlateUpdate";
import PlateUpdate2 from "@/views/PlateUpdate2";

// EVENTS
// import BisonInfo from "@/views/events/NewBison/BisonInfo";
// import BisonWaiver from "@/views/events/NewBison/BisonWaiver.vue";
// import Thankyou from "@/views/events/NewBison/Thankyou.vue";
import FantasyAtTheBay from "@/views/events/FantasyAtTheBay";
import FantasyDates from "@/views/events/fantasyDates/FantasyDates";
import dGlasses from "@/views/events/fantasyDates/3dGlasses";
import FishingChallenge from "@/views/events/FishingChallenge";
import QuailTriathlon from "@/views/events/QuailTriathlon";
import DiscGolf from "@/views/events/DiscGolf";
import MoonWalk from "@/views/events/MoonWalk";
import MoonWalkJune from "@/views/events/moonWalkDates/June";
import MoonWalkJuly from "@/views/events/moonWalkDates/July";
import MoonWalkAug from "@/views/events/moonWalkDates/Aug";
import MoonWalkSept from "@/views/events/moonWalkDates/Sept";
import MoonWalkOct from "@/views/events/moonWalkDates/Oct";
// import GroupFire from "@/views/events/GroupFire";
import Brad from "@/views/events/Brad";
import GroupFireOct7 from "@/views/events/GroupFireDates/Oct7th";
import GroupFireOct20 from "@/views/events/GroupFireDates/Oct20th";
import GroupFireOct27 from "@/views/events/GroupFireDates/Oct27th";
import HikeGSL from "@/views/events/HikeGSL";
import Store from "@/views/Store";
import Mirabilite2023 from "@/views/events/Mirabilite2023";
import MirabileiteDates from "@/views/events/mirabiliteDates/MirabiliteDates";
import MirabiliteJan14 from "@/views/events/mirabiliteDates/Jan14";
import MirabiliteJan15 from "@/views/events/mirabiliteDates/Jan15";
import MirabiliteJan16 from "@/views/events/mirabiliteDates/Jan16";
import MirabiliteJan21 from "@/views/events/mirabiliteDates/Jan21";
import MirabiliteJan22 from "@/views/events/mirabiliteDates/Jan22";
import Eggstrav from "@/views/events/EasterEastCanyon";
import Prehistoric from "@/views/events/PrehistoricStory";
import PaintNight from "@/views/events/PaintNightGSL";
import PaintNightDates from "@/views/events/paintNightDates/PaintNightDates";
import PalisadeFishingReg from "/src/views/events/palisadeFishing/RegisterPage.vue";
import Waiver from "/src/views/events/palisadeFishing/Waiver.vue";
import Cart from "/src/views/events/palisadeFishing/AddToCart.vue";
import BirdWatching from "/src/views/events/BirdWatching.vue";
import BirdWatchingMay12 from "/src/views/events/birdwatchDates/May12.vue";
import BirdWatchingMay13 from "/src/views/events/birdwatchDates/May13.vue";
import BirdWatchingMay28 from "/src/views/events/birdwatchDates/May28.vue";
import PioneerGames from "@/views/events/PioneerGames.vue";
import PaddlingWithRanger from "@/views/events/PaddlingWithRanger.vue";
import CampfireStories from "@/views/events/CampfireStories.vue";
import RockportFirewood from "@/views/RockportFirewood.vue";
import JordanWaiver from "@/views/Jordan-OHV/JordanWaiver.vue";
import JordanPurchase from "@/views/Jordan-OHV/JordanPurchase.vue";
import Jordan from "@/views/Jordan-OHV/Jordan.vue";
import NorthBeach from "@/views/YubaCamping/NorthBeaches.vue";
import EagleView from "@/views/YubaCamping/EagleView.vue";
import Oasis from "@/views/YubaCamping/Oasis.vue";
import OasisVista from "@/views/YubaCamping/OasisVista.vue";
import PaintedRock from "@/views/YubaCamping/PaintedRock.vue";
import HobieDry from "@/views/DryStorage/HobieDry.vue";
import HobieDry2 from "@/views/DryStorage/HobieDry2.vue";
import HobieWaiver from "@/views/DryStorage/HobieWaiver2.vue";
import HobieForm from "@/views/DryStorage/HobieForm.vue";
import Montecristo from "@/views/Montecristo.vue";
import RockportCamping from "@/views/RockportCamping/RockCamping.vue";
import RockportVideo from "@/views/rentals/RockportVideo.vue";
import GunlockDonation from "@/views/events/donations/Gunlock.vue";
import AnasaziDonation from "@/views/events/donations/Anasazi.vue";
import HyrumDonation from "@/views/events/donations/Hyrum.vue";
import EastCamping from "../views/EastCanyonCampin/EastCamping.vue";
import Haunted from "../views/EastCanyonCampin/Haunted.vue";
// import MoveFromWaitlist from "@/views/events/NewBison/MoveFromWaitlist.vue";
import WillCamping from "@/views/WillardCamping/willCamping.vue";
import AITestSupport from "../views/AITestSupport.vue";
import AiBlocker from "../views/AiBlocker.vue";
// import Permits from "/src/views/events/Permits.vue";
import QuadInfo from "@/views/events/QuadFishathon/QuadInfo.vue";
import QuadWaiver from "@/views/events/QuadFishathon/QuadWaiver.vue";

// Willard Bay Walk In Inlet

import InletWillard from "@/views/InletWillard";
// jordanelle perimeter trail
import JordanelleTrail from "@/views/JordanelleTrail";

// REGISTER PASS
import Register from "@/views/Register.vue";
import Register2 from "@/views/Register2.vue";

// Check in pages
import Landing from "@/views/Check-in/Landing.vue";
import Cash from "@/views/Check-in/Cash.vue";
import Annual from "@/views/Check-in/Annual.vue";
import DigitalDay from "@/views/Check-in/DigitalDay.vue";
import Brochure from "@/views/Check-in/Brochure.vue";

//sticker pages
import StickerLanding from "@/views/StickerPages/StickerLanding.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);
// Vue.use(vClickOutside)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //   {
  //     path: '/parks/:name',
  //     name: 'Park',
  //     component: Park,
  //   },

  {
    path: "/parks/ANNUAL/dayuse",
    redirect: "/parks/PANUAL/dayuse",
  },
  {
    path: "/parks/:name/dayuse",
    name: "ParkDayuse",
    component: ParkDayuse,
  },
  {
    path: "/parks/:name/camping",
    name: "Camping",
    component: Camping,
  },
  {
    path: "/parks/UTSNOW/info",
    name: "InfoGraphic",
    component: InfoGraphic,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/plateupdate",
    name: "PlateUpdate",
    component: PlateUpdate,
  },
  {
    path: "/plateupdate/form",
    name: "PlateUpdate2",
    component: PlateUpdate2,
  },
  // EVENTS
  // {
  //   path: "/parks/:name/bisonroundup",
  //   name: "BisonInfo",
  //   component: BisonInfo,
  // },
  // {
  //   path: "/parks/:name/bisonroundup/waiver",
  //   name: "BisonWaiver",
  //   component: BisonWaiver,
  // },
  // {
  //   path: "/parks/:name/bisonroundup/thankyou",
  //   name: "Thankyou",
  //   component: Thankyou,
  // },
  // {
  //   path: "/parks/:name/bisonroundup/movefromwaitlist/:uuid/:quantity",
  //   name: "MoveFromWaitlist",
  //   component: MoveFromWaitlist,
  // },
  {
    path: "/parks/:name/fantasyatthebay",
    name: "FantasyAtTheBay",
    component: FantasyAtTheBay,
  },
  {
    path: "/parks/:name/fantasyatthebay/:month/:day",
    name: "fantasyDates",
    component: FantasyDates,
  },
  {
    path: "/parks/:name/3dglasses",
    name: "3dGlasses",
    component: dGlasses,
  },
  {
    path: "/parks/:name/fishingchallenge",
    name: "FishingChallenge",
    component: FishingChallenge,
  },
  {
    path: "/parks/:name/quailtriathlon",
    name: "QuailTriathlon",
    component: QuailTriathlon,
  },
  {
    path: "/parks/:name/discgolf",
    name: "DiscGolf",
    component: DiscGolf,
  },
  {
    path: "/parks/:name/moonwalk",
    name: "MoonWalk",
    component: MoonWalk,
  },
  {
    path: "/parks/:name/moonwalk/june",
    name: "MoonWalkJune",
    component: MoonWalkJune,
  },
  {
    path: "/parks/:name/moonwalk/july",
    name: "MoonWalkJuly",
    component: MoonWalkJuly,
  },
  {
    path: "/parks/:name/moonwalk/aug",
    name: "MoonWalkAug",
    component: MoonWalkAug,
  },
  {
    path: "/parks/:name/moonwalk/sept",
    name: "MoonWalkSept",
    component: MoonWalkSept,
  },
  {
    path: "/parks/:name/moonwalk/oct",
    name: "MoonWalkOct",
    component: MoonWalkOct,
  },
  {
    path: "/parks/:name/groupfire/oct7th",
    name: "GroupFireOct7th",
    component: GroupFireOct7,
  },
  {
    path: "/parks/:name/groupfire/oct20th",
    name: "GroupFireOct20th",
    component: GroupFireOct20,
  },
  {
    path: "/parks/:name/groupfire/oct27th",
    name: "GroupFireOct27th",
    component: GroupFireOct27,
  },
  {
    path: "/parks/:name/campfire",
    name: "CampfireStories",
    component: CampfireStories,
  },
  {
    path: "/parks/:name/pioneergames",
    name: "PioneerGames",
    component: PioneerGames,
  },
  {
    path: "/parks/:name/paddlingwithranger",
    name: "PaddlingWithRanger",
    component: PaddlingWithRanger,
  },
  {
    path: "/brad",
    name: "Brad",
    component: Brad,
  },
  {
    path: "/parks/:name/hike",
    name: "HikeGSL",
    component: HikeGSL,
  },
  {
    path: "/app",
    name: "Store",
    component: Store,
  },
  {
    path: "/parks/:name/mirabilite",
    name: "Mirabilite",
    component: Mirabilite2023,
  },
  {
    path: "/parks/:name/mirabilite/:dates",
    name: "Mirabilite Dates",
    component: MirabileiteDates,
  },
  {
    path: "/parks/:name/mirabilite/jan14",
    name: "MirabiliteJan14",
    component: MirabiliteJan14,
  },
  {
    path: "/parks/:name/mirabilite/jan15",
    name: "MirabiliteJan15",
    component: MirabiliteJan15,
  },
  {
    path: "/parks/:name/mirabilite/jan16",
    name: "MirabiliteJan16",
    component: MirabiliteJan16,
  },
  {
    path: "/parks/:name/mirabilite/jan21",
    name: "MirabiliteJan21",
    component: MirabiliteJan21,
  },
  {
    path: "/parks/:name/mirabilite/jan22",
    name: "MirabiliteJan22",
    component: MirabiliteJan22,
  },
  {
    path: "/parks/:name/Eggstravaganza",
    name: "Eggstrav",
    component: Eggstrav,
  },
  {
    path: "/parks/:name/prehistoric",
    name: "prehistoric",
    component: Prehistoric,
  },
  {
    path: "/parks/:name/paintnight",
    name: "PaintNight",
    component: PaintNight,
  },
  {
    path: "/parks/:name/paintnight/:type/:month/:day",
    name: "paintNightDate",
    component: PaintNightDates,
  },
  {
    path: "/parks/:name/palisadefishingreg",
    name: "PalisadeFishingReg",
    component: PalisadeFishingReg,
    props: true,
  },
  {
    path: "/parks/:name/waiver",
    name: "Waiver",
    component: Waiver,
  },
  {
    path: "/parks/:name/fishingcart",
    name: "FishingCart",
    component: Cart,
  },
  {
    path: "/parks/:name/birdwatching/may12",
    name: "BirdWatchingMay12",
    component: BirdWatchingMay12,
  },
  {
    path: "/parks/:name/birdwatching/may13",
    name: "BirdWatchingMay13",
    component: BirdWatchingMay13,
  },
  {
    path: "/parks/:name/birdwatching/may28",
    name: "BirdWatchingMay28",
    component: BirdWatchingMay28,
  },
  // {
  //   path: "/parks/:name/fishingcart",
  //   name: "FishingCart",
  //   component: Cart,
  // },
  {
    path: "/parks/:name/dayuse/inlet",
    name: "InletWillard",
    component: InletWillard,
  },
  {
    path: "/parks/:name/dayuse/jordanelletrail",
    name: "JordanelleTrail",
    component: JordanelleTrail,
  },
  {
    path: "/parks/UTGREA/birdwatching",
    name: "BirdWatching",
    component: BirdWatching,
  },
  {
    path: "/parks/:name/rockportfirewood",
    name: "RockportFirewood",
    component: RockportFirewood,
  },
  {
    path: "/parks/UTJOHV/jordan/waiver",
    name: "JordanWaiver",
    component: JordanWaiver,
  },
  {
    path: "/parks/UTJOHV/jordan/purchase",
    name: "JordanPurchase",
    component: JordanPurchase,
  },
  {
    path: "/parks/UTJOHV/jordan",
    name: "Jordan",
    component: Jordan,
  },
  {
    path: "/parks/UTYUBA/camping/northbeach",
    name: "NorthBeach",
    component: NorthBeach,
  },
  {
    path: "/parks/UTYUBA/camping/eagleview",
    name: "EagleView",
    component: EagleView,
  },
  {
    path: "/parks/UTYUBA/camping/oasis",
    name: "Oasis",
    component: Oasis,
  },
  {
    path: "/parks/UTYUBA/camping/oasisvista",
    name: "OasisVista",
    component: OasisVista,
  },
  {
    path: "/parks/UTYUBA/camping/paintedrock",
    name: "PaintedRock",
    component: PaintedRock,
  },
  {
    path: "/parks/UTROCK/camping/riverside",
    name: "RockportCamping",
    component: RockportCamping,
  },
  {
    path: "/parks/UTEAST/camping/east",
    name: "EastCamping",
    component: EastCamping,
  },
  {
    path: "/parks/UTEAST/camping/haunted",
    name: "HauntedCamping",
    component: Haunted,
  },
  {
    path: "/parks/UTROCK/drystorage/rockport",
    name: "HobieDry",
    component: HobieDry,
  },
  {
    path: "/parks/UTROCK/drystorage/hobie",
    name: "HobieDry2",
    component: HobieDry2,
  },
  {
    path: "/parks/UTROCK/drystorage/hobiewaiver",
    name: "HobieWaiver",
    component: HobieWaiver,
  },
  {
    path: "/parks/UTROCK/drystorage/rockportform",
    name: "HobieForm",
    component: HobieForm,
  },
  {
    path: "/parks/UTWILL/montecristo/dayuse",
    name: "Montecristo",
    component: Montecristo,
  },

  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/activate/:id",
    name: "Register",
    component: Register2,
  },
  {
    path: "/:park/checkin",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/:park/checkin/cash",
    name: "Cash",
    component: Cash,
  },
  {
    path: "/:park/checkin/annual",
    name: "Annual",
    component: Annual,
  },
  {
    path: "/:park/checkin/digitalday",
    name: "DigitalDay",
    component: DigitalDay,
  },
  {
    path: "/:park/checkin/brochure",
    name: "Brochure",
    component: Brochure,
  },
  {
    path: "/:park/checkin/error",
    name: "Error",
    component: Error,
  },
  {
    path: "/parks/UTROCK/rockportvideo",
    name: "RockportVideo",
    component: RockportVideo,
  },
  {
    path: "/parks/UTGUNL/donations",
    name: "GunlockDonation",
    component: GunlockDonation,
  },
  {
    path: "/parks/UTANAS/donations",
    name: "AnasaziDonation",
    component: AnasaziDonation,
  },
  {
    path: "/parks/UTHYRU/donations",
    name: "HyrumDonation",
    component: HyrumDonation,
  },
  {
    path: "/parks/UTWILL/camping/offseason",
    name: "WillCamping",
    component: WillCamping,
  },
  {
    path: "/passhelp",
    name: "StickerLanding",
    component: StickerLanding,
  },
  {
    path: "/aisupport/access",
    name: "AITestSupport",
    component: AITestSupport,
  },
  {
    path: "/aisupport",
    name: "AiBlocker",
    component: AiBlocker,
  },
  {
    path: "/parks/quadfishathon/info",
    name: "QuadInfo",
    component: QuadInfo,
  },
  {
    path: "/parks/quadfishathon/waivers/:quantity",
    name: "QuadWaiver",
    component: QuadWaiver,
  },
  // {
  //   path: "/permits",
  //   name: "Permits",
  //   component: Permits,
  // },
  // REDIRECTS
  // { path: "/bisonroundup", redirect: "/parks/UTANTE/bisonroundup" },
  { path: "/fishingchallenge", redirect: "/parks/UTEAST/fishingchallenge" },
  { path: "/quailtriathlon", redirect: "/parks/UTQUIA/quailtriathlon" },
  { path: "/groupfire", redirect: "/parks/UTGREA/groupfire" },
  { path: "/groupfireoct7", redirect: "/parks/UTGREA/groupfire/oct7th" },
  { path: "/palisadefishing", redirect: "/parks/:name/palisadefishingreg" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
