<template>
  <v-app>
    <Header />
    <div id="cont" style="height: 100vh">
      <div id="box">
        <h1>Enter Password</h1>
        <v-text-field
          class="password"
          v-model="password"
          label="Password"
          type="password"
          @keyup.enter="checkPassword"
          outlined
        >
        </v-text-field>
        <div style="display: flex; justify-content: flex-end">
          <button id="button" @click="checkPassword()">Enter</button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
export default {
  data() {
    return {
      showDialog: false,
      password: "",
      Passdialog: true,
    };
  },
  components: {
    Header,
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    checkPassword() {
      if (this.password === "parkranger") {
        // Redirect to a different page
        this.$router.push("/aisupport/access");
      } else {
        alert("Incorrect password. Please try again.");
      }
    },
  },
};
</script>

<style>
/* Add your custom styles here */
#cont {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}
#box {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  padding: 20px;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}
#button {
  border-radius: 23px;
  background: #96a145;
  color: white;
  padding: 6px 12px;
}
</style>
