export const collectionJson = [
  { name: "Anasazi State Park Museum", collectionId: "300995248281" },
  { name: "Antelope Island State Park", collectionId: "300995608729" },
  { name: "Bear Lake State Park", collectionId: "318528061593" },
  { name: "Camp Floyd State Park Museum", collectionId: "300997476505" },
  { name: "Coral Pink Sand Dunes State Park", collectionId: "301019758745" },
  { name: "Dead Horse Point State Park", collectionId: "305053204633" },
  { name: "Deer Creek State Park", collectionId: "318530912409" },
  { name: "East Canyon State Park", collectionId: "305022763161" },
  { name: "Echo State Park", collectionId: "305086169241" },
  {
    name: "Edge Of The Cedars State Park Museum",
    collectionId: "305086660761",
  },
  {
    name: "Escalante Petrified Forest State Park",
    collectionId: "305086103705",
  },
  { name: "Flight Park State Recreation Area", collectionId: "318530781337" },
  { name: "Fred Hayes State Park at Starvation", collectionId: "305060216985" },
  {
    name: "Fremont Indian State Park and Museum",
    collectionId: "305086464153",
  },
  {
    name: "Frontier Homestead State Park Museum",
    collectionId: "305411260569",
  },
  { name: "Goblin Valley State Park", collectionId: "318530683033" },
  { name: "Goosenecks State Park", collectionId: "305086562457" },
  { name: "Great Salt Lake State Park", collectionId: "305086038169" },
  { name: "Green River State Park", collectionId: "305086070937" },
  { name: "Gunlock State Park", collectionId: "301019889817" },
  { name: "Huntington State Park", collectionId: "305086234777" },
  { name: "Hyrum State Park", collectionId: "305060282521" },
  { name: "Jordan River OHV State Park", collectionId: "301917634713" },
  { name: "Jordanelle State Park", collectionId: "323317891225" },
  { name: "Kodachrome Basin State Park", collectionId: "302604877977" },
  { name: "Lost Creek State Park", collectionId: "319042060441" },
  { name: "Millsite State Park", collectionId: "305086627993" },
  { name: "Otter Creek State Park", collectionId: "305086365849" },
  { name: "Palisade State Park", collectionId: "305060118681" },
  { name: "Piute State Park", collectionId: "305086791833" },
  { name: "Quail Creek State Park", collectionId: "301019725977" },
  { name: "Red Fleet State Park", collectionId: "318531109017" },
  { name: "Rockport State Park", collectionId: "305060020377" },
  { name: "Sand Hollow State Park", collectionId: "301019627673" },
  { name: "Scofield State Park", collectionId: "305086529689" },
  { name: "Snow Canyon State Park", collectionId: "300995903641" },
  { name: "Steinaker State Park", collectionId: "305086496921" },
  {
    name: "Territorial Statehouse State Park Museum",
    collectionId: "305086726297",
  },
  //   {"name":"Test Park", "collectionId": "268180259006"},
  {
    name: "Utah Field House of Natural History State Park Museum",
    collectionId: "305086267545",
  },
  { name: "Utah Lake State Park", collectionId: "305059627161" },
  { name: "Utahraptor State Park", collectionId: "304026353817" },
  { name: "Wasatch Mountain State Park", collectionId: "305059528857" },
  { name: "Willard Bay State Park", collectionId: "318531567769" },
  { name: "Yuba State Park", collectionId: "305060380825" },
  { name: "State Park Annual Pass", collectionId: "324472864921" },
  // { name: "Digital Annual Pass", collectionId: "319050055833" },
];
