<template>
  <div style="background-color: #fff; height: 100vh">
    <!-- Your HTML content goes here -->
    <Header />
    <div id="hero">
      <h1>Receive Your Annual Pass Sticker</h1>
    </div>
    <div id="box-cont">
      <div id="grey-box">
        <h2>Purchase Information</h2>
        <p style="margin-top: 10px">Where did you purchase your annual pass?</p>
        <v-radio-group id="radio-cont" v-model="type">
          <v-radio label="In Person" value="one" class="radio"></v-radio>
          <v-radio label="Online" value="two" class="radio"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/SocialFooter";

export default {
  name: "StickerLanding",
  components: {
    Header,
    Footer,
  },
  // Your component's logic goes here
};
</script>

<style scoped>
#hero {
  background-image: url("../../assets/images/Stickers.jpg");
  height: 380px;
  background-size: cover;
  align-content: center;
}
h1 {
  color: #fff;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  width: 412px;
  margin-left: 70px;
  margin-bottom: 15px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
p {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
#box-cont {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#grey-box {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  width: 60%;
  padding: 25px;
}
#radio-cont {
  display: flex;
  margin-top: 20px;
  flex-direction: row !important;
}
.radio {
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px;
  margin-right: 10px;
}
.radio > label {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.radio-item {
  margin-right: 20px;
}
.circle {
  margin-right: 10px;
}
.radio-text {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}
/* Your component's styles go here */
</style>
<style>
.v-input--radio-group__input {
  width: unset !important;
}
</style>
