<template>
  <v-app>
    <div class="header">
      <div class="header-cont">
        <img src="../../assets/images/parkspass-logo-2023.svg" alt="" />
        <h3>Check-In</h3>
      </div>
    </div>
    <div class="main-cont">
      <v-card class="card1">
        <h1 style="margin-bottom: 26px">Annual Pass</h1>
        <p>* Required Information</p>
        <div id="input-flex">
          <v-text-field
            style="margin-top: 16px"
            label="* Order #"
            class="grey-background"
            color="#96A145"
            v-model="order_number"
          ></v-text-field>
          <img src="../../assets/images/help.svg" alt="" @click="info = true" />
        </div>
        <div class="button-flex">
          <button class="green-button" @click="submit()">Submit</button>
        </div>
        <div style="margin-top: 20px">
          <h4>or Check in through</h4>
          <h4 class="green-text">Utah State Park App</h4>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="info" class="info-dialog">
      <v-card class="info-card">
        <p class="white-text">
          If you have not received an order # by email, you can register your
          pass now at:
          <span class="underline">https://parkspass.utah.gov/register</span>
        </p>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import $ from "jquery";
export default {
  name: "Annual Check-In",
  metaInfo: {
    title: "Annual Check-In",
    meta: [
      {
        name: "description",
        content: "Annual Check-In",
      },
    ],
  },
  data() {
    return {
      info: false,
      order_number: "",
      park_abbr: this.$route.params.park,
    };
  },
  methods: {
    submit() {
      console.log("Submitted");
      // Handle the form submission here
      var settings = {
        url: `https://trailwaze.info/test/parkpass/checkinQRCode.php?order_number=${this.order_number}&checkin=yes&park_abbr=${this.park_abbr}`,
        method: "GET",
        timeout: 0,
      };
      var _this = this;
      $.ajax(settings).done(function (response) {
        console.log(response);
        if (response.response == "CHECK IN SUCCESSFUL") {
          // go to success page
          console.log("success");
          _this.$router.push(`/${_this.park_abbr}/checkin/brochure`);
        } else {
          // go to error page
          console.log("error");
          _this.$router.push(`/${_this.park_abbr}/checkin/error`);
        }
      });
    },
  },
};
</script>

<style scoped>
h1 {
  color: #444;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
h2 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}
h3 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
h4 {
  color: #222;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.green-text {
  color: #96a145;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
}
button {
  margin-right: 8px;
}
.header {
  background: #2e2e31;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
}
.header-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-cont {
  margin: 20px;
}

#app {
  height: 100vh;
  background: #f5f5f5;
}
.card1 {
  border-radius: 4px !important;
  border: 1px solid #8f8f8f !important;
  background: #fff !important;
  padding: 16px !important;
  box-shadow: none !important;
}
p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.green-button {
  border-radius: 29px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 8px 16px;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.icon-help img {
  width: 24px;
  height: 24px;
}
#input-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-card {
  border-radius: 4px;
  background: #2e2e31 !important;
  padding: 14px;
}
.white-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
.underline {
  text-decoration-line: underline;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
</style>
