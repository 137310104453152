var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","min-height":"100vh"}},[_c('Header'),_vm._m(0),_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"topCon"},[_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"divider":"/","items":_vm.breadcrumbs}})],1),_c('div',{staticClass:"shopifyCollectionWrap",attrs:{"id":""}},[_vm._m(1),_c('div',{staticClass:"collectionDiv"},[_c('DatePicker',{staticClass:"date-picker",attrs:{"mode":"date","attribute":_vm.selectAttribute,"min-date":new Date(2022, 10, 25),"max-date":new Date(2022, 11, 30),"disabled-dates":[
            {
              start: new Date(2022, 11, 25),
              end: new Date(2022, 11, 25),
            },
            // {
            //   start: null,
            //   end: new Date(Date.now() - 8639999)
            //     .toISOString()
            //     .substring(0, 10),
            // },
          ],"color":"blue"},on:{"dayclick":_vm.dayClicked},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('div',[_c('div',{staticClass:"price-wrap"},[(_vm.pricePerDay)?_c('div',{staticClass:"price-christmas"},[_vm._v(" $"+_vm._s(_vm.price)+".00 per vehicle up to 8 occupants ")]):_vm._e(),(_vm.christmasDay)?_c('div',[_vm._v("Closed Christmas Day")]):_vm._e(),(_vm.notAvailable)?_c('div',[_vm._v("Not Available")]):_vm._e()]),_c('div',{staticClass:"get-dates-wrap"},[(!_vm.btnDisabled)?_c('div',{staticClass:"get-dates-btn",on:{"click":function($event){return _vm.goToDate()}}},[_vm._v(" Continue ")]):_vm._e(),(_vm.btnDisabled)?_c('div',{staticClass:"get-dates-btn-disabled"},[_vm._v(" Continue ")]):_vm._e()])])]),_c('v-divider',{staticClass:"divider"}),_c('div',{staticClass:"passContainer2"},[_c('div',{staticClass:"infoMargin"},[_c('p',{staticClass:"passTitle"},[_vm._v("Pass Information")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"passContainer",attrs:{"id":"nomargin"}},[_c('img',{staticClass:"noMarginimg",attrs:{"src":require("../../assets/images/3dglasses.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v("$2 each or 3 for $5")]),_c('div',{staticClass:"eventTitle",attrs:{"id":"nomargin"}},[_vm._v("3D Glasses")]),_c('p',{staticClass:"link",on:{"click":function($event){return _vm.gotoGlasses()}}},[_vm._v(" Purchase 3D glasses here ")])])])]),_vm._m(6)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","color":"white","font-family":"'mediumRoboto'"}},[_c('img',{staticClass:"eventImg",attrs:{"src":require("../../assets/images/christmas-lights-2021.jpg")}}),_c('img',{staticClass:"eventImg-mobile",attrs:{"src":require("../../assets/images/christmas-lights-2021-1.jpg")}}),_c('div',[_c('div',{staticClass:"bottom-left"},[_c('p',{staticClass:"eventSmall whiteText"},[_vm._v("Nov 25 - Dec 30")]),_c('h3',{staticClass:"eventLarge whiteText"},[_vm._v("2022 Fantasy at the Bay")]),_c('p',{staticClass:"eventSub whiteText"},[_vm._v("Drive Through Light Show")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-date-headings"},[_c('h2',{staticClass:"select-date-h2"},[_vm._v("Select a Date")]),_c('h3',[_vm._v("November 25th - December 30th")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{staticClass:"noMarginimg",attrs:{"src":require("../../assets/images/sun-thur-cal.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v("$15")]),_c('div',{staticClass:"eventTitle",attrs:{"id":"nomargin"}},[_vm._v("Sun - Thurs")]),_c('p',[_vm._v("Per vehicle up to 8 occupants")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{staticClass:"noMarginimg",attrs:{"src":require("../../assets/images/fir-sat-cal.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v("$20")]),_c('div',{staticClass:"eventTitle",attrs:{"id":"nomargin"}},[_vm._v("Fri & Sat")]),_c('p',[_vm._v("Per vehicle up to 8 occupants")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{staticClass:"noMarginimg",attrs:{"src":require("../../assets/images/dec16-24-cal.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v("$20")]),_c('div',{staticClass:"eventTitle",attrs:{"id":"nomargin"}},[_vm._v("Dec 16 - Dec 24")]),_c('p',[_vm._v("Per vehicle up to 8 occupants")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{staticClass:"noMarginimg",attrs:{"src":require("../../assets/images/dec16-24-cal.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v("$15")]),_c('div',{staticClass:"eventTitle",attrs:{"id":"nomargin"}},[_vm._v("Dec 26 - Dec 30")]),_c('p',[_vm._v("Per vehicle up to 8 occupants")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoMargin2"},[_c('p',{staticClass:"passTitle"},[_vm._v("Event Information")]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/dates.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Dates")]),_c('p',[_vm._v("November 25th - December 30th "),_c('br'),_vm._v("Closed Christmas Day")])])]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/time.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Time")]),_c('p',[_vm._v("Nov 25 - Dec 24: 5:00 p.m. - 10:00 p.m.")]),_c('p',[_vm._v("Dec 26 - 30: 5:00 p.m. - 9:00 p.m.")])])]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/Disclaimer.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v(" Annual, Senior, and Honor Passes are not accepted for this event. ")])])])])
}]

export { render, staticRenderFns }