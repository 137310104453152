<template>
  <div class="header" id="header">
    <router-link to="/"
      ><img
        class="logo"
        src="../assets/icons/NewParkspassLogo.svg"
        alt="Parkspass Logo"
    /></router-link>
    <div class="helpContainer" @click="helpClicked()">
      <img
        class="helpBtn"
        src="../assets/icons/help.svg"
        alt="Parkspass Help"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    helpClicked: function () {
      window.open("https://parkspass.zendesk.com/hc/en-us");
    },
  },
};
</script>

<style scoped>
.logo {
  height: 40px;
  margin-top: 19px;
}
@media only screen and (max-width: 420px) {
  .logo {
    height: 24px;
    margin-top: 28px;
  }
}
</style>
