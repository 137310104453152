<template>
  <v-app>
    <div style="background-color: white; min-height: 100vh">
      <Header />

      <div class="page-wrap">
        <div v-if="dayBanner" class="dayBanner">
          <img
            src="../assets/icons/blueInfo.png"
            style="
              display: inline-block;
              vertical-align: middle;
              margin: 0px 12px;
              height: 24px;
            "
          />
          <div
            v-if="
              parkName == 'Utahraptor State Park' ||
              parkName == 'Flight Park State Recreation Area'
            "
          >
            <p
              style="display: inline-block; vertical-align: middle; margin: 0px"
            >
              Attention: Pass is good for the night of camping only!
            </p>
          </div>
          <div v-else>
            <p
              style="display: inline-block; vertical-align: middle; margin: 0px"
            >
              Attention: Pass is good for the day of purchase only!
            </p>
          </div>
          <img
            src="../assets/icons/blueX.png"
            style="
              float: right;
              display: inline-block;
              margin: 12px 16px;
              cursor: pointer;
              height: 24px;
              margin-left: auto;
            "
            @click="dayBanner = false"
          />
        </div>

        <v-breadcrumbs
          divider="/"
          :items="breadcrumbs"
          style="padding: 8px 0px"
        ></v-breadcrumbs>

        <div class="parkTitleWrap">
          <h1 style="margin-top: 0px">{{ parkName }}</h1>
          <h3
            v-if="parkName == 'Utahraptor State Park'"
            style="font-family: 'boldRoboto'; font-size: 18px"
          >
            Primitive Camping
          </h3>
          <h3 v-else style="font-family: 'boldRoboto'; font-size: 18px">
            Camping
          </h3>

          <h2>{{ currentParkMeta }}</h2>
        </div>

        <div class="shopifyCollectionWrap">
          <div
            id="collection-component-1684429205721"
            class="collectionDiv"
          ></div>
          <!-- <div id="loader"></div> -->
        </div>

        <v-divider class="divider" />

        <!-- Bear Lake && Jordanelle Specific -->
        <div
          v-if="
            parkName == 'Bear Lake State Park' ||
            parkName == 'Jordanelle State Park'
          "
        >
          <p class="passTitle">Pass Information {{ season }}</p>
          <div v-for="(location, index) in parkOptions" v-bind:key="index">
            <div class="parkOption" @click="parkOptionSelected(location)">
              <h3 class="parkOptionTitle">{{ location }}</h3>
              <img
                class="arrow"
                src="../assets/icons/arrow.svg"
                :class="{ flipArrow: currentParkOption == location }"
              />

              <div class="passGrid" v-if="currentParkOption == location">
                <div
                  v-for="(pass, i) in filteredParkOptions"
                  v-bind:key="i"
                  class="passContainer"
                >
                  <img
                    style="
                      height: 42px;
                      margin-top: 6px;
                      margin-right: 16px;
                      width: auto;
                    "
                    :src="require(`../assets/new_icons/${pass.image}`)"
                    :alt="pass.altDescription"
                  />
                  <div class="passMargin">
                    <p
                      v-if="pass.price"
                      class="passText"
                      style="line-height: 20px"
                    >
                      ${{ pass.price }}
                    </p>
                    <h4 class="passType">{{ pass.passType }}</h4>
                    <p class="passText">{{ pass.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div style="height: 16px"></div> -->
          <h3 class="passTextRed">*Pass is good for day of purchase only!</h3>
          <h3 class="passTextRed">
            *Purchase does not guarantee entry if park is temporarily closed due
            to capacity being reached.
          </h3>
        </div>

        <div v-else>
          <p class="passTitle">Pass Information {{ season }}</p>
          <div class="passGrid">
            <div
              class="passContainer"
              v-for="(pass, index) in passList"
              v-bind:key="index"
            >
              <img
                style="
                  height: 42px;
                  margin-top: 6px;
                  margin-right: 16px;
                  width: auto;
                "
                :src="require(`../assets/new_icons/${pass.image}`)"
                :alt="pass.image"
              />
              <div class="passMargin">
                <p class="passText" style="line-height: 20px">
                  ${{ pass.price }}
                </p>
                <h4 class="passType">{{ pass.passType }}</h4>
                <p class="passText">{{ pass.description }}</p>
              </div>
            </div>
          </div>
          <div v-if="parkName == 'Utahraptor State Park'">
            <p class="passTitle">License Plate Information</p>
            <p class="passText">
              This is for state park employees to verify that you have purchased
              a pass online.
            </p>
            <br />
            <p class="passTitle">Primitive Camping</p>
            <p class="passText">
              Utahraptor State Park is currently in the planning phases for
              major construction and amenities improvements. The area is
              currently undeveloped with only primitive recreation options. We
              ask visitors to remain patient during the planning process. Access
              to camping will be available on Dalton Wells and Willow Springs
              roads. Camp only in disturbed sites which contain a fire pit (do
              not build additional fire pits).
            </p>
            <p class="passTitle">Fires</p>
            <p class="passText">
              STAGE 2 FIRE RESTRICTINS begin June 2, 2022.
              <a
                href="https://utahfireinfo.gov/active-fire-restriction-documents/"
                >CLICK HERE</a
              >
              for more information and to see current statewide fire
              restrictions.
            </p>
            <p class="passText">
              Campfires are only allowed in existing fire pits. Do not leave
              fires unattended. Collecting and gathering firewood within the
              park is prohibited.
            </p>
          </div>
          <div v-if="parkName == 'Deer Creek State Park'">
            <v-divider class="divider" />
            <p class="passTitle" style="margin-bottom: 16px">
              Payment Instructions Upon Arrival
            </p>
            <p>1. Take a self-pay envelope.</p>
            <p>2. Write your confirmation number on the stub.</p>
            <p>3. Tear off the stub and display it on your dashboard.</p>
            <p>4. Place the envelope in the slot provided</p>
          </div>
          <!-- <div style="height: 16px"></div> -->
          <h3 class="passTextRed">*Pass is good for night of purchase only!</h3>
          <h3 class="passTextRed">
            *Purchase does not guarantee entry if park is temporarily closed due
            to capacity being reached.
          </h3>
        </div>
        <div class="shopifyBox2" v-if="ohv_details">
          <p class="passTitle">Waivers</p>
          <p class="passText">
            Sign your waiver(s) before you come to save time!
          </p>
          <p class="passText" style="margin: 16px 0px">
            *We have updated our waiver system. Waivers are now only required
            once a calendar year. New waivers accepted after 4/25/2020 will be
            good until the end of December. New Waivers are located below or at
            the park upon arrival!
          </p>
          <div>
            <a
              class="waiverLink"
              href="https://docs.google.com/forms/d/e/1FAIpQLSd74q1JodOuYaasSiayD5rcN9TSPyDMbtHB8jpsf1yi-g9nwA/viewform"
              target="_blank"
              rel="noopener noreferrer"
              style="margin-right: 32px"
              >Minor Waiver</a
            >
            <a
              class="waiverLink"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfG8ZCs_ri52FMnuDpId3FVvLBL7vWCoj2rVBgb6Dzj0sv38Q/viewform"
              target="_blank"
              rel="noopener noreferrer"
              >Adult Waiver</a
            >
          </div>
        </div>
        <!-- Park Information -->
        <!-- <div class="shopifyBox2" style="cursor: pointer" @click="displayInfo = !displayInfo">
              <p class="passTitle" style="display: inline-block;">Park Information</p>
              <img class="arrow" style="margin-top: 8px" src="../assets/icons/arrow.svg" :class="{flipArrow: displayInfo}">
              <div v-if="displayInfo">
                <p class="passText" style="margin: 6px 0px">{{parkDescription}}</p>
                <a class="parkLink" :href="learnLink" target="_blank" rel="noopener noreferrer">Learn More About {{parkName}}</a>
              </div>
          </div> -->
      </div>
    </div>
  </v-app>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import { collectionJson } from "../assets/ParkFiles/collectionTable";
import { parkPassesJson } from "../assets/ParkFiles/parkPassesJson";
import { parksJson } from "../assets/ParkFiles/parks";
import Header from "../components/Header.vue";

export default {
  name: "ParkDayuse",
  components: {
    Header,
  },
  data() {
    return {
      currentPark: "",
      currentParkMeta: "",
      parkName: "",
      parkPasses: parkPassesJson,
      parks: parksJson,
      collectionTable: collectionJson,
      shortParkName: "",
      parkLink: "",
      passList: [],
      passType: "Day-Use Passes",
      passSelected: "",
      currentPassInfo: [],
      parkOptions: [],
      currentParkOption: "",
      collectionId: "",
      holidays: [],
      learnLink: "",
      lakeWaiver: false,
      ohv_details: false,
      displayInfo: false,
      parkDescription: "",
      passesWithPlates: [],
      plateRequired: true,

      sdkReady: false,
      checkingInterval: null,
      dayBanner: true,
      dateParks: [
        "Bear Lake State Park",
        "Jordanelle State Park",
        "Deer Creek State Park",
        "Utah Lake State Park",
        "Willard Bay State Park",
      ],
      season: "",
      breadcrumbs: [],
    };
  },
  metaInfo() {
    return {
      title: this.currentPark.name + " | Day-Use Passes",
      meta: [{ name: "description", content: this.currentParkMeta }],
    };
  },
  mounted() {
    this.locationSelected(this.$route.params.name);
  },
  methods: {
    locationSelected: function (name) {
      for (let i = 0; i < this.parks.length; i++) {
        if (name == this.parks[i].abbr) {
          this.parkName = this.parks[i].name;
          this.currentParkMeta = this.parks[i].meta;
          this.currentPark = this.parks[i];
          this.breadcrumbs = [
            { text: "Home", disabled: false, href: "/" },
            // { text: this.parkName, disabled: false, href: '/parks/' + this.$route.params.name},
            {
              text: "Camping",
              disabled: true,
              href: "/parks/" + this.$route.params.name + "/dayuse",
            },
          ];
        }
      }
      for (let i = 0; i < this.collectionTable.length; i++) {
        if (this.parkName == this.collectionTable[i].name) {
          this.collectionId = this.collectionTable[i].collectionId;
          console.log("im looking for this collection ID: ", this.collectionId);
        }
      }
      var location = this.parkName;
      this.passList = [];
      this.ohv_details = false;
      this.shortParkName = location.replace(/\s+/g, "");
      for (let i = 0; i < this.parkPasses.length; i++) {
        if (this.parkPasses[i].name == location) {
          this.passList.push(this.parkPasses[i]);

          // ONLY FOR BEAR LAKE && JORDANELLE STATE PARK
          if (
            this.parkName == "Bear Lake State Park" ||
            this.parkName == "Jordanelle State Park"
          ) {
            if (this.parkOptions.indexOf(this.parkPasses[i].location) == -1) {
              this.parkOptions.push(this.parkPasses[i].location);
            }
          }
          // ONLY FOR JORDANELLE
          // if(this.parkName == 'Jordanelle State Park'){
          //   if(this.jordanelleOptions.indexOf(this.parkPasses[i].location) == -1){
          //       this.jordanelleOptions.push(this.parkPasses[i].location);
          //   }
          // }
        }
      }

      if (this.dateParks.includes(this.parkName)) {
        this.checkDates();
      }
      for (let i = 0; i < this.parks.length; i++) {
        if (this.parks[i].name == location) {
          this.learnLink = this.parks[i].site;
          this.parkDescription = this.parks[i].description;
        }
      }

      var lakes = [
        "Deer Creek State Park",
        "East Canyon State Park",
        "Echo State Park",
        "Fred Hayes State Park at Starvation",
        "Gunlock State Park",
        "Jordanelle State Park",
        "Quail Creek State Park",
        "Sand Hollow State Park",
        "Steinaker State Park",
        "Utah Lake State Park",
        "Willard Bay State Park",
        "Yuba State Park",
      ];
      this.detectPlates();
      if (lakes.includes(location)) {
        this.locations = false;
        // this.lakeWaiver = true;
        this.loadShopifyCollection();
      } else {
        this.loadShopifyCollection();
      }
      if (location == "Jordan River OHV State Park") {
        this.ohv_details = true;
      }
      if (this.parkName == "Bear Lake State Park") {
        this.currentParkOption = "Marina";
      } else if (this.parkName == "Jordanelle State Park") {
        this.currentParkOption = "Hailstone";
      }
    },
    detectPlates() {
      for (let i = 0; i < this.passList.length; i++) {
        if (this.passList[i].plate) {
          this.passesWithPlates.push(this.passList[i].passType);
        }
      }
    },
    checkDates: function () {
      var date = new Date();
      var newList = [];

      for (let i = 0; i < this.passList.length; i++) {
        var pass = this.passList[i];
        if (pass.start_date != "" || pass.end_date != "") {
          if (pass.start_date.includes("/") && pass.end_date.includes("/")) {
            var sd = new Date(
              date.getFullYear(),
              pass.start_date.split("/")[0] - 1,
              pass.start_date.split("/")[1],
              0,
              0,
              0,
              0
            );
            var ed = new Date(
              date.getFullYear(),
              pass.end_date.split("/")[0] - 1,
              pass.end_date.split("/")[1],
              0,
              0,
              0,
              0
            );
            if (date >= sd && date <= ed) {
              newList.push(pass);
              if (pass.season) {
                this.season = "( " + pass.season + " )";
              }
            }
          }
          // else{
          //   var hsd, hed;
          //   for(var j=0; j<this.holidays.length; j++){
          //     if(this.holidays[j].holiday == pass.start_date){
          //       hsd = this.holidays[j].date;
          //       hsd = new Date(hsd.split('/')[2], hsd.split('/')[0]-1, hsd.split('/')[1], 0, 0, 0, 0);
          //     }
          //     if(this.holidays[j].holiday == pass.end_date){
          //       hed = this.holidays[j].date;
          //       hed = new Date(hed.split('/')[2], hed.split('/')[0]-1, hed.split('/')[1], 0, 0, 0, 0);
          //     }
          //   }
          //   if(date >= hsd && date <= hed){
          //     newList.push(pass);
          //   }
          // }
        }
      }
      this.passList = newList;
    },
    parkOptionSelected: function (option) {
      if (this.currentParkOption != option) {
        this.currentParkOption = option;
      } else {
        this.currentParkOption = "";
      }
    },
    waiverAccepted: function () {
      this.filterSearch = "";
      this.loadShopifyCollection();
    },
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          this.ShopifyBuyInit(this.collectionId);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.ShopifyBuyInit(this.collectionId);
    },
    stopLoader: function () {
      document.getElementById("loader").style.display = "none";
    },
    ShopifyBuyInit: function (collectionId) {
      // this.initDivWatcher();
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("collection", {
          id: collectionId,
          node: document.getElementById("collection-component-1684429205721"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    width: "calc(25% - 20px)",
                  },
                  img: {
                    height: "calc(100% - 15px)",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                  },
                  imgWrapper: {
                    "padding-top": "calc(75% + 15px)",
                    position: "relative",
                    height: "0",
                  },
                },
                title: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "normal",
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                price: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                compareAt: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                unitPrice: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
              },
              buttonDestination: "cart",
              text: {
                button: "Buy now",
              },
              googleFonts: ["Roboto"],
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              popup: false,
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#96a145",
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  ":focus": {
                    "background-color": "#87913e",
                  },
                },
              },
            },
          },
        });
      });
    },
  },
  watch: {
    sdkReady: function (data) {
      if (data) {
        clearInterval(this.checkingInterval);
        this.locationSelected(this.$route.params.name);
      }
    },
  },
  computed: {
    filteredParkOptions() {
      return this.passList.filter(
        (pass) => pass.location === this.currentParkOption
      );
    },
  },
};
</script>

<style scoped>
.v-application {
  min-height: 0px !important;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.passGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
}
/* PRODUCT CSS DONE IN JS */

@media only screen and (max-width: 1400px) {
  .passGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 800px) {
  .passGrid {
    grid-template-columns: auto;
  }
}
.shopify-buy__option-select__select {
  height: 42px;
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
a {
  color: #96a145;
}
[data-element="option.select"] {
  height: 62px;
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
input[type="text"][name="properties[License plate]"] {
  border: 5px solid black !important;
}
.parkTitleWrap {
  text-align: left;
}
h1 {
  font-family: "boldRoboto";
  font-size: 36px;
  margin: 8px 0px;
  margin-top: 32px;
}
h2 {
  margin: 0px;
}
.divider {
  margin: 32px 0px;
}
.dayBanner {
  background-color: #409fae10;
  border: 2px solid #409fae;
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 16px;
  width: calc(100%);
}
.row {
  display: flex;
  padding-bottom: 42px;
}
.column {
  flex: 50%;
}
.bGreen {
  color: #96a145;
}
.column div {
  margin-left: 12px;
}
.column div b {
  font-size: 18px;
  font-family: "boldRoboto";
  margin-right: 14px;
  height: 22px;
  vertical-align: top;
}
.column div p {
  margin: 8px;
  margin-top: 12px;
  font-size: 16px;
  display: inline-block;
  width: 80%;
}
.Q {
  margin-top: 24px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  padding-top: 50px;
}
.shopifyBox,
.shopifyBox2 {
  background-color: white;
  display: inline-block;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 16px;
  position: relative;
  margin-left: 0px;
}
.shopifyBox2 {
  margin-top: 16px;
  width: 100%;
}
.waiverLink {
  font-size: 16px;
  color: #96a145;
}
.parkLink {
  font-size: 16px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
  display: block;
}
.passContainer {
  display: flex;
  margin: 12px 0px;
}
h4 {
  margin-top: 4px;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.passTitle {
  font-size: 18px;
  font-family: "boldRoboto";
  margin: 0px;
}
.passType {
  font-size: 16px;
  font-family: "boldRoboto";
  color: #424242;
  margin-top: 0px;
}
.searchHeader {
  color: white;
  font-size: 18px;
  display: inline;
  padding-top: 16px;
}
.search-page-wrap {
  padding: 8px;
}
.passTextRed {
  color: #fd251e;
  position: relative;
  bottom: 0px;
  padding-top: 16px;
  left: 0px;
  font-family: "boldRoboto";
  font-size: 16px;
  margin: 0px;
  margin-right: 16px;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
#overlay-card {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: black;
  background: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 16px;
  max-width: 500px;
}
#overlay-card h3 {
  font-family: "boldRoboto";
  font-size: 18px;
  margin: 8px 0px;
}
#overlay-card p {
  margin: 8px 0px;
}
#overlay-card div {
  color: #96a145;
  font-size: 16px;
  font-family: "mediumRoboto";
  display: inline;
  padding: 12px;
  border-radius: 40px;
  float: right;
}
#overlay-card div:hover {
  background: #96a14560;
}

.shopify-box2 p {
  margin: 8px 0px;
}
.shopify-box2 a {
  color: #96a145;
  font-size: 20px;
  font-family: "mediumRoboto";
  display: inline;
  padding: 12px;
  border-radius: 40px;
  float: right;
}
.shopify-box2 a:hover {
  background: #96a14560;
}
.flatTop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.flatBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.parkOption {
  padding: 12px;
  margin: 12px 0px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}
.parkOptionTitle {
  display: inline-block;
  font-size: 16px;
  font-family: "boldRoboto";
}
.arrow {
  float: right;
  height: 6px;
  margin-top: 10px;
  transform: rotate(0deg);
}
.flipArrow {
  transform: rotate(180deg);
}
.passText {
  display: inline-block;
  font-size: 16px;
  /* line-height: 32px; */
  margin: 0px;
  white-space: break-spaces;
}
h4 {
  margin-top: 4px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.passTitle {
  padding-top: 18px;
  font-size: 18px;
  font-family: "boldRoboto";
}
.passType {
  font-size: 16px;
  font-family: "boldRoboto";
  color: #424242;
  margin-top: 0px;
  margin: 0px;
}
.searchHeader {
  color: white;
  font-size: 18px;
  display: inline;
  padding-top: 16px;
}
.search-page-wrap {
  padding: 8px;
}
.passTextRed {
  color: #fd251e;
  position: relative;
  bottom: 0px;
  padding-top: 16px;
  left: 0px;
  font-family: "boldRoboto";
  font-size: 16px;
  margin-right: 16px;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}
@media only screen and (max-width: 1000px) {
  h1 {
    font-family: "boldRoboto";
    font-size: 24px;
    margin: 8px 0px;
    margin-top: 16px;
  }
  h2 {
    margin: 8px 0px;
    font-size: 14px;
  }
  .dayBanner {
    margin-bottom: 8px;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .passTitle {
    font-size: 18px;
  }
  .page-wrap {
    padding: 16px;
  }
}
/* spinner/processing state, errors */
#loader,
#loader:before,
#loader:after {
  border-radius: 50%;
}
#loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #96a145; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: loading 2s linear infinite;
  margin: 0 auto;
  margin-top: 60px;
}
@media only screen and (max-width: 600px) {
  #overlay-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
  }
}
@media only screen and (max-width: 400px) {
  .centered-home {
    margin-top: 24px;
  }
}
@media only screen and (max-height: 1200px) {
  .locationsWrap {
    max-height: 30vh;
  }
}
@media only screen and (max-height: 900px) {
  .locationsWrap {
    max-height: 20vh;
  }
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
</style>
