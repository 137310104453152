<template>
  <div style="background-color: white; min-height: 100vh">
    <Header />
    <div class="page-wrap">
      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>
      <h2>Dry Storage Permit</h2>
      <h1>Hobie Cat Dry Storage</h1>

      <div class="border waiver">
        <p style="padding: 28px 20px">
          The state of Utah, Division of Parks and Recreation hereby grants to
          the undersigned applicant a permit for the boat designated below to
          occupy storage space at Rockport State Park per the following terms
          and conditions:
        </p>
        <div style="padding: 0px 30px 30px 30px">
          <ol>
            <li>
              <strong class="bold">Dry Storage Permit Fee</strong> - To obtain
              reserved dry storage space, applicant hereby agrees to pay in
              advance on or before the first of each month, the monthly fee
              provided by the division's posted and approved fee schedule
              applicable to dry storage within the Utah State Park system. The
              division reserves the right to change this posted fee at any time
              during the term of this agreement upon Board approval. Failure by
              applicant to pay on time all appropriate fees and all other
              charges provided in the fee schedule in accordance with this
              agreement will result in loss of reserved dry storage space and
              may result in other actions by the state as outlined below in
              paragraph 5. This agreement shall not become effective until
              applicant pays the Division of Parks and Recreation the initial
              monthly fee and also pays an equal sum which shall be held by the
              division as a security deposit pending termination of this
              agreement and settlement by applicant of all sums due the
              division.
            </li>
            <li>
              <strong class="bold">Waiver of Responsibility</strong> - It is
              mutually agreed that the state shall not be liable for injury,
              loss or damage to said boat, its tackle, apparel, fixtures,
              equipment, and its trailer or other property of applicant or to
              the person of applicant or his agents or invitees arising from
              applicant's use of state dry storage facilities. Applicant hereby
              releases the state of Utah from all claims and causes of action
              therefore that exist or may arise in the future.
              <v-spacer></v-spacer>
              Applicant further agrees to indemnity and save harmless the state
              against any and all damages to property or injuries to or death of
              any person arising from applicant's use of state marina
              facilities, and to defend, indemnify and save harmless the state
              against any and all claims, actions, proceedings, expenses and
              liabilities whatsoever arising therefrom.
            </li>
            <li>
              <strong class="bold">Noncommercial Use Only</strong> - The state
              hereby grants applicant dry dock storage space for the boat and
              equipment so designated within this agreement at the above
              identified park area for the purposes of noncommercial use. Rights
              granted applicant under this agreement are nontransferable. The
              temporary use and occupancy of the premises and improvements
              herein may only be sublet by the applicant to third parties with
              prior written approval of the Utah Division of Parks and
              Recreation. The applicant, however, shall at all times, while this
              agreement is in effect be responsible for compliance with all
              conditions of this permit by persons to whom such premises may be
              sublet.
            </li>
            <li>
              <storage class="bold">Storage Maintenance</storage> - The
              applicant shall maintain his assigned dry storage area to
              standards of orderliness, neatness, sanitation, and safety
              acceptable to the state officer in charge.
            </li>
            <li>
              <strong class="bold">Default</strong> - In the event the applicant
              does not pay, as herein provided, the fees and/or other charges
              which are accrued in favor of the Division, or applicant otherwise
              violates the provisions of the agreement. the applicant shall be
              in default. If the applicant is in default, the Division shall
              have the right, at its option, to take possession the applicant's
              boat, motor, tackle, apparel, fixtures, equipment, and trailer,
              and hold the same as security for said payment plus any expenses
              which may reasonably be incurred by the Division in connection
              with the exercise of said right, including a reasonable attorney's
              fee. The Division may, at its option, retain such possession at
              the park area or elsewhere until all charges are fully paid. If
              such default continues for a period of thirty (30) days, the
              Division is expressly authorized and may, at its option, sell the
              boat, motor, tackle, apparel, fixtures, equipment, and trailer at
              public sale, after giving notice in writing to applicant at
              applicant's address as shown in this lease. The notice shall
              contain the time and place of such sale. The purchaser at such
              sale shall be entitled to possession of, and title to, the boat,
              motor, tackle, apparel, fixtures, equipment, and trailer upon
              payment of sale price to the Division. If the boat is sold as
              provided herein, the proceeds of such sale shall be applied first
              to payment of all accrued amounts due to the Division through the
              date of such sale, and all costs incurred thereto, including a
              reasonable attorney's fee. The excess, if any, shall be deposited
              by the Division in an escrow account and paid to applicant on
              applicant's written demand. In the event the proceeds of such sale
              do not pay such accrued amounts and costs, applicant shall
              continue to be liable to the Division for any such deficiency.
            </li>
            <li>
              <strong class="bold">Term</strong> - This agreement shall become
              effective on the date stated herein and shall remain in force
              until terminated (a) on the date stated within the permit, (b) for
              default pursuant to paragraph 5 above, or (c) by applicant ten
              (10) days preceding receipt of written notification by the
              division.
            </li>
            <li>
              <strong class="bold">Entire Agreement</strong> - Amendments - This
              constitutes the entire agreement between the parties. No
              modification or amendment of the agreement shall be valid unless
              evidenced in writing and signed by both parties.
            </li>
          </ol>
        </div>
      </div>
      <v-app style="margin-top: 7px">
        <div class="border waiver" style="padding: 0px 30px 30px 30px">
          <h6 style="text-align: left">Applicant</h6>
          <div class="inputFlex">
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[0].fnameError }"
              v-model="AdultData[0].firstname"
              label="First Name"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[0].fnameError"
              :error-messages="AdultData[0].fnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[0].lnameError }"
              v-model="AdultData[0].lastname"
              label="Last Name"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[0].lnameError"
              :error-messages="AdultData[0].lnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[0].emailError }"
              v-model="AdultData[0].email"
              label="Email"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[0].emailError"
              :error-messages="AdultData[0].emailMessage"
              error-color="#96a145"
            ></v-text-field>
          </div>
          <div class="error-text" v-show="AdultData[0].AagError == true">
            {{ AdultData[0].AagMessage }}
          </div>
          <v-checkbox
            class="waiver-checkbox"
            v-model="AdultData[0].agreed"
            v-on:change="checkCont()"
            color="#0096FF"
            label="I have carefully read and understand the contents of the foregoing language and agree to the terms and conditions stated above."
          ></v-checkbox>
        </div>
      </v-app>
      <v-card id="errorCard" v-show="errorCard == true">
        <v-card-title class="headline" style="color: #5a1213">
          All signatures and checkboxes are required.
        </v-card-title>
        <v-card-actions>
          <v-icon @click="errorCard = false">mdi-close</v-icon>
        </v-card-actions>
      </v-card>
      <div class="button-flex">
        <button class="contin" @click="cont()" v-show="continueButton == true">
          Continue
        </button>

        <button
          class="contin grey-button"
          @click="errorAlert()"
          v-show="continueButton == false && adultNumber != 0"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://sdks.shopifycdn.com/js-buy-sdk/latest/shopify-buy.umd.min.js"></script>

<script>
import $ from "jquery";
import Header from "../../components/Header.vue";
// import $ from "jquery";
export default {
  name: "Hobie Cat Dry Storage",
  metaInfo: {
    title: "Hobie Cat Waiver",
    meta: [
      {
        name: "description",
        content: "Hobie Cat Dry Storage Waiver",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      ticketType: [{ text: "Adult", value: "Adult" }],
      selectedTicket: "",
      adultNumber: 0,
      minorNumber: 0,
      AdultData: [],
      uuid: "",
      ready: false,
      continueButton: false,
      errorCard: false,
      redErrors: true,
      errorMessage: [],
      adultErrorMessage: [],
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Rockport State Park",
        disabled: false,
        href: "/parks/UTROCK/dayuse",
      },
      {
        text: "Hobie Cat Dry Storage",
        disabled: true,
        href: "/parks/UTROCK/drystorage/hobiewaiver",
      },
    ];
    this.uuid = this.generateUUID();
    this.addAdult();
  },
  mounted() {},
  methods: {
    addAdult() {
      this.adultNumber += 1;
      this.AdultData.push({
        passId: this.uuid,
        event: "43401192276121",
        // $400 hobie dry
        firstname: "",
        lastname: "",
        email: "",
        minor: false,
        agreed: false,
        guardianFirstName: null,
        guardianLastName: null,
        guardianAgreed: null,
        fnameError: false,
        fnameMessage: "",
        lnameError: false,
        lnameMessage: "",
        emailError: false,
        emailMessage: "",
        AagError: false,
        AagMessage: "",
      });
      console.log(this.AdultData);
    },
    removeAdult() {
      if (this.adultNumber > 0) {
        this.adultNumber -= 1;
        this.AdultData.pop();
      }
    },
    generateUUID() {
      // Public Domain/MIT
      let d = new Date().getTime(); //Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    showData() {
      console.log(this.AdultData);
    },
    checkCont() {
      console.log("here in the check funtions");
      // check to make sure that all the fields are filled out and the checkboxes are checked, and if so return true
      // if not return false
      var adultReady = 0;

      for (let y in this.AdultData) {
        if (
          this.AdultData[y].agreed == true &&
          this.AdultData[y].firstname != "" &&
          this.AdultData[y].lastname != "" &&
          this.AdultData[y].email != ""
        ) {
          adultReady += 1;
        }
      }
      if (adultReady == this.adultNumber) {
        this.continueButton = true;
        return true;
      } else {
        this.continueButton = false;
        return false;
      }
    },
    removeExtras() {
      console.log("removing all the error things");
      for (var i in this.AdultData) {
        delete this.AdultData[i].fnameError;
        delete this.AdultData[i].fnameMessage;
        delete this.AdultData[i].lnameError;
        delete this.AdultData[i].lnameMessage;
        delete this.AdultData[i].emailError;
        delete this.AdultData[i].emailMessage;
        delete this.AdultData[i].AagError;
        delete this.AdultData[i].AagMessage;
      }
    },
    cont() {
      this.removeExtras();

      var adultReady = 0;

      console.log("this is adult stuff: ", this.AdultData);

      for (let x in this.AdultData) {
        if (
          this.AdultData[x].agreed == true &&
          this.AdultData[x].firstname != "" &&
          this.AdultData[x].lastname != "" &&
          this.AdultData[x].email != ""
        ) {
          adultReady += 1;
        }
      }

      if (adultReady == this.adultNumber) {
        console.log("you can move onto the next page from adult");
        var _this = this;
        for (let f in this.AdultData) {
          var data = this.AdultData[f];
          console.log("data: ", data);
          data = JSON.stringify(data);
          console.log("data: ", data);
          $.ajax({
            url: "https://trailwaze.info/waivers/addWaiver.php",
            type: "POST",
            dataType: "json",
            data: data,
            success: function (json) {
              _this.json = json;
              console.log("new json: ", _this.json);
            },
            error: function (xhr, status, error) {
              console.log("Error: " + error);
            },
          });
        }

        console.log("everything worked!!!!");
        console.log("going to shopify now");
        // when we go to the waitlist comment out this.MakeShiftShopify(); and redirect to a page that says you have been added! Thank you!
        this.MakeShiftShopify();
      } else {
        alert("Please make sure all fields are filled out correctly.");
      }
    },
    errorAlert() {
      var goAhead = this.checkCont();
      if (goAhead == true) {
        console.log("PASSED!! going through the cont funciton");
        this.cont();
      } else {
        console.log("in error alert");
        this.errorMessage = [];
        this.adultErrorMessage = [];
        var message = "";
        var errors = [];
        for (let y in this.AdultData) {
          errors = [];
          message = "";
          if (this.AdultData[y].agreed == false) {
            message = `You must agree to the waiver`;
            errors.push(message);
            this.AdultData[y].AagError = true;
            this.AdultData[y].AagMessage = message;
          }
          if (this.AdultData[y].firstname == "") {
            message = `First name is required`;
            errors.push(message);
            this.AdultData[y].fnameError = true;
            this.AdultData[y].fnameMessage = message;
          }
          if (this.AdultData[y].lastname == "") {
            message = `Last name is required`;
            errors.push(message);
            this.AdultData[y].lnameError = true;
            this.AdultData[y].lnameMessage = message;
          }
          if (this.AdultData[y].email == "") {
            message = `Email is required`;
            errors.push(message);
            this.AdultData[y].emailError = true;
            this.AdultData[y].emailMessage = message;
          }
          console.log("errors: ", errors);
          for (let z in errors) {
            this.adultErrorMessage.push(errors[z]);
          }
          console.log("adult errors: ", this.adultErrorMessage);
          this.errorCard = true;
        }
      }
    },
    MakeShiftShopify() {
      console.log("make shift shopify");
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });

      var variantId = "43401192276121"; // The variant ID you want to add to the cart
      var quantity1 = 1; // The quantity of the item
      console.log("quantity: ", quantity1);
      variantId = window.btoa(`gid://shopify/ProductVariant/${variantId}`);

      client.checkout
        .create()
        .then((checkout) => {
          var lineItemsToAdd = [
            {
              variantId: variantId,
              quantity: quantity1,
            },
          ];
          return client.checkout.addLineItems(checkout.id, lineItemsToAdd);
        })
        .then((checkout) => {
          // Direct the user to the checkout
          //window.open(checkout.webUrl, "_blank", "height=600,width=425");
          window.location.href = checkout.webUrl;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    loadShopifyCollection: function () {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          //this.ShopifyBuyInit(this.collectionId);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      //create html script tag and run after it loads
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      //script.onload = this.ShopifyBuyInit(this.collectionId);
    },
  },
  computed: {
    adultArray() {
      return Array.from({ length: this.adultNumber }, (_, i) => i);
    },
    // minorArray() {
    //   return Array.from({ length: this.minorNumber }, (_, i) => i);
    // },
  },
};
</script>
<style>
.error-outline::v-deep .v-input__control .v-input__slot {
  border-color: #b3261e !important;
}
.waiver-checkbox .v-input__slot {
  align-items: flex-start;
}
.v-text-field.v-text-field--enclosed {
  padding: unset !important;
  margin: 0px 20px 0px 0px !important;
}
.v-messages__message {
  color: #b3261e;
}
.error-text {
  color: #b3261e !important;
  font-size: 16px;
  padding-top: 20px;
}
.v-application .error--text {
  color: #b3261e !important;
}
</style>

<style scoped>
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}
#errorCard {
  margin-top: 20px;
  background: #ec8183;
  border: 1px solid #5a1213;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.buttonFlex {
  flex-direction: row;
  align-items: center;
}
.bold {
  font-weight: 700;
}
.leftpadding {
  padding-left: 70px;
}
.warnings {
  padding-top: 0px;
  color: crimson;
}
.grey {
  background: #b1b1b1 !important;
  border: 1px solid #b1b1b1 !important;
}
.errorButton {
  background-color: #96a145 !important;
  color: #ffffff !important;
}
.contin {
  background: #96a145;
  border: 1px solid #96a145;
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding: 14px 16px;
  margin-top: 20px;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.grey-button {
  background: #b1b1b1;
  border: 1px solid #b1b1b1;
}
.v-input__slot {
  align-items: flex-start;
  padding-right: 8px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  padding-left: 8px;
}

.wavierInput {
  width: 264px;
  margin-right: 20px;
}
.inputFlex {
  display: flex;
}
p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494e53;
}
li {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494e53;
  padding-bottom: 20px;
  padding-left: 10px;
}
.spacer {
  margin: 15px;
}
.waiver {
  margin-top: 25px;
}
.numberButton {
  background-color: #96a145;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 10px;
}
.border {
  background: #ffffff;
  box-shadow: 0.5px 0.5px 6px 0.5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #494e53;
  text-align: center;
  padding: 28px 0px;
}
h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  margin: 0px 0px 50px 0px;
  color: #494e53;
}
.bold {
  color: #494e53;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.example-img {
  width: 250px;
  margin-bottom: 20px;
}
.example-holder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
p {
  line-height: 22px;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 34px;
  max-width: 50%;
}
.price {
  text-align: center;
  display: block;
  margin: 3px;
}
.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}
.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}
a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 15px;
}
@media only screen and (max-width: 850px) {
  .contin {
    left: 85%;
  }
  .passTitle {
    margin-top: 20px;
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 350px;
  }
}
@media only screen and (max-width: 425px) {
  .buttonFlex {
    flex-direction: column;
    align-items: center;
  }
  .warnings {
    font-size: 14px;
  }
  .leftpadding {
    padding-left: 0px;
    margin-top: 15px;
  }
  .contin {
    left: 73%;
  }
  .inputFlex {
    flex-direction: column;
  }
  .wavierInput {
    width: 100%;
    margin-bottom: 15px;
  }
  h1 {
    text-align: center;
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
    text-align: center;
  }
  .example-img {
    width: 70vw;
  }
}
@media only screen and (max-width: 320px) {
  .contin {
    left: 62%;
  }
  h1 {
    font-size: 26px;
    margin: 0px 0px 30px;
    line-height: 22px;
  }
}
</style>
