<template>
  <div style="background-color: white; min-height: 100vh">
    <Header></Header>

    <div class="page-wrap">
      <div v-if="dayBanner" class="dayBanner">
        <img
          src="../../assets/icons/blueInfo.png"
          style="
            display: inline-block;
            vertical-align: middle;
            margin: 0px 12px;
            height: 24px;
          "
        />

        <div>
          <p class="Attention">
            Attention: Pass is good for the day of purchase only!
          </p>
        </div>
        <img
          src="../../assets/icons/blueX.png"
          style="
            float: right;
            display: inline-block;
            margin: 12px 16px;
            cursor: pointer;
            height: 24px;
            margin-left: auto;
          "
          @click="dayBanner = false"
        />
      </div>

      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        style="padding: 8px 0px"
      ></v-breadcrumbs>
      <div class="parkTitleWrap">
        <h1 style="margin-top: 0px">{{ parkName }}</h1>

        <div>
          <h3 class="passTitle">Day-Use Camping Passes</h3>
        </div>
        <!-- <h2>{{ currentParkMeta }}</h2> -->
      </div>
      <div id="shopifyCollectionWrap" class="shopifyCollectionWrap">
        <div
          id="collection-component-1616176464282"
          class="collectionDiv"
        ></div>
      </div>
      <v-divider class="divider" />

      <p class="passTitle">Pass Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img
              src="../../assets/new_icons/vehicle.png"
              alt="person with checkmark in bottom right corner icon"
            />
            <div class="iconMargin">
              <!-- <p>$10.00</p> -->
              <div class="eventTitle" id="nomargin">Eagle View Camping</div>
              <p>
                Day-Use Pass and Campground Fee per day (includes 1 vehicle).
              </p>
            </div>
          </div>
        </div>
      </div>
      <h3 class="passTextRed">*Pass is good for day of purchase only!</h3>
      <h3 class="passTextRed">
        *Purchase does not guarantee entry if park is temporarily closed due to
        capacity being reached.
      </h3>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../components/Header.vue";
import $ from "jquery";
export default {
  name: "Jordan OHV",
  metaInfo: {
    title: "Jordan OHV",
    meta: [
      {
        name: "description",
        content: "Purchase your day use pass for Jordan OHV State Park.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      collectionId: "324304208025",
      parkName: "Yuba State Park",
      displayMonth: "",
      displayDay: "",
      dayBanner: true,
      date: new Date().toISOString().substr(0, 10),
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Yuba State Park",
        disabled: false,
        href: "/parks/UTYUBA/dayuse",
      },
      {
        text: "Eagle View Campground",
        disabled: true,
        href: "/parks/UTYUBA/camping/northbeach",
      },
    ];
  },
  mounted() {
    document.getElementById("header").scrollIntoView();

    console.log(this.collectionId);
    this.loadShopifyCollection();
    console.log(this.date);

    // if (this.date == "2024-03-29") {
    //   console.log("TODAYYYY");
    //   this.collectionId = "324304208025";
    // }
  },
  methods: {
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.ShopifyBuyInit(this.collectionId);
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    goBack() {
      this.$router.push("/parks/UTGREA/paintnight");
    },

    goToDayUse() {
      this.$router.push("/parks/UTGREA/dayuse");
    },

    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.ShopifyBuyInit(this.collectionId);
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
    ShopifyBuyInit: function (collectionId) {
      // this.initDivWatcher();
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("collection", {
          id: collectionId,
          node: document.getElementById("collection-component-1616176464282"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    width: "calc(25% - 20px)",
                  },
                  img: {
                    height: "calc(100% - 15px)",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                  },
                  imgWrapper: {
                    "padding-top": "calc(75% + 15px)",
                    position: "relative",
                    height: "0",
                  },
                },
                title: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "normal",
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                price: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                compareAt: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                unitPrice: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
              },
              buttonDestination: "cart",
              text: {
                button: "Buy now",
              },
              googleFonts: ["Roboto"],
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              popup: false,
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#96a145",
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  ":focus": {
                    "background-color": "#87913e",
                  },
                },
              },
            },
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.dayBanner {
  background-color: #409fae10;
  border: 2px solid #409fae;
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 16px;
  width: calc(100%);
}

.passTextRed {
  color: #fd251e;
  position: relative;
  bottom: 0px;
  padding-top: 16px;
  left: 0px;
  font-family: "boldRoboto";
  font-size: 16px;
  margin: 0px;
  margin-right: 16px;
}

h1 {
  font-family: "boldRoboto";
  font-size: 36px;
  margin: 8px 0px;
  margin-top: 32px;
}

.Attention {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  font-family: "boldRoboto";
}

.parkTitleWrap {
  text-align: left;
}

.eventSub {
  font-size: 36px;
  margin-top: 15px;
}

.hero-text {
  color: white;
}
.link {
  font-size: 16px;
  font-family: "Roboto";
  color: #96a145;
  cursor: pointer;
}
#backArrow {
  padding-right: 30px;
}
#date-sub {
  font-family: "Roboto";
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}
.date-head {
  font-family: "boldRoboto";
  font-size: 36px;
  line-height: 24px;
  padding-bottom: 20px;
}
#nomargin {
  margin-top: 0px;
}
.topCon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
}
.greenCard {
  background-color: #dde0c1 !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  flex-direction: row;
  display: none;
}

#glass-head {
  font-family: "boldRoboto";
  font-size: 20px;
  color: #000000;
}
#glass-sub {
  font-family: "Roboto";
  font-size: 16px;
  color: #000000;
}

.select-date-headings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  /* width: 19em; */
}

.select-date-h2 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.select-date-h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.v-breadcrumbs {
  margin: 0px 12px 18px 12px;
}
p {
  line-height: 22px;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 18px;
  font-family: "boldRoboto";
  margin: 0px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
  margin-top: 15px;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 75px;
  margin-right: 75px;
  max-width: 60%;
}

a {
  text-decoration: none;
  color: #96a145;
}

.noMarginimg {
  margin-top: 22px !important;
}
@media only screen and (max-width: 850px) {
  h1 {
    font-family: "boldRoboto";
    font-size: 24px;
    margin: 8px 0px;
    margin-top: 16px;
  }
  .passTitle {
    font-size: 18px;
    font-family: "boldRoboto";
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 450px;
  }
  .date-sub {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .eventSmallSlab {
    font-size: 16px;
    line-height: 18px;
  }
  .eventLargeSlab {
    font-size: 24px;
    line-height: 18px;
  }

  .eventSub {
    font-size: 16px;
    margin-top: 0px;
    line-height: 18px;
  }
  .topCon {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: stretch;
    padding-bottom: 0px;
  }

  .greenCard {
    display: flex;
    margin-bottom: 50px;
  }

  .date-sub {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 425px) {
  #backArrow {
    padding-right: 15px;
  }
  #date-sub {
    font-family: "Roboto";
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 0px;
  }
  .date-head {
    font-family: "boldRoboto";
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 375px) {
  #glass-head {
    font-size: 16px;
  }
  #glass-sub {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  #glassesBig {
    width: 18%;
  }
}
</style>
