<template>
  <v-app>
    <div class="header">
      <div class="header-cont">
        <img src="../../assets/images/parkspass-logo-2023.svg" alt="" />
        <h3>Check-In</h3>
      </div>
    </div>
    <div class="main-cont">
      <v-card class="card1">
        <h1 style="margin-bottom: 26px">Cash Envelope Payment</h1>
        <p>* Required Information</p>
        <div class="error-flex" v-if="error == true">
          <img src="../../assets/images/error_outline.svg" alt="" />
          <p class="red-text">Please fill out all the information below.</p>
        </div>
        <v-text-field
          label="* License Plate"
          messages="Must be 4-7 digits, no spaces"
          class="grey-background"
          color="#96A145"
          :maxlength="8"
          v-model="license_plate"
        ></v-text-field>
        <v-text-field
          style="margin-top: 16px"
          label="* Phone Number"
          class="grey-background"
          color="#96A145"
          v-model="phone_number"
        ></v-text-field>
        <div class="button-flex">
          <button class="green-button" @click="submit()">Submit</button>
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Cash Check-In",
  metaInfo: {
    title: "Cash Check-In",
    meta: [
      {
        name: "description",
        content: "Cash Check-In",
      },
    ],
  },
  data() {
    return {
      park_abbr: this.$route.params.park,
      license_plate: "",
      phone_number: "",
      error: false,
    };
  },
  methods: {
    submit() {
      console.log("Submitted");
      // Handle the form submission here
      if (this.license_plate != "" && this.phone_number != "") {
        console.log("ready to submit");
        // send plate to jared
        this.$router.push(`/${this.park_abbr}/checkin/brochure`);
      } else {
        console.log("missing info");
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  color: #444;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
h2 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}
h3 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
button {
  margin-right: 8px;
}
.header {
  background: #2e2e31;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
}
.header-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-cont {
  margin: 20px;
}

#app {
  height: 100vh;
  background: #f5f5f5;
}
.card1 {
  border-radius: 4px !important;
  border: 1px solid #8f8f8f !important;
  background: #fff !important;
  padding: 16px !important;
  box-shadow: none !important;
}
p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.green-button {
  border-radius: 29px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 8px 16px;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.red-text {
  color: var(--material-theme-sys-light-error, #ba1a1a);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 8px;
  margin-bottom: 0px;
}
.error-flex {
  display: flex;
  align-items: flex-start;
}
</style>
