<template>
  <div>
    <elevenlabs-convai agent-id="DBPliIk7o0vdLLRL6EX6"></elevenlabs-convai>
  </div>
</template>

<script>
export default {
  name: "ElevenLabsWidget",
  mounted() {
    // Load the script only once
    if (
      !document.querySelector(
        'script[src="https://elevenlabs.io/convai-widget/index.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://elevenlabs.io/convai-widget/index.js";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);
    }
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
