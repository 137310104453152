import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";
import VueMeta from "vue-meta";
import vuetify from "./plugins/vuetify"; // path to vuetify export

Vue.use(VueMeta, {
  keyName: "head",
});
Vue.use(vuetify);

Vue.config.productionTip = false;
// Vue.config.ignoredElements = [/^elevenlabs-convai$/];

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
