<template>
  <div style="background-color: white; min-height: 100vh">
    <Header />
    <!-- <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <img src="../../assets/images/palfishing.jpg" class="eventImg" />
      <div class="bottom-left">
        <p class="eventSmall whiteText" style="color: white">May 13, 2023</p>
        <h3 class="eventLarge whiteText">
          Palisade State Park Annual Fishing Tournament
        </h3>
        <p class="eventSub whiteText" style="color: white">
          Information and Registration
        </p>
      </div>
    </div> -->

    <div class="page-wrap">
      <div class="dayBanner">
        <img
          src="../../assets/icons/blueInfo.png"
          alt=""
          style="padding-top: 16px; padding-left: 12px"
        />
        <p class="Attention">
          Attention: Pass is good for the day of purchase only!
        </p>
      </div>

      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>

      <div id="collectFlex">
        <img src="../../assets/images/jordan-river.svg" alt="" />
        <!-- <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                  :items="ticketType"
                  label="Outlined style"
                  outlined
                  v-model="selectedTicket"
                  ></v-select>
              </v-col> -->
        <h6 id="selectHeader">Jordan River OHV State Park Day-Use Pass</h6>
        <button id="selectButton" @click="nextPage()">Sign Waiver</button>
      </div>

      <v-divider class="divider" />
      <p class="passTitle">Pass Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img src="../../assets/new_icons/Rider.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Rider</div>
              <p>
                $35 Day-Use Pass: Youth operators must be at least eight years
                of age and possess a valid education certificate.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/new_icons/Rider.svg" />
            <div class="iconMargin">
              <div class="eventTitle">50 Track Rider</div>
              <p>
                $15 Wristband. If you purchase a wristband for the 50 track, you
                will not be able to ride the other tracks for that day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../components/Header.vue";
import $ from "jquery";
export default {
  name: "Jordan River OHV Main",
  metaInfo: {
    title: "Jordan River OHV Main",
    meta: [
      {
        name: "description",
        content: "Purchase your day use pass for Jordan River OHV State Park.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      ticketType: [
        { text: "Adult", value: "Adult" },
        { text: "Minor", value: "Minor" },
      ],
      selectedTicket: "",
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Day-Use Pass",
        disabled: true,
        href: "/parks/" + this.$route.params.name + "/dayuse",
      },
    ];
  },
  mounted() {
    document.getElementById("header").scrollIntoView();
  },
  methods: {
    nextPage() {
      this.$router.push("/parks/UTJOHV/jordan/waiver");
    },
  },
};
</script>

<style scoped>
.greentext {
  margin: 0px;
  color: #96a145;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.Attention {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  font-family: "boldRoboto";
  padding: 16px 12px;
}

.mapoutline {
  position: relative;
  top: -117px;
}
.infoFlex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.map {
  width: 100%;
}
ul {
  margin-top: 10px;
}
li {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494e53;
  margin-bottom: 10px;
  margin-left: 17px;
}
.schedule > .priceFlex {
  align-items: flex-start !important;
}
.priceFlex > p {
  width: 63%;
  padding-left: 10px;
}
.times {
  width: 150px;
}
.priceFlex {
  display: flex;
  align-items: center;
}
p {
  margin-top: 16px;
  margin-bottom: 1px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #494e53;
}
.outline {
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  padding: 20px 30px;
  width: 45%;
  margin: 12px;
}
#collectFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#selectButton {
  background: #96a145;
  border: 1px solid #96a145;
  border-radius: 50px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  margin-top: 12px;
}
.dayBanner {
  background-color: #409fae10;
  border: 2px solid #409fae;
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 16px;
  width: calc(100%);
  display: flex;
  align-items: flex-start;
}
#selectHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #494e53;
  width: 288px;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
p {
  line-height: 22px;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px 0px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 34px;
  max-width: 50%;
}
.price {
  text-align: center;
  display: block;
  margin: 3px;
}
.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}
.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}
a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 15px;
}

@media only screen and (max-width: 1024px) {
  .mapoutline {
    top: -35px;
  }
}

@media only screen and (max-width: 768px) {
  .outline {
    width: 86%;
  }
  .mapoutline {
    position: unset;
    top: unset;
  }
}

@media only screen and (max-width: 850px) {
  .passTitle {
    margin-top: 20px;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 350px;
  }
}
@media only screen and (max-width: 425px) {
  .outline {
    padding: 18px 14px;
  }
  .passContainer2 {
    display: inline;
  }
  .priceFlex {
    align-items: flex-start;
  }
  .eventTitle {
    font-size: 16px;
  }
  .passTitle {
    margin-left: 28px;
  }
}
@media only screen and (max-width: 375px) {
  .eventTitle {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .passTitle {
    margin-left: 20px;
  }
}
</style>
