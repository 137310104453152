<template>
  <div>
    <v-app>
      <Header v-if="!mobileSearch" />
      <div v-if="mobileSearch == true" class="blackBack">
        <div class="search-page-wrap">
          <p class="searchHeader">Search a Utah State Park</p>
          <img
            class="white-x"
            src="../assets/icons/whitex.png"
            alt="white X"
            @click="mobileSearch = false"
          />
          <div class="searchBox" style="width: 100%; box-sizing: border-box">
            <v-autocomplete
              id="autocomplete-mobile"
              @load="mobileSearchLoaded()"
              @change="locationSelected()"
              @focus="searchFocus()"
              @blur="searchBlur()"
              v-model="park"
              auto-select-first
              background-color="#96A145"
              dark
              color="#FFFFFF"
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }"
              item-text="name"
              placeholder="Find your destination..."
              :items="parks"
              filled
              :rounded="!searchFocused"
              class="select-park-mobile"
            >
              <template v-slot:append>
                <v-icon color="white" style="margin-right: 8px"
                  >mdi-magnify</v-icon
                >
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
      <div
        v-bind:style="{ backgroundImage: 'url(' + homeHeroImg + ')' }"
        v-else
      >
        <div id="homeImgContainer">
          <div class="centered-home">
            <!-- <h2 class="home1">PLAN YOUR TRIP AHEAD!</h2> -->
            <h1 class="home2">
              Digital Utah State<br />
              Park Passes
            </h1>
            <h3 class="home3">Buy passes online.</h3>
            <v-autocomplete
              id="autocomplete"
              @change="locationSelected()"
              @focus="searchFocus()"
              @blur="searchBlur()"
              v-model="park"
              auto-select-first
              background-color="#96A145"
              dark
              color="#FFFFFF"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="name"
              placeholder="Find your destination..."
              :items="parks"
              filled
              :rounded="!searchFocused"
              class="select-park"
            >
              <template v-slot:append>
                <v-icon color="white" style="margin-right: 8px"
                  >mdi-magnify</v-icon
                >
              </template>
            </v-autocomplete>
            <div id="overlay" v-if="lakeWaiver">
              <div id="overlay-card">
                <h3>Quagga Informed Consent Agreement</h3>
                <p>
                  The visiting participant hereby acknowledges the following:
                </p>
                <p>
                  Watercraft and equipment must be decontaminated after use on
                  quagga-infested waters like Lake Powell. If decontamination
                  through extended drying times cannot be met, professional
                  treatments are necessary.
                </p>
                <div @click="waiverAccepted()">ACCEPT</div>
                <div @click="lakeWaiver = false">CANCEL</div>
              </div>
            </div>
          </div>
        </div>
        <div class="insta">
          <h2 id="instaHead">Explore with Parkspass</h2>
          <div class="instaMid">
            <img class="instaIcon" src="../assets/images/instagram.png" />
            <p class="instaText">
              Follow us
              <span style="font-family: 'boldRoboto'; color: #ffffff">
                @parkspass
              </span>
              for visitor tips, outdoor content, deals, and more!
            </p>
          </div>
          <div class="buttonContainer" v-if="!isMobile()">
            <!-- desktop -->
            <a
              id="signUpButton"
              href="https://instagram.com/parkspass?igshid=NWRhNmQxMjQ="
              >Follow Us</a
            >
          </div>
          <div class="buttonContainer" v-else>
            <a id="signUpButton" href="instagram://user?username=parkspass"
              >Follow Us</a
            >
          </div>
        </div>
        <div style="width: 100%; text-align: center">
          <img class="mapBanner" src="../assets/icons/mapBanner.png" />
        </div>
        <div id="mapContainer">
          <iframe
            src="https://utahparksmap.web.app"
            title="Utah Parks Map"
            class="mapIframe"
          ></iframe>
        </div>

        <Footer></Footer>
      </div>
    </v-app>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

import $ from "jquery";

import { parksJson } from "../assets/ParkFiles/parks";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      backgroundImage: "",
      mobile: false,
      locations: "",
      lakeWaiver: false,
      filterSearch: "",
      parks: parksJson,
      // parks_menu: parksJson,
      mobileSearch: false,
      park: "",
      searchFocused: false,
    };
  },
  metaInfo() {
    return {
      title: "Parkspass",
      titleTemplate: null,
      meta: [{ name: "description", content: this.currentParkMeta }],
    };
  },
  created() {
    console.log(this.parks);
    var newParks = [];
    for (let i in this.parks) {
      if ((this.parks[i].abbr != "DANUAL") & (this.parks[i].abbr != "ANNUAL")) {
        newParks.push(this.parks[i]);
      }
    }
    this.parks = newParks;
    // PARKS PASS APP
    if ($(window).width() <= 720) {
      this.mobile = true;
    }
    if (!this.parkFromArg()) {
      window.addEventListener("popstate", this.statePopped);
    }

    //this.homeHeroImg = "../assets/images/snowcanyon-large.jpg";
    this.homeHeroImg = "../assets/images/snowcanyon-small.jpg";

    // this.loadHomeImg();
    // this.parks_menu.shift()
  },
  updated() {
    if (this.mobileSearch) {
      document.getElementById("autocomplete-mobile").click();
      this.searchFocused = true;
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("is mobile");
        return true;
      } else {
        console.log("desktop");
        return false;
      }
    },
    searchFocus() {
      this.searchFocused = true;
      if (this.mobile) {
        this.mobileSearch = true;
      }
    },
    searchBlur() {
      this.searchFocused = false;
    },
    loadHomeImg: function () {
      var screenWidth = window.innerWidth;
      if (screenWidth < 500) {
        this.homeHeroImg = "../assets/images/snowcanyon-small.jpg";
      } else if (screenWidth < 800) {
        this.homeHeroImg = "../assets/images/snowcanyon-medium.jpg";
      } else if (screenWidth < 1400) {
        this.homeHeroImg = "../assets/images/snowcanyon-large.jpg";
      }
    },
    //get park from arguments
    parkFromArg: function () {
      // This function is solely used for the QR codes that are advertised in the parks to rederict them to the right place.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("p")) {
        const parkAbbr = urlParams.get("p").toUpperCase();
        const reg = /^[A-Z]{6}$/;
        if (!reg.test(parkAbbr)) {
          return false;
        }
        var parkName = null;
        var abbr = null;
        for (var i = 0; i < this.parks.length; i++) {
          if (this.parks[i].abbr == parkAbbr) {
            parkName = this.parks[i].name;
            abbr = this.parks[i].abbr;
            this.routerPush(abbr);
            break;
          }
        }
        if (parkName != null && abbr != null) {
          return true;
        }
        /*if (parkName !== null) {
          this.park = parkName;
          var abbr;
          for (var j = 0; j < this.parks.length; j++) {
            if (this.parks[j].name == this.park) {
              abbr = this.parks[j].abbr;
              this.routerPush(abbr);
              break;
            }
          }
          return true;
        } else {
          return false;
        }*/
      }
      return false;
    },
    locationSelected: function () {
      var abbr;
      for (var i = 0; i < this.parks.length; i++) {
        if (this.parks[i].name == this.park) {
          abbr = this.parks[i].abbr;
          this.routerPush(abbr);
          break;
        }
      }
    },
    routerPush(abbr) {
      if (abbr == "UTFLIG") {
        this.$router.push(`/parks/${abbr}/camping`);
      } else if (abbr == "UTRAPT") {
        this.$router.push(`/parks/${abbr}/camping`);
      }
      // else if (abbr == "UTJOHV") {
      //   this.$router.push("/parks/UTJOHV/jordan");
      // }
      else {
        this.$router.push(`/parks/${abbr}/dayuse`);
      }
    },
  },
};
</script>

<style>
.v-list-item__title {
  color: #96a145 !important;
}
.v-list-item:hover {
  background-color: #96a14520;
}
.v-list-item--highlighted {
  background-color: #96a14540;
}
.v-list-item--link:before {
  background-color: none;
}
</style>
<style scoped>
.select-park,
.select-park-mobile {
  z-index: 1;
  color: white;
  padding: 0px 8px !important;
  margin-top: 32px !important;
}
.select-park-mobile {
  margin-top: 6px !important;
}
.v-select ::v-deep(input) {
  font-size: 1.2em !important;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
html,
body {
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
a:link {
  text-decoration: none;
}
.previewImg {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 625px) {
  .previewImg {
    width: 200px;
  }
}
#previewImgContainer {
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Home Page */
#homeImgContainer {
  /* Full height */
  height: 84vh;
  background-image: url("../assets/images/snowcanyon-large.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.homepageContainer {
  max-width: 1200px;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.home1 {
  color: #ffffff;
  font-size: 24px;
  font-family: "boldRoboto";
  margin: 0px;
}
.home2 {
  color: #ffffff;
  font-size: 64px;
  font-family: "boldRoboto";
  margin: 0px;
  line-height: 72px;
  padding-top: 24px;
}
.home3 {
  color: #ffffff;
  font-size: 36px;
  font-family: "boldRoboto";
  margin: 0px;
}
.navbar {
  position: absolute;
  top: 68px;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.logo,
.logoSmall {
  width: auto;
  height: 62px;
}
.logoSmall {
  vertical-align: middle;
  width: auto;
  margin-top: 14px;
  cursor: pointer;
  margin-left: 20px;
}
.centered-home {
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: 700px;
  font-family: "boldRoboto";
  z-index: 1;
  padding-top: 200px;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.destinationsBtn {
  display: inline;
  font-size: 24px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.helpBtn {
  float: right;
  height: 24px;
  width: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 16px;
}
.searchContainer {
  position: relative;
  top: 32px;
  bottom: 0;
  width: 600px;
  margin: 0 auto;
  display: block;
}
.searchWrapper {
  position: relative;
  bottom: 0;
  height: 170px;
}
.singleBar:focus {
  outline: 0 none;
}
.search-page-wrap {
  padding: 8px;
  box-sizing: border-box;
}
.locationsWrap {
  background-color: white;
  width: 420px;
  height: 34vh;
  min-height: 200px;
  -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
  z-index: 50000;
  position: relative;
  padding-top: 0px;
  text-align: left;
  margin: 0 auto;
  border-radius: 20px;
}
.locationTextWrap {
  width: 100%;
  height: auto;
  padding: 0px;
}
.locationText {
  font-size: 24px;
  line-height: 32px;
  margin: -16px 0px;
  padding: 8px 4px;
  color: #96a145;
  list-style: none;
  cursor: pointer;
  padding-left: 26px;
}
.locationText:hover {
  background: #eaecda;
}
.search {
  background: #96a145;
  color: white;
  font-size: 24px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 26px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  height: 72px;
  width: 356px;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
.searchHeader {
  margin-top: 0px;
  color: white;
  font-size: 18px;
  margin-left: 16px;
  display: inline;
  padding-top: 16px;
}
.white-x {
  float: right;
  vertical-align: middle;
  height: 24px;
  margin-right: 16px;
  margin-top: -2px;
}
.mobileSearch {
  background: #96a145;
  color: white;
  font-size: 20px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 20px;
  height: 56px;
  width: 100%;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  padding-left: 26px !important;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.footer {
  width: 100%;
  height: auto;
  background: #1e252b;
  position: relative;
  vertical-align: middle;
  text-align: center;
  bottom: 0;
  padding-bottom: 10px;
}
.footerText {
  color: white;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}
.footerText a {
  font-family: "boldRoboto";
  font-size: 16px;
  cursor: pointer;
}
.social-button {
  background: #96a145;
  color: white;
  font-size: 18px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  padding: 4px 24px;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}
.homeMessage,
.homeMessage2 {
  width: 564px;
  height: 72px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  position: relative;
  padding: 0px 16px;
  padding-top: 2px;
  display: table-cell;
  vertical-align: middle;
}
.homeMessage2 {
  position: absolute;
  bottom: 30px;
  color: white;
  display: table;
}
.searchBtn {
  position: absolute;
  top: 0;
  height: 48px;
  width: 48px;
  background-color: #96a145;
  display: inline-block;
  cursor: pointer;
}
.searchImg {
  margin-top: 16px;
  margin-left: 16px;
  height: 15px;
  width: 15px;
}
.homeMessage p {
  color: white;
  font-size: 14px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  transform: translate(0, -50%);
}
.blackRect {
  width: 100%;
  height: 112px;
  background-color: #000000;
  display: block;
  position: relative;
  margin-top: -4px;
}
.blackRectLogo {
  color: white;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translate(0, -50%);
  height: 56px;
}
.blackRectLogo b {
  font-size: 18px;
  font-family: "boldRoboto";
}
.center {
  text-align: center;
}
#mapImg {
  width: 54px;
  height: 54px;
  margin: 54px;
}
h1 {
  font-family: "boldRoboto";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.86);
}
h2 {
  font-family: "boldRoboto";
  font-size: 36px;
}
.parkTitle {
  font-size: 48px;
  font-family: "boldRoboto";
  padding: 32px 0px;
}
.flatTop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.flatBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.parkOption {
  width: 100%;
  padding: 12px;
  margin: 12px 0px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}
.parkOptionTitle {
  display: inline-block;
  font-size: 16px;
  font-family: "boldRoboto";
}
.arrow {
  float: right;
  height: 6px;
  margin-top: 8px;
  transform: rotate(180deg);
}
.flipArrow {
  transform: rotate(0deg);
}
.mapBanner {
  margin: 0 auto;
  margin-top: 32px;
}
#mapContainer {
  height: 80vh;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-top: 32px;
}
.mapIframe {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: block;
}
.insta {
  background: #1e252b;
  padding: 20px;
}
#instaHead {
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.instaText {
  color: #ffffff;
}
.instaMid {
  display: -webkit-box;
  width: 310px;
  margin: auto;
  margin-top: 10px;
}
.instaIcon {
  padding: 5px 12px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 4px 20px;
}
#signUpButton {
  letter-spacing: 0.3px;
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 40px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 1000;
}

@media only screen and (max-width: 850px) {
  #instaHead {
    font-size: 24px;
  }
  .buttonContainer {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  #mapContainer {
    width: 90%;
    text-align: center;
  }
  .passMargin {
    margin-left: 16px;
  }
  .passTextRed {
    font-size: 16px;
    left: 16px;
    right: 16px;
  }
  .shopifyBox2 a {
    font-size: 16px;
  }
  .locationsWrap {
    height: 30vh;
  }
  .search {
    width: -webkit-fill-available;
    height: 48px;
    font-size: 16px;
    background-size: 16px;
  }
  .locationText {
    font-size: 16px;
    line-height: 24px;
  }
  .searchContainer {
    width: 90%;
  }
  .locationsWrap {
    width: 100%;
  }
  #homeImgContainer {
    background-image: url("../assets/images/snowcanyon-small.jpg");
    height: 70vh;
    overflow-y: hidden !important;
  }
  .home1,
  .home3 {
    font-size: 14px;
  }
  .home2 {
    padding-top: 14px;
    font-size: 48px;
    line-height: 48px;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
}
/* Galaxy fold */
@media only screen and (max-width: 345px) {
  .instaMid {
    width: 85%;
    margin: 0px;
  }
}
@media only screen and (max-width: 600px) {
  #overlay-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
  }
  .home2 {
    font-size: 30px;
  }
}
@media only screen and (max-height: 1000px) {
  .centered-home {
    margin-top: 0px;
    padding-top: 120px;
  }
}
@media only screen and (max-height: 800px) {
  .centered-home {
    margin-top: 0px;
    padding-top: 20px;
  }
}
@media only screen and (max-height: 1200px) {
  .locationsWrap {
    max-height: 30vh;
  }
}
@media only screen and (max-height: 900px) {
  .locationsWrap {
    max-height: 20vh;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  #homeImgContainer {
    background-image: url("../assets/images/snowcanyon-medium.jpg");
  }
}
</style>
