<template>
  <div style="background-color: white; min-height: 100vh">
    <Header></Header>
    <!-- <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <img
        src="../../assets/images/christmas-lights-2021.jpg"
        class="eventImg"
      />
      <img
        src="../../assets/images/christmas-lights-2021-1.jpg"
        class="eventImg-mobile"
      />
      <div>
        <div class="bottom-left">
          <p class="eventSmall whiteText">Nov 25 - Dec 30</p>
          <h3 class="eventLarge whiteText">2022 Fantasy at the Bay</h3>
          <p class="eventSub whiteText">Drive Through Light Show</p>
        </div>
      </div>
    </div> -->
    <div class="page-wrap">
      <div class="topCon">
        <v-breadcrumbs
          divider="/"
          :items="breadcrumbs"
          class="breadcrumbs"
        ></v-breadcrumbs>
      </div>

      <div class="select-date-headings">
        <h2 class="select-date-h2">Paint Night at the Park</h2>
        <h3 class="select-date-h3">Select a Date</h3>
      </div>
      <div class="collectionDiv"></div>
      <div class="all-dates-wrap">
        <div class="dates-wrap">
          <div class="painting-date">April 22</div>
          <div class="kids-wrap">
            <div class="icon-text-wrap">
              <div>
                <img src="../../assets/images/child.svg" alt="child logo" />
              </div>
              <div>
                <div>Kids (Ages 6-13)</div>
                <div class="date">April 22, 2023</div>
                <div>Sat, 3:00pm</div>
              </div>
            </div>
            <div class="buy-ticket-wrap">
              <div @click="kidApril22" class="ticket-button">Tickets</div>
              <div>$10.00</div>
            </div>
          </div>
          <div class="adult-wrap">
            <div class="icon-text-wrap">
              <div>
                <img
                  src="../../assets/images/pedestrian.svg"
                  alt="pedestrain logo to represent an adult"
                />
              </div>

              <div>
                <div>Adult (14+)</div>
                <div class="date">April 22, 2023</div>
                <div>Sat, 5:00pm</div>
              </div>
            </div>
            <div class="buy-ticket-wrap">
              <div @click="adultApril22" class="ticket-button">Tickets</div>
              <div>$10.00</div>
            </div>
          </div>
        </div>
        <div class="dates-wrap">
          <div class="painting-date">April 23</div>
          <div class="kids-wrap">
            <div class="icon-text-wrap">
              <div>
                <img src="../../assets/images/child.svg" alt="child logo" />
              </div>

              <div>
                <div>Kids (Ages 6-13)</div>
                <div class="date">April 23, 2023</div>
                <div>Sun, 3:00pm</div>
              </div>
            </div>
            <div class="buy-ticket-wrap">
              <div @click="kidApril23" class="ticket-button">Tickets</div>
              <div>$10.00</div>
            </div>
          </div>
          <div class="adult-wrap">
            <div class="icon-text-wrap">
              <div>
                <img
                  src="../../assets/images/pedestrian.svg"
                  alt="pedestrain logo to represent an adult"
                />
              </div>

              <div>
                <div>Adult (14+)</div>
                <div class="date">April 23, 2023</div>
                <div>Sun, 5:00pm</div>
              </div>
            </div>
            <div class="buy-ticket-wrap">
              <div @click="adultApril23" class="ticket-button">Tickets</div>
              <div>$10.00</div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <v-divider class="divider" />

      <p class="passTitle">Event Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img src="../../assets/images/flame.svg" alt="flame icon" />
            <div class="iconMargin">
              <div class="eventTitle">Overview</div>
              <p>
                This is a unique opportunity to Paint with a Ranger at Great
                Salt Lake State Park and Marina. This will be focused mostly on
                beginner skills but people of all skill levels are welcome to
                join. Paint what inspires you! We will be providing the
                watercolor paper, paintbrushes, and watercolor paint palettes.
                We will be set up by the Pavilion/ Lawn area. This event is $10
                per person + the $5 (per vehicle) entry fee.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <div style="display: flex">
              <img
                src="../../assets/images/pawprint.svg"
                alt="pawprint icon"
                style="margin-right: 14px"
              />
              <p>Dogs are welcome but must be on leash.</p>
            </div>
            <div class="iconMargin"></div>
          </div>
          <div class="passContainer">
            <img
              src="../../assets/images/checkmark-person.svg"
              alt="person with checkmark in bottom right corner icon"
            />
            <div class="iconMargin">
              <div class="eventTitle">Tickets Available</div>
              <p>
                Everyone must pre-register, we will only have 20 people max
                capacity for each event. If you are not pre-registered we can
                not ensure there will be room for the event. Tickets cost $10
                per person
              </p>
            </div>
          </div>
        </div>

        <div class="infoMargin2">
          <div class="passContainer">
            <img
              src="../../assets/images/location-gsl.svg"
              alt="location icon"
            />
            <div class="iconMargin">
              <div class="eventTitle">Location</div>
              <p>
                Meet at the Group Pavilion / Lawn area of the Great Salt Lake
                State Park (Exit 104 off of I-80). This event is not on Antelope
                Island
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/lantern-gsl.svg" alt="lantern icon" />
            <div class="iconMargin">
              <div class="eventTitle">What to bring</div>
              <p>
                Bring your own snacks or drinks if you'd like. If you have your
                own supplies you would like to use you are welcome to bring
                them. We will be providing watercolor paper sheets,
                paintbrushes, basic watercolor paint palette, and pencils for
                those registered.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/error.svg" alt="error icon" />
            <div class="iconMargin">
              <div class="eventTitle">Disclaimer</div>
              <p>*This event may be canceled if the weather is stormy</p>
              <p>
                **This is event is held at the Great Salt Lake Marina in Magna,
                and NOT on Antelope Island.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/call.svg" alt="phone icon" />
            <div class="iconMargin">
              <div class="eventTitle">Event Contact Info</div>
              <p>
                For more information about this event, contact the Great Salt
                Lake State Park Visitor Center at 801-828-0787
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../components/Header.vue";
import $ from "jquery";
//import Calendar from 'v-calendar/lib/components/calendar.umd'

export default {
  name: "PaintNight",
  metaInfo: {
    title: "Paint Night at the Park",
    meta: [
      {
        name: "description",
        content:
          "Purchase your day use pass for Paint Night at the Park Event.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      // collectionId: "397134823646",
      date: null,
      selectAttribute: {
        // dot: true,

        highlight: "red",
      },
      price: 15,
      selectedData: null,
      christmasDay: false,
      notAvailable: false,
      pricePerDay: true,
      btnDisabled: false,
    };
    currentDate = new Date();
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Great Salt Lake State Park",
        disabled: false,
        href: "/parks/" + this.$route.params.name + "/dayuse",
      },
      {
        text: "Paint Night at the Park",
        disabled: true,
        href: "/parks/UTGREA/groupfire",
      },
    ];
  },
  mounted() {
    // this.loadShopifyCollection();
    document.getElementById("header").scrollIntoView();
    //console.log(this.price);
  },
  methods: {
    kidApril22() {
      this.$router.push(`/parks/UTGREA/paintnight/Child/April/22`);
    },
    kidApril23() {
      this.$router.push(`/parks/UTGREA/paintnight/Child/April/23`);
    },
    adultApril22() {
      this.$router.push(`/parks/UTGREA/paintnight/Adult/April/22`);
    },
    adultApril23() {
      this.$router.push(`/parks/UTGREA/paintnight/Adult/April/23`);
    },
  },
};
</script>

<style scoped>
.ticket-button {
  width: 81px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #96a145;
  color: white;
  border-radius: 50px;
  margin-bottom: 5px;
}
.date-circle {
  background: #ff8080;
}
#nomargin {
  margin-top: 0px;
}
.topCon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.greenCard {
  background-color: #dde0c1 !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  flex-direction: row;
}
#glass-head {
  font-family: "boldRoboto";
  font-size: 20px;
  color: #000000;
}
#glass-sub {
  font-family: "Roboto";
  font-size: 16px;
  color: #000000;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.v-breadcrumbs {
  margin: 0px 12px 18px 12px;
}
p {
  line-height: 22px;
}

.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.select-date-headings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  /* width: 19em; */
}

.select-date-h2 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.select-date-h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

/* .get-dates-wrap {
  display: flex;
  justify-content: flex-end;
} */

.all-dates-wrap {
  display: flex;
}

.dates-wrap {
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  width: 330px;
  margin-right: 24px;
}

.painting-date {
  background-color: #e7ebc2;
  width: 330px;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.kids-wrap {
  border-bottom: #e6e6e6 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  padding: 0px 10px 0px 10px;
}

.icon-text-wrap {
  display: flex;
}

.adult-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  padding: 0px 10px 0px 10px;
}

.buy-ticket-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-wrap {
  border-bottom: 1px solid #666;
  border-top: 1px solid #666;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
  width: 20em;
  text-align: center;
}

.vc-container {
  border: none;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}

.link {
  font-size: 16px;
  font-family: "Roboto";
  color: #96a145;
  cursor: pointer;
}

.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}

.eventImg-mobile {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
  display: none;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* max-width: 1600px; */
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(56, 56, 56, 0));
}

.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 75px;
  max-width: 60%;
}
.price {
  text-align: center;
  display: block;
  margin: 3px;
}
.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}
.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}
a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 16px;
}
.noMarginimg {
  margin-top: 22px !important;
}
@media only screen and (max-width: 850px) {
  .passTitle {
    margin-top: 20px;
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }

  .eventImg {
    display: none;
  }
  .eventImg-mobile {
    /* filter: brightness(80%); */
    display: block;
    object-position: top;
    height: 450px;
  }
}
@media only screen and (max-width: 768px) {
  .all-dates-wrap {
    flex-direction: column;
  }

  .dates-wrap {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 20px;
  }

  .painting-date {
    background-color: #e7ebc2;
    width: 100%;
  }

  .kids-wrap {
    border-bottom: #e6e6e6 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 108px;
    padding: 0px 10px 0px 10px;
  }

  .icon-text-wrap {
    display: flex;
  }

  .adult-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 108px;
    padding: 0px 10px 0px 10px;
  }
  .topCon {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
  }
  .passTitle {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 375px) {
  #glass-head {
    font-size: 16px;
  }
  #glass-sub {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  #glassesBig {
    width: 18%;
  }
}
</style>
