<template id="white-back">
  <div>
    <Header />
    <div id="side-flex">
      <div id="ranger">
        <img src="../assets/images/rangerDalle.png" alt="" id="ranger-pic" />
      </div>
      <div id="down-flex">
        <div class="box">
          <h1>Park Support</h1>
          <h2>Ask Me Anything</h2>
        </div>
        <div class="box">
          <h2>Disclaimer</h2>
          <p>
            This AI robot agent is designed to provide information and
            assistance to the best of its ability based on available data. It is
            not a substitute for human expertise or judgment and may not always
            provide accurate, complete, or up-to-date information. All
            interactions with this AI agent should be considered as guidance
            only, and users are encouraged to verify any information provided.
            The developers and operators of this AI assume no responsibility for
            any errors, omissions, or outcomes arising from its use. For
            critical decisions or specific advice, please consult a qualified
            professional or human agent.
          </p>
        </div>
      </div>
    </div>
    <!-- Other page content -->
    <ElevenLabsWidget />
  </div>
</template>

<script>
import ElevenLabsWidget from "@/components/ElevenLabsWidget.vue";
import Header from "@/components/Header";

export default {
  components: {
    ElevenLabsWidget,
    Header,
  },
};
</script>

<style scoped>
#side-flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
  margin-top: 40px;
}
#ranger {
  border-radius: 4px;
  border: 1px solid #808080;
  background: #fff;
  padding: 24px;
}
#down-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  border-radius: 4px;
  border: 1px solid #808080;
  background: #fff;
  padding: 24px;
  width: 40vw;
  margin-left: 20px;
  margin-bottom: 20px;
}
h1 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h2 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 767px) {
  /* CSS rules for screens smaller than 768px */
  #side-flex {
    flex-direction: column;
    align-items: center;
  }
  #ranger {
    margin-bottom: 20px;
    width: 80vw;
  }
  .box {
    width: 80vw;
    margin-left: 0;
  }
  #ranger-pic {
    width: 100%;
  }
  #side-flex {
    margin-bottom: 200px;
  }
}
@media (max-width: 321px) {
  h1 {
    font-size: 32px;
  }
}
</style>
