<template>
  <div>
    <Header />
    <div style="text-align: center">
      <img
        src="../assets/images/infographic.jpg"
        width="100%"
        height="auto"
        style="max-width: 600px"
      />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";

export default {
  components: {
    Header,
  },
  name: "InfoGraphic",
  metaInfo: {
    title: "Infographic",
    meta: [{ name: "description", content: "Save the turtles!" }],
  },
};
</script>
