<template>
  <v-app>
    <div class="header">
      <div class="header-cont">
        <img src="../../assets/images/parkspass-logo-2023.svg" alt="" />
        <h3 class="white-text">Check-In</h3>
      </div>
    </div>
    <div class="main-cont">
      <v-card class="card1">
        <img src="../../assets/images/success-check.svg" alt="" />
        <div class="down-flex">
          <h1>Checked-In</h1>
          <h3>You have been successfully checked-in. Enjoy your visit!</h3>
        </div>
      </v-card>
      <!-- add images -->
      <div class="imgcont" v-show="parkabbr == 'UTANAS'">
        <img src="../../assets/brochure/UTANAS-1.jpg" />
        <img src="../../assets/brochure/UTANAS-2.jpg" />
        <img src="../../assets/brochure/UTANAS-3.jpg" />
        <img src="../../assets/brochure/UTANAS-4.jpg" />
        <img src="../../assets/brochure/UTANAS-5.jpg" />
        <img src="../../assets/brochure/UTANAS-6.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTANTE'">
        <img src="../../assets/brochure/UTANTE-1.jpg" />
        <img src="../../assets/brochure/UTANTE-2.jpg" />
        <img src="../../assets/brochure/UTANTE-3.jpg" />
        <img src="../../assets/brochure/UTANTE-4.jpg" />
        <img src="../../assets/brochure/UTANTE-5.jpg" />
        <img src="../../assets/brochure/UTANTE-6.jpg" />
        <img src="../../assets/brochure/UTANTE-7.jpg" />
        <img src="../../assets/brochure/UTANTE-8.jpg" />
        <img src="../../assets/brochure/UTANTE-9.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTBEAR'">
        <img src="../../assets/brochure/UTBEAR-1.jpg" />
        <img src="../../assets/brochure/UTBEAR-2.jpg" />
        <img src="../../assets/brochure/UTBEAR-3.jpg" />
        <img src="../../assets/brochure/UTBEAR-4.jpg" />
        <img src="../../assets/brochure/UTBEAR-5.jpg" />
        <img src="../../assets/brochure/UTBEAR-6.jpg" />
        <img src="../../assets/brochure/UTBEAR-7.jpg" />
        <img src="../../assets/brochure/UTBEAR-8.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTCAMP'">
        <img src="../../assets/brochure/UTCAMP-1.jpg" />
        <img src="../../assets/brochure/UTCAMP-2.jpg" />
        <img src="../../assets/brochure/UTCAMP-3.jpg" />
        <img src="../../assets/brochure/UTCAMP-4.jpg" />
        <img src="../../assets/brochure/UTCAMP-5.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTCORA'">
        <img src="../../assets/brochure/UTCORA-1.jpg" />
        <img src="../../assets/brochure/UTCORA-2.jpg" />
        <img src="../../assets/brochure/UTCORA-3.jpg" />
        <img src="../../assets/brochure/UTCORA-4.jpg" />
        <img src="../../assets/brochure/UTCORA-5.jpg" />
        <img src="../../assets/brochure/UTCORA-6.jpg" />
        <img src="../../assets/brochure/UTCORA-7.jpg" />
        <img src="../../assets/brochure/UTCORA-8.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTDEAD'">
        <img src="../../assets/brochure/UTDEAD-1.jpg" />
        <img src="../../assets/brochure/UTDEAD-2.jpg" />
        <img src="../../assets/brochure/UTDEAD-3.jpg" />
        <img src="../../assets/brochure/UTDEAD-4.jpg" />
        <img src="../../assets/brochure/UTDEAD-5.jpg" />
        <img src="../../assets/brochure/UTDEAD-6.jpg" />
        <img src="../../assets/brochure/UTDEAD-7.jpg" />
      </div>

      <div class="imgcont" v-show="parkabbr == 'UTDEER'">
        <img src="../../assets/brochure/UTDEER-1.jpg" />
        <img src="../../assets/brochure/UTDEER-2.jpg" />
        <img src="../../assets/brochure/UTDEER-3.jpg" />
        <img src="../../assets/brochure/UTDEER-4.jpg" />
        <img src="../../assets/brochure/UTDEER-5.jpg" />
        <img src="../../assets/brochure/UTDEER-6.jpg" />
        <img src="../../assets/brochure/UTDEER-7.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTEAST'">
        <img src="../../assets/brochure/UTEAST-1.jpg" />
        <img src="../../assets/brochure/UTEAST-2.jpg" />
        <img src="../../assets/brochure/UTEAST-3.jpg" />
        <img src="../../assets/brochure/UTEAST-4.jpg" />
        <img src="../../assets/brochure/UTEAST-5.jpg" />
        <img src="../../assets/brochure/UTEAST-6.jpg" />
        <img src="../../assets/brochure/UTEAST-7.jpg" />
        <img src="../../assets/brochure/UTEAST-8.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTECHO'">
        <img src="../../assets/brochure/UTECHO-1.jpg" />
        <img src="../../assets/brochure/UTECHO-2.jpg" />
        <img src="../../assets/brochure/UTECHO-3.jpg" />
        <img src="../../assets/brochure/UTECHO-4.jpg" />
        <img src="../../assets/brochure/UTECHO-5.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTEDGE'">
        <img src="../../assets/brochure/UTEDGE-1.jpg" />
        <img src="../../assets/brochure/UTEDGE-2.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTESCA'">
        <img src="../../assets/brochure/UTESCA-1.jpg" />
        <img src="../../assets/brochure/UTESCA-2.jpg" />
        <img src="../../assets/brochure/UTESCA-3.jpg" />
        <img src="../../assets/brochure/UTESCA-4.jpg" />
        <img src="../../assets/brochure/UTESCA-5.jpg" />
        <img src="../../assets/brochure/UTESCA-6.jpg" />
        <img src="../../assets/brochure/UTESCA-7.jpg" />
        <img src="../../assets/brochure/UTESCA-8.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTFLIG'">
        <img src="../../assets/brochure/UTFLIG-1.jpg" />
        <img src="../../assets/brochure/UTFLIG-2.jpg" />
        <img src="../../assets/brochure/UTFLIG-3.jpg" />
        <img src="../../assets/brochure/UTFLIG-4.jpg" />
        <img src="../../assets/brochure/UTFLIG-5.jpg" />
        <img src="../../assets/brochure/UTFLIG-6.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTFREM'">
        <img src="../../assets/brochure/UTFREM-1.jpg" />
        <img src="../../assets/brochure/UTFREM-2.jpg" />
        <img src="../../assets/brochure/UTFREM-3.jpg" />
        <img src="../../assets/brochure/UTFREM-4.jpg" />
        <img src="../../assets/brochure/UTFREM-5.jpg" />
        <img src="../../assets/brochure/UTFREM-6.jpg" />
        <img src="../../assets/brochure/UTFREM-7.jpg" />
        <img src="../../assets/brochure/UTFREM-8.jpg" />
        <img src="../../assets/brochure/UTFREM-9.jpg" />
        <img src="../../assets/brochure/UTFREM-10.jpg" />
        <img src="../../assets/brochure/UTFREM-11.jpg" />
        <img src="../../assets/brochure/UTFREM-12.jpg" />
        <img src="../../assets/brochure/UTFREM-13.jpg" />
        <img src="../../assets/brochure/UTFREM-14.jpg" />
        <img src="../../assets/brochure/UTFREM-15.jpg" />
        <img src="../../assets/brochure/UTFREM-16.jpg" />
        <img src="../../assets/brochure/UTFREM-17.jpg" />
        <img src="../../assets/brochure/UTFREM-18.jpg" />
        <img src="../../assets/brochure/UTFREM-19.jpg" />
        <img src="../../assets/brochure/UTFREM-20.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTFRON'">
        <img src="../../assets/brochure/UTFRON-1.jpg" />
        <img src="../../assets/brochure/UTFRON-2.jpg" />
        <img src="../../assets/brochure/UTFRON-3.jpg" />
        <img src="../../assets/brochure/UTFRON-4.jpg" />
        <img src="../../assets/brochure/UTFRON-5.jpg" />
        <img src="../../assets/brochure/UTFRON-6.jpg" />
        <img src="../../assets/brochure/UTFRON-7.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTGOBL'">
        <img src="../../assets/brochure/UTGOBL-1.jpg" />
        <img src="../../assets/brochure/UTGOBL-2.jpg" />
        <img src="../../assets/brochure/UTGOBL-3.jpg" />
        <img src="../../assets/brochure/UTGOBL-4.jpg" />
        <img src="../../assets/brochure/UTGOBL-5.jpg" />
        <img src="../../assets/brochure/UTGOBL-6.jpg" />
        <img src="../../assets/brochure/UTGOBL-7.jpg" />
        <img src="../../assets/brochure/UTGOBL-8.jpg" />
        <img src="../../assets/brochure/UTGOBL-9.jpg" />
        <img src="../../assets/brochure/UTGOBL-10.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTGREA'">
        <img src="../../assets/brochure/UTGREA-1.jpg" />
        <img src="../../assets/brochure/UTGREA-2.jpg" />
        <img src="../../assets/brochure/UTGREA-3.jpg" />
        <img src="../../assets/brochure/UTGREA-4.jpg" />
        <img src="../../assets/brochure/UTGREA-5.jpg" />
        <img src="../../assets/brochure/UTGREA-6.jpg" />
        <img src="../../assets/brochure/UTGREA-7.jpg" />
        <img src="../../assets/brochure/UTGREA-8.jpg" />
        <img src="../../assets/brochure/UTGREA-9.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTGREE'">
        <img src="../../assets/brochure/UTGREE-1.jpg" />
        <img src="../../assets/brochure/UTGREE-2.jpg" />
        <img src="../../assets/brochure/UTGREE-3.jpg" />
        <img src="../../assets/brochure/UTGREE-4.jpg" />
        <img src="../../assets/brochure/UTGREE-5.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTGUNL'">
        <img src="../../assets/brochure/UTGUNL-1.jpg" />
        <img src="../../assets/brochure/UTGUNL-2.jpg" />
        <img src="../../assets/brochure/UTGUNL-3.jpg" />
        <img src="../../assets/brochure/UTGUNL-4.jpg" />
        <img src="../../assets/brochure/UTGUNL-5.jpg" />
        <img src="../../assets/brochure/UTGUNL-6.jpg" />
        <img src="../../assets/brochure/UTGUNL-7.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTHYRU'">
        <img src="../../assets/brochure/UTHYRU-1.jpg" />
        <img src="../../assets/brochure/UTHYRU-2.jpg" />
        <img src="../../assets/brochure/UTHYRU-3.jpg" />
        <img src="../../assets/brochure/UTHYRU-4.jpg" />
        <img src="../../assets/brochure/UTHYRU-5.jpg" />
        <img src="../../assets/brochure/UTHYRU-6.jpg" />
        <img src="../../assets/brochure/UTHYRU-7.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTJORD'">
        <img src="../../assets/brochure/UTJORD-1.jpg" />
        <img src="../../assets/brochure/UTJORD-2.jpg" />
        <img src="../../assets/brochure/UTJORD-3.jpg" />
        <img src="../../assets/brochure/UTJORD-4.jpg" />
        <img src="../../assets/brochure/UTJORD-5.jpg" />
        <img src="../../assets/brochure/UTJORD-6.jpg" />
        <img src="../../assets/brochure/UTJORD-7.jpg" />
        <img src="../../assets/brochure/UTJORD-8.jpg" />
        <img src="../../assets/brochure/UTJORD-9.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTKODA'">
        <img src="../../assets/brochure/UTKODA-1.jpg" />
        <img src="../../assets/brochure/UTKODA-2.jpg" />
        <img src="../../assets/brochure/UTKODA-3.jpg" />
        <img src="../../assets/brochure/UTKODA-4.jpg" />
        <img src="../../assets/brochure/UTKODA-5.jpg" />
        <img src="../../assets/brochure/UTKODA-6.jpg" />
        <img src="../../assets/brochure/UTKODA-7.jpg" />
        <img src="../../assets/brochure/UTKODA-8.jpg" />
        <img src="../../assets/brochure/UTKODA-9.jpg" />
        <img src="../../assets/brochure/UTKODA-10.jpg" />
        <img src="../../assets/brochure/UTKODA-11.jpg" />
        <img src="../../assets/brochure/UTKODA-12.jpg" />
        <img src="../../assets/brochure/UTKODA-13.jpg" />
        <img src="../../assets/brochure/UTKODA-14.jpg" />
        <img src="../../assets/brochure/UTKODA-15.jpg" />
        <img src="../../assets/brochure/UTKODA-16.jpg" />
        <img src="../../assets/brochure/UTKODA-17.jpg" />
        <img src="../../assets/brochure/UTKODA-18.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTOTTE'">
        <img src="../../assets/brochure/UTOTTE-1.jpg" />
        <img src="../../assets/brochure/UTOTTE-2.jpg" />
        <img src="../../assets/brochure/UTOTTE-3.jpg" />
        <img src="../../assets/brochure/UTOTTE-4.jpg" />
        <img src="../../assets/brochure/UTOTTE-5.jpg" />
        <img src="../../assets/brochure/UTOTTE-6.jpg" />
        <img src="../../assets/brochure/UTOTTE-7.jpg" />
        <img src="../../assets/brochure/UTOTTE-8.jpg" />
        <img src="../../assets/brochure/UTOTTE-9.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTPIUT'">
        <img src="../../assets/brochure/UTPIUT-1.jpg" />
        <img src="../../assets/brochure/UTPIUT-2.jpg" />
        <img src="../../assets/brochure/UTPIUT-3.jpg" />
        <img src="../../assets/brochure/UTPIUT-4.jpg" />
        <img src="../../assets/brochure/UTPIUT-5.jpg" />
        <img src="../../assets/brochure/UTPIUT-6.jpg" />
        <img src="../../assets/brochure/UTPIUT-7.jpg" />
        <img src="../../assets/brochure/UTPIUT-8.jpg" />
        <img src="../../assets/brochure/UTPIUT-9.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTQUAI'">
        <img src="../../assets/brochure/UTQUAI-1.jpg" />
        <img src="../../assets/brochure/UTQUAI-2.jpg" />
        <img src="../../assets/brochure/UTQUAI-3.jpg" />
        <img src="../../assets/brochure/UTQUAI-4.jpg" />
        <img src="../../assets/brochure/UTQUAI-5.jpg" />
        <img src="../../assets/brochure/UTQUAI-6.jpg" />
        <img src="../../assets/brochure/UTQUAI-7.jpg" />
        <img src="../../assets/brochure/UTQUAI-8.jpg" />
        <img src="../../assets/brochure/UTQUAI-9.jpg" />
        <img src="../../assets/brochure/UTQUAI-10.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTREDF'">
        <img src="../../assets/brochure/UTREDF-1.jpg" />
        <img src="../../assets/brochure/UTREDF-2.jpg" />
        <img src="../../assets/brochure/UTREDF-3.jpg" />
        <img src="../../assets/brochure/UTREDF-4.jpg" />
        <img src="../../assets/brochure/UTREDF-5.jpg" />
        <img src="../../assets/brochure/UTREDF-6.jpg" />
        <img src="../../assets/brochure/UTREDF-7.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTSAND'">
        <img src="../../assets/brochure/UTSAND-1.jpg" />
        <img src="../../assets/brochure/UTSAND-2.jpg" />
        <img src="../../assets/brochure/UTSAND-3.jpg" />
        <img src="../../assets/brochure/UTSAND-4.jpg" />
        <img src="../../assets/brochure/UTSAND-5.jpg" />
        <img src="../../assets/brochure/UTSAND-6.jpg" />
        <img src="../../assets/brochure/UTSAND-8.jpg" />
      </div>
      <div class="imgcont" v-show="parkabbr == 'UTFHNH'">
        <img src="../../assets/brochure/UTFHNH-1.jpg" />
        <img src="../../assets/brochure/UTFHNH-2.jpg" />
        <img src="../../assets/brochure/UTFHNH-3.jpg" />
        <img src="../../assets/brochure/UTFHNH-4.jpg" />
        <img src="../../assets/brochure/UTFHNH-5.jpg" />
        <img src="../../assets/brochure/UTFHNH-6.jpg" />
        <img src="../../assets/brochure/UTFHNH-8.jpg" />
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Successful Check-In",
  metaInfo: {
    title: "Successful Check-In",
    meta: [
      {
        name: "description",
        content: "Successful Check-In",
      },
    ],
  },
  data() {
    return {
      info: false,
      parkabbr: "",
    };
  },
  created() {
    this.parkabbr = this.$route.params.park;

    console.log("parkabbr: ", this.parkabbr);
  },
  methods: {
    submit() {
      console.log("Submitted");
      // Handle the form submission here
    },
  },
};
</script>

<style scoped>
h1 {
  color: #212121;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
h2 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}
h3 {
  color: var(--material-theme-sys-light-on-primary-container, #222);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
h4 {
  color: #222;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.green-text {
  color: #96a145;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
}
button {
  margin-right: 8px;
}
.header {
  background: #2e2e31;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
}
.header-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-cont {
  margin: 20px;
}

#app {
  height: 100vh;
  background: #f5f5f5;
}
.card1 {
  border-radius: 4px !important;
  border: 1px solid #8f8f8f !important;
  background: #fff !important;
  padding: 16px !important;
  box-shadow: none !important;
  display: flex;
  align-items: flex-start;
}
p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.imgcont {
  margin-top: 20px;
}
.imgcont > img {
  width: 100%;
}
.green-button {
  border-radius: 29px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  padding: 8px 16px;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.icon-help img {
  width: 24px;
  height: 24px;
}
#input-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-card {
  border-radius: 4px;
  background: #2e2e31 !important;
  padding: 14px;
}
.white-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
.underline {
  text-decoration-line: underline;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}
.down-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
.white-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>
