<template>
  <div style="background-color: white; min-height: 100vh">
    <Header />
    <div class="page-wrap">
      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>
      <h1>Waiver and Release</h1>
      <div
        class="border"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 25px;
        "
      >
        <h6 style="padding: 28px 12px">
          Please select who will be participating
        </h6>
        <div
          style="display: flex; margin-bottom: 30p; align-items: flex-start"
          class="buttonFlex"
        >
          <div style="display: flex; align-items: center">
            <button class="numberButton">
              <v-icon
                style="color: #ffffff; padding: 0px 12px"
                @click="removeAdult()"
                >mdi-minus</v-icon
              >
              {{ adultNumber }}
              <v-icon
                style="color: #ffffff; padding: 0px 12px"
                @click="addAdult()"
                >mdi-plus</v-icon
              >
            </button>
            <div style="display: flex; align-items: center; padding-left: 8px">
              <img src="../../../assets/images/pedestrian.svg" alt="" />
              <p>Adults</p>
            </div>
          </div>

          <div style="display: flex; align-items: center" class="leftpadding">
            <button class="numberButton">
              <v-icon
                style="color: #ffffff; padding: 0px 12px"
                @click="removeMinor()"
                >mdi-minus</v-icon
              >
              {{ minorNumber }}
              <v-icon
                style="color: #ffffff; padding: 0px 12px"
                @click="addMinor()"
                >mdi-plus</v-icon
              >
            </button>
            <div style="display: flex; align-items: center; padding-left: 8px">
              <img src="../../../assets/images/child.svg" alt="" />
              <p>Minors</p>
            </div>
          </div>
        </div>
      </div>

      <div class="border waiver" v-if="adultNumber > 0">
        <h6 style="padding: 28px 20px">
          This is Waiver and Release for participation in a Utah Division of
          State Parks and Recreation sponsored event.
        </h6>
        <div style="padding: 0px 30px 30px 30px">
          <h6 style="padding: 0px; text-align: left">
            Description of event or program:
          </h6>
          <p style="padding-top: 5px">
            Injury may result from your participation in this event.
            Additionally, property damage or loss may occur. You are expected to
            familiarize yourself with the rules of conduct for the event/program
            as well as Utah Division of State Parks policies. You are expected
            to follow proper operating procedures including safety procedures,
            plus any directions given by an authorized park or event/program
            coordinator employee. <v-spacer></v-spacer>

            The undersigned, being at least eighteen years of age, and in
            consideration of participation in a State Parks event/program as
            described above (herein after event/program), does hereby agree to
            this waiver and release.<v-spacer></v-spacer>

            I do hereby agree to assume all risks which may be associated with
            or may result from, my participation in this event/program,
            including but not limited to the actual course of activities or
            while using the facility, parking lots, transportation or access to
            State Parks facilities and recreation sites.<v-spacer></v-spacer>

            I recognize that participation in the event/program may involve
            moderate to strenuous physical activity and may cause physical and
            or emotional distress to participants. There is also inherent risk
            involved in fishing and travel in and around water. Weather
            conditions may also be very hazardous at times. There may also be
            associated health risks. I state that I am free from any known
            heart, respiratory or other health problems that could prevent me
            from safely participating in any of the activities.<v-spacer
            ></v-spacer>

            I certify that I have medical insurance or otherwise agree to be
            personally responsible for costs of any emergency or other medical
            care that I receive. I agree to release the State of Utah, Utah
            Division of State Parks and recreation, water operators and owners,
            and its agencies, departments, officers, employees, agents and all
            sponsors, officials and staff or volunteers from the cost of any
            medical care that I receive as a result of participation in the
            event/program.<v-spacer></v-spacer>

            I further agree to release the State of Utah, Utah Division of State
            Parks, water operators and owners, and its agencies, departments,
            officers, employees, agents and all sponsors, officials and staff or
            volunteers from any and all liability, claims, demands, breach of
            warranty, negligence, actions, and causes of actions whatsoever for
            any loss, claim, damage, injury, illness, attorneys fees or harm of
            any kind or nature to me arising out of my participation in the
            event/program. This release extends to any claim made by my family,
            estate, heirs, or assigns arising from or in any way connected with
            the aforementioned activities.
          </p>
          <h6 style="text-align: left">Consent</h6>
          <p>
            Consent is expressly given, in the event of injury, for any
            emergency aid, anesthesia and / or operation, if in the opinion of
            the attending physician, such treatment is necessary. I have
            carefully read and understand the contents of the foregoing language
            and I specifically intend it to cover my participation in the above
            stated event/program.
          </p>
        </div>
      </div>
      <v-app style="margin-top: 7px">
        <div
          v-for="(adult, index) in adultArray"
          v-bind:key="index"
          class="border waiver"
          style="padding: 0px 30px 30px 30px"
        >
          <h6 style="text-align: left">Adult #{{ index + 1 }} Name</h6>
          <div class="inputFlex">
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[index].fnameError }"
              v-model="AdultData[index].firstname"
              label="First Name"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[index].fnameError"
              :error-messages="AdultData[index].fnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[index].lnameError }"
              v-model="AdultData[index].lastname"
              label="Last Name"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[index].lnameError"
              :error-messages="AdultData[index].lnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': AdultData[index].emailError }"
              v-model="AdultData[index].email"
              label="Email"
              v-on:change="checkCont()"
              outlined
              :error="AdultData[index].emailError"
              :error-messages="AdultData[index].emailMessage"
              error-color="#96a145"
            ></v-text-field>
          </div>
          <div class="error-text" v-show="AdultData[index].AagError == true">
            {{ AdultData[index].AagMessage }}
          </div>
          <v-checkbox
            class="waiver-checkbox"
            v-model="AdultData[index].agreed"
            v-on:change="checkCont()"
            color="#0096FF"
            label="I have carefully read and understand the contents of the foregoing language and I specifically intend it to cover my participation in the above stated event/program."
          ></v-checkbox>
        </div>
        <div class="border waiver" v-show="minorNumber > 0">
          <div style="padding: 0px 30px 30px 30px">
            <h6 style="text-align: left">
              This is an Informed Consent Form for Minors, which identifies
              risks of participating in the Palisade State Park Annual Fishing
              Tournament at Palisade State Park, and a Waiver and Release for
              parents/guardians.
            </h6>
            <p>
              Injury may result from your participation in the Palisade State
              Park Annual Fishing Tournament. You are expected to familiarize
              yourself with the Palisade State Park Annual Fishing Tournament
              and what is required, rules of conduct for the Palisade State Park
              Annual Fishing Tournament as well as any policies. You are
              expected to follow proper operating procedures including safety
              procedures as outlined by the course/program instructor, plus any
              directions given by an authorized employee.
            </p>
          </div>
        </div>
        <div
          v-for="(adult, Minorindex) in minorArray"
          v-bind:key="Minorindex"
          class="border waiver"
          style="padding: 0px 30px 30px 30px"
        >
          <h6 style="text-align: left">Minor #{{ Minorindex + 1 }} Name</h6>
          <div class="inputFlex">
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': MinorData[Minorindex].fnameError }"
              v-model="MinorData[Minorindex].firstname"
              label="First Name"
              v-on:change="checkCont()"
              outlined
              :error="MinorData[Minorindex].fnameError"
              :error-messages="MinorData[Minorindex].fnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': MinorData[Minorindex].lnameError }"
              v-model="MinorData[Minorindex].lastname"
              label="Last Name"
              v-on:change="checkCont()"
              outlined
              :error="MinorData[Minorindex].lnameError"
              :error-messages="MinorData[Minorindex].lnameMessage"
              error-color="#96a145"
            ></v-text-field>
          </div>
          <div
            class="error-text"
            v-show="MinorData[Minorindex].MagError == true"
          >
            {{ MinorData[Minorindex].MagMessage }}
          </div>
          <v-checkbox
            class="waiver-checkbox"
            v-model="MinorData[Minorindex].agreed"
            v-on:change="checkCont()"
            color="#0096FF"
            label="I have carefully read and understand the contents of the foregoing language and I specifically intend it to cover my participation in the above stated event/program."
          ></v-checkbox>
        </div>
        <div
          v-show="minorNumber > 0"
          class="border waiver"
          style="padding: 0px 30px 30px 30px"
        >
          <h6 style="text-align: left">Legal Guardian Name</h6>
          <div class="inputFlex">
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': LegalGuardian.fnameError }"
              v-model="LegalGuardian.firstname"
              label="First Name"
              v-on:change="checkCont()"
              outlined
              :error="LegalGuardian.fnameError"
              :error-messages="LegalGuardian.fnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': LegalGuardian.lnameError }"
              v-model="LegalGuardian.lastname"
              label="Last Name"
              v-on:change="checkCont()"
              outlined
              :error="LegalGuardian.lnameError"
              :error-messages="LegalGuardian.lnameMessage"
              error-color="#96a145"
            ></v-text-field>
            <v-text-field
              color="#96a145"
              class="wavierInput"
              :class="{ 'error-outline': LegalGuardian.emailError }"
              v-model="LegalGuardian.email"
              label="Email"
              v-on:change="checkCont()"
              outlined
              :error="LegalGuardian.emailError"
              :error-messages="LegalGuardian.emailMessage"
            ></v-text-field>
          </div>
          <div class="error-text" v-show="LegalGuardian.LagError == true">
            {{ LegalGuardian.LagMessage }}
          </div>
          <v-checkbox
            class="waiver-checkbox"
            style="align-items: flex-start; padding-right: 8px"
            v-model="LegalGuardian.agreed"
            color="#0096FF"
            v-on:change="checkCont()"
            label="The undersigned, the legal guardian of the above minor (hereinafter “minor”) a minor under eighteen years of age, in consideration of the minor’s participation in the Palisade State Park Annual Fishing Tournament do hereby agree to this waiver and release. 
            I recognize that participation in the Palisade State Park Annual Fishing Tournament may involve moderate to strenuous physical activity and may cause physical and or emotional distress to participants. There is also inherent risk involved in ice fishing and travel in or around ice. Weather conditions may also be very hazardous at times. There may also be associated health risks. I state that student is free from any known heart, respiratory or other health problems that could prevent minor from safely participating in any of the activities.  
            I certify that I have medical insurance or otherwise agree to be personally responsible for costs of any emergency or other medical care that minor receives. I agree to release State of Utah, water operators and owners associated with this park, and their agencies, departments, officers, employees, agents, and all sponsors, officials and staff or volunteers from the cost of any medical care that minor receives as a result of participation in the Palisade State Park Annual Fishing Tournament.
            I further agree to release the State of Utah, water operators and owners associated with this park, their agencies, departments, officers, employees, agents and all sponsors, officials and staff or volunteers from any and all liability, claims, demands, breach of warranty, negligence, actions, and causes of actions whatsoever for any loss, claim, damage, injury, illness, attorney’s fees or harm of any kind or nature to me arising out of minor’s participation in the Palisade State Park  Annual Fishing Tournament. This release extends to any claim made by parents or guardians or their assigns arising from or in any way connected with the aforementioned activities.  
            CONSENT 
            Consent is expressly given, in the event of injury, for any emergency aid, anesthesia and / or operation, if in the opinion of the attending physician, such treatment is necessary.  
            I have carefully read and understand the contents of the foregoing language and I specifically intend it to cover minor’s participation in the above stated activities at the Palisade State Park Annual Fishing Tournament."
          ></v-checkbox>
        </div>
      </v-app>

      <v-card id="errorCard" v-show="errorCard == true">
        <v-card-title class="headline" style="color: #5a1213">
          All signatures and checkboxes are required.
        </v-card-title>
        <v-card-actions>
          <v-icon @click="errorCard = false">mdi-close</v-icon>
        </v-card-actions>
      </v-card>

      <button class="contin" @click="cont()" v-show="continueButton == true">
        Continue
      </button>
      <button
        class="contin grey-button"
        @click="errorAlert()"
        v-show="
          continueButton == false && (adultNumber != 0 || minorNumber != 0)
        "
      >
        Continue
      </button>

      <!-- <button class="contin grey" @click="error()" v-bind="class">Continue</button> -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Header from "../../../components/Header.vue";
// import $ from "jquery";
export default {
  name: "palisadeWaiver",
  metaInfo: {
    title: "Palisade Waiver",
    meta: [
      {
        name: "description",
        content: "Purchase your day use pass for Palisade Fishing Tournament.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      ticketType: [
        { text: "Adult", value: "Adult" },
        { text: "Minor", value: "Minor" },
      ],
      selectedTicket: "",
      adultNumber: 0,
      minorNumber: 0,
      AdultData: [],
      MinorData: [],
      LegalGuardian: {
        firstname: "",
        lastname: "",
        email: "",
        agreed: false,
        fnameError: false,
        fnameMessage: "",
        lnameError: false,
        lnameMessage: "",
        emailError: false,
        emailMessage: "",
        LagError: false,
        LagMessage: "",
      },
      ready: false,
      continueButton: false,
      errorCard: false,
      redErrors: true,
      errorMessage: [],
      adultErrorMessage: [],
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Palisade State Park",
        disabled: false,
        href: "/parks/" + this.$route.params.name + "/dayuse",
      },
      {
        text: "Annual Fishing Tournament",
        disabled: true,
        href: "/parks/:name/palisadefishingreg",
      },
    ];
  },
  mounted() {},
  methods: {
    addAdult() {
      this.adultNumber += 1;
      this.AdultData.push({
        event: "7665603805337",
        firstname: "",
        lastname: "",
        email: "",
        minor: false,
        agreed: false,
        guardianFirstName: null,
        guardianLastName: null,
        guardianAgreed: null,
        fnameError: false,
        fnameMessage: "",
        lnameError: false,
        lnameMessage: "",
        emailError: false,
        emailMessage: "",
        AagError: false,
        AagMessage: "",
      });
    },
    removeAdult() {
      if (this.adultNumber > 0) {
        this.adultNumber -= 1;
        this.AdultData.pop();
      }
    },
    addMinor() {
      console.log("adding minor");
      this.minorNumber += 1;
      this.MinorData.push({
        event: "7665603805337",
        firstname: "",
        lastname: "",
        email: "",
        minor: true,
        agreed: false,
        guardianFirstName: "",
        guardianLastName: "",
        guardianAgreed: false,
        fnameError: false,
        fnameMessage: "",
        lnameError: false,
        lnameMessage: "",
        emailError: false,
        emailMessage: "",
        MagError: false,
        MagMessage: "",
      });
    },
    removeMinor() {
      if (this.minorNumber > 0) {
        this.minorNumber -= 1;
        this.MinorData.pop();
      }
    },
    showData() {
      console.log(this.AdultData);
    },
    checkCont() {
      console.log("here in the check funtions");
      // check to make sure that all the fields are filled out and the checkboxes are checked, and if so return true
      // if not return false
      var minorReady = 0;
      var adultReady = 0;
      for (let i in this.MinorData) {
        console.log("in minior loop");
        if (
          this.MinorData[i].agreed == true &&
          this.LegalGuardian.agreed == true &&
          this.LegalGuardian.firstname != "" &&
          this.LegalGuardian.lastname != "" &&
          this.MinorData[i].firstname != "" &&
          this.MinorData[i].lastname != "" &&
          this.LegalGuardian.email != ""
        ) {
          minorReady += 1;
          console.log("minor ready: ", minorReady);
        }
      }
      for (let y in this.AdultData) {
        if (
          this.AdultData[y].agreed == true &&
          this.AdultData[y].firstname != "" &&
          this.AdultData[y].lastname != "" &&
          this.AdultData[y].email != ""
        ) {
          adultReady += 1;
        }
      }
      if (minorReady == this.minorNumber && adultReady == this.adultNumber) {
        this.continueButton = true;
        return true;
      } else {
        this.continueButton = false;
        return false;
      }
    },
    removeExtras() {
      console.log("removing all the error things");
      for (var i in this.AdultData) {
        delete this.AdultData[i].fnameError;
        delete this.AdultData[i].fnameMessage;
        delete this.AdultData[i].lnameError;
        delete this.AdultData[i].lnameMessage;
        delete this.AdultData[i].emailError;
        delete this.AdultData[i].emailMessage;
        delete this.AdultData[i].AagError;
        delete this.AdultData[i].AagMessage;
      }
      for (var y in this.MinorData) {
        delete this.MinorData[y].fnameError;
        delete this.MinorData[y].fnameMessage;
        delete this.MinorData[y].lnameError;
        delete this.MinorData[y].lnameMessage;
        delete this.MinorData[y].MagError;
        delete this.MinorData[y].MagMessage;
      }
      delete this.LegalGuardian.fnameError;
      delete this.LegalGuardian.fnameMessage;
      delete this.LegalGuardian.lnameError;
      delete this.LegalGuardian.lnameMessage;
      delete this.LegalGuardian.emailError;
      delete this.LegalGuardian.emailMessage;
      delete this.LegalGuardian.LagError;
      delete this.LegalGuardian.LagMessage;
    },
    cont() {
      this.removeExtras();
      var minorReady = 0;
      var adultReady = 0;

      for (let i in this.MinorData) {
        this.MinorData[i].guardianFirstName = this.LegalGuardian.firstname;
        this.MinorData[i].guardianLastName = this.LegalGuardian.lastname;
        this.MinorData[i].guardianAgreed = this.LegalGuardian.agreed;
        this.MinorData[i].email = this.LegalGuardian.email;
      }
      console.log("this is adult stuff: ", this.AdultData);
      console.log("this is minor stuff: ", this.MinorData);

      for (let y in this.MinorData) {
        if (
          this.MinorData[y].agreed == true &&
          this.MinorData[y].guardianAgreed == true &&
          this.MinorData[y].guardianFirstName != "" &&
          this.MinorData[y].guardianLastName != "" &&
          this.MinorData[y].guardianEmail != "" &&
          this.MinorData[y].firstname != "" &&
          this.MinorData[y].lastname != ""
        ) {
          minorReady += 1;
        }
      }
      for (let x in this.AdultData) {
        if (
          this.AdultData[x].agreed == true &&
          this.AdultData[x].firstname != "" &&
          this.AdultData[x].lastname != "" &&
          this.AdultData[x].email != ""
        ) {
          adultReady += 1;
        }
      }

      if (minorReady == this.minorNumber && adultReady == this.adultNumber) {
        console.log("you can move onto the next page from adult");
        var _this = this;
        for (let f in this.AdultData) {
          var data = this.AdultData[f];
          console.log("data: ", data);
          data = JSON.stringify(data);
          console.log("data: ", data);
          $.ajax({
            url: "https://trailwaze.info/waivers/addWaiver.php",
            type: "POST",
            dataType: "json",
            data: data,
            success: function (json) {
              _this.json = json;
              console.log("new json: ", _this.json);
            },
            error: function (xhr, status, error) {
              console.log("Error: " + error);
            },
          });
        }
        for (let p in this.MinorData) {
          console.log("you can move on from the minor");
          data = this.MinorData[p];
          data = JSON.stringify(data);
          console.log("data: ", data);
          $.ajax({
            url: "https://trailwaze.info/waivers/addWaiver.php",
            type: "POST",
            dataType: "json",
            data: data,
            success: function (json) {
              _this.json = json;
              console.log("new json: ", _this.json);
            },
            error: function (xhr, status, error) {
              console.log("Error: " + error);
            },
          });
        }
        console.log("everything worked!!!!");
        this.$router.push("/parks/UTPALI/fishingcart");
      } else {
        alert("Please make sure all fields are filled out correctly.");
      }

      // send each adult data and minor data to jared

      // var _this = this;
      // $.getJSON(`https://trailwaze.info/waivers/addWaiver.php`, function (json) {
      //     _this.json = json;
      //     console.log("new json: ", _this.json);
      // });
    },
    errorAlert() {
      var goAhead = this.checkCont();
      if (goAhead == true) {
        console.log("PASSED!! going through the cont funciton");
        this.cont();
      } else {
        console.log("in error alert");
        //alert("Please make sure all fields are filled out correctly.");
        // var minorReady = 0;
        // var adultReady = 0;
        this.errorMessage = [];
        this.adultErrorMessage = [];
        var message = "";
        var errors = [];
        for (let i in this.MinorData) {
          errors = [];
          message = "";
          console.log("in minior error loop");
          if (this.MinorData[i].agreed == false) {
            message = `You must agree to the waiver`;
            errors.push(message);
            this.MinorData[i].MagError = true;
            this.MinorData[i].MagMessage = message;
          }
          if (this.LegalGuardian.agreed == false) {
            message = `You must agree to the waiver`;
            errors.push(message);
            this.LegalGuardian.LagError = true;
            this.LegalGuardian.LagMessage = message;
          }
          if (this.LegalGuardian.firstname == "") {
            message = `First name is required`;
            errors.push(message);
            this.LegalGuardian.fnameError = true;
            this.LegalGuardian.fnameMessage = message;
          }
          if (this.LegalGuardian.lastname == "") {
            message = `Last name is required`;
            errors.push(message);
            this.LegalGuardian.lnameError = true;
            this.LegalGuardian.lnameMessage = message;
          }
          if (this.MinorData[i].firstname == "") {
            message = `First name is required`;
            errors.push(message);
            this.MinorData[i].fnameError = true;
            this.MinorData[i].fnameMessage = message;
          }
          if (this.MinorData[i].lastname == "") {
            message = `Last name is required`;
            errors.push(message);
            this.MinorData[i].lnameError = true;
            this.MinorData[i].lnameMessage = message;
            console.log("last name error: ", this.MinorData[i].lnameMessage);
          }
          if (this.LegalGuardian.email == "") {
            message = `Email is required`;
            errors.push(message);
            this.LegalGuardian.emailError = true;
            this.LegalGuardian.emailMessage = message;
          }
          console.log("errors: ", errors);
          for (let x in errors) {
            this.errorMessage.push(errors[x]);
          }
        }
        for (let y in this.AdultData) {
          errors = [];
          message = "";
          if (this.AdultData[y].agreed == false) {
            message = `You must agree to the waiver`;
            errors.push(message);
            this.AdultData[y].AagError = true;
            this.AdultData[y].AagMessage = message;
          }
          if (this.AdultData[y].firstname == "") {
            message = `First name is required`;
            errors.push(message);
            this.AdultData[y].fnameError = true;
            this.AdultData[y].fnameMessage = message;
          }
          if (this.AdultData[y].lastname == "") {
            message = `Last name is required`;
            errors.push(message);
            this.AdultData[y].lnameError = true;
            this.AdultData[y].lnameMessage = message;
          }
          if (this.AdultData[y].email == "") {
            message = `Email is required`;
            errors.push(message);
            this.AdultData[y].emailError = true;
            this.AdultData[y].emailMessage = message;
          }
          console.log("errors: ", errors);
          for (let z in errors) {
            this.adultErrorMessage.push(errors[z]);
          }
          console.log("adult errors: ", this.adultErrorMessage);
          this.errorCard = true;
        }
      }
    },
  },
  computed: {
    adultArray() {
      return Array.from({ length: this.adultNumber }, (_, i) => i);
    },
    minorArray() {
      return Array.from({ length: this.minorNumber }, (_, i) => i);
    },
  },
};
</script>
<style>
.error-outline::v-deep .v-input__control .v-input__slot {
  border-color: #b3261e !important;
}
.waiver-checkbox .v-input__slot {
  align-items: flex-start;
}
.v-text-field.v-text-field--enclosed {
  padding: unset !important;
  margin: 0px 20px 0px 0px !important;
}
.v-messages__message {
  color: #b3261e;
}
.error-text {
  color: #b3261e !important;
  font-size: 16px;
  padding-top: 20px;
}
.v-application .error--text {
  color: #b3261e !important;
}
</style>

<style scoped>
#errorCard {
  margin-top: 20px;
  background: #ec8183;
  border: 1px solid #5a1213;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.buttonFlex {
  flex-direction: row;
  align-items: center;
}
.leftpadding {
  padding-left: 70px;
}
.grey {
  background: #b1b1b1 !important;
  border: 1px solid #b1b1b1 !important;
}
.errorButton {
  background-color: #96a145 !important;
  color: #ffffff !important;
}
.contin {
  background: #96a145;
  border: 1px solid #96a145;
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding: 14px 16px;
  position: relative;
  left: 92%;
  margin-top: 20px;
}
.grey-button {
  background: #b1b1b1;
  border: 1px solid #b1b1b1;
}
.v-input__slot {
  align-items: flex-start;
  padding-right: 8px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  padding-left: 8px;
}

.wavierInput {
  width: 264px;
  margin-right: 20px;
}
.inputFlex {
  display: flex;
}
p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494e53;
}
.spacer {
  margin: 15px;
}
.waiver {
  margin-top: 25px;
}
.numberButton {
  background-color: #96a145;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 10px;
}
.border {
  background: #ffffff;
  box-shadow: 0.5px 0.5px 6px 0.5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #494e53;
  text-align: center;
  padding: 28px 0px;
}
h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  margin: 50px 0px;
  color: #494e53;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
p {
  line-height: 22px;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 34px;
  max-width: 50%;
}
.price {
  text-align: center;
  display: block;
  margin: 3px;
}
.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}
.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}
a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 15px;
}
@media only screen and (max-width: 850px) {
  .contin {
    left: 85%;
  }
  .passTitle {
    margin-top: 20px;
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 350px;
  }
}
@media only screen and (max-width: 425px) {
  .buttonFlex {
    flex-direction: column;
    align-items: center;
  }
  .leftpadding {
    padding-left: 0px;
    margin-top: 15px;
  }
  .contin {
    left: 73%;
  }
  .inputFlex {
    flex-direction: column;
  }
  .wavierInput {
    width: 100%;
    margin-bottom: 15px;
  }
  h1 {
    text-align: center;
    font-size: 36px;
  }
}
@media only screen and (max-width: 320px) {
  .contin {
    left: 62%;
  }
  h1 {
    font-size: 26px;
    margin: 30px 0px;
    line-height: 22px;
  }
}
</style>
