var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","min-height":"100vh"}},[_c('Header'),_vm._m(0),_vm._m(1),_c('div',{staticClass:"page-wrap"},[_vm._m(2),_c('v-divider',{staticClass:"divider"}),_c('p',{staticClass:"passTitle"},[_vm._v("Event Information")]),_c('div',{staticClass:"passContainer2"},[_c('div',{staticClass:"infoMargin"},[_c('div',{staticClass:"passContainer",staticStyle:{"margin-top":"16px"}},[_c('img',{staticStyle:{"margin":"0px"},attrs:{"src":require("../../assets/images/dollar.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('p',[_vm._v(" The event is $3 per person + the $5 (per vehicle) to enter the park. ")]),_c('p',{staticClass:"green-text",on:{"click":function($event){return _vm.dayUse()}}},[_vm._v(" Click here to purchase a $5 vehicle pass. ")])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_vm._m(7)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","color":"white","font-family":"'mediumRoboto'"}},[_c('img',{staticClass:"eventImg",attrs:{"src":require("../../assets/images/silver-sands-beach.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bubble-cont"},[_c('div',{staticClass:"grey-bubble"},[_c('p',{staticClass:"eventSmall"},[_vm._v("January 1st 2024 at 8:30 am")]),_c('h3',{staticClass:"eventLarge"},[_vm._v("First Day Hike - Bird Watching")]),_c('p',{staticClass:"eventSub"},[_vm._v("$3/person")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopifyCollectionWrap",attrs:{"id":"shopifyCollectionWrap"}},[_c('div',{staticClass:"collectionDiv",attrs:{"id":"collection-component-1616176464282"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/fire.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Overview")]),_c('p',[_vm._v(" Every January 1, hundreds of thousands of people across the country start off the new-year with a “First Day Hike” in their local state park. Join a park ranger for a morning stroll along Silver Sands Beach as we talk about birding around the Great Salt Lake. We hope to see a few birds while we talk. Total walking distance will be about 1.5 miles on beach conditions. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/location.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Location")]),_c('p',[_vm._v(" Check in at the Entrance Station upon arrival. Meet at the Great Salt Lake State Park Visitors Center back patio at 8:30 am to begin the walk. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/time.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Time")]),_c('p',[_vm._v("The walk will start at 8:30 am and possibly up to 2 hours.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/personCheck.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Tickets Available")]),_c('p',[_vm._v(" Everyone must pre-register, we will only have 50 people max capacity for this event. If you are not pre-registered we can not ensure there will be room. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoMargin2"},[_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/lantern.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("What to bring")]),_c('p',[_vm._v(" We recommend you dress for the weather (including waterproof shoes) and bring a pair of binoculars to birdwatch. All levels of birders are welcome. Please do not bring dogs for this walk. We will be walking on the beach so wet conditions are possible. Waterproof shoes/boots are recommended. Dress for the weather. This walk will mostly be on uneven terrain, with typical beach hazards. ")])])]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/pets.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Dogs")]),_c('p',[_vm._v(" Please do not bring dogs for this walk since we will be looking for birds. ")])])]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/Disclaimer.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Disclaimer")]),_c('p',[_vm._v(" * This Event is at Great Salt Lake State Park (off of Exit 104 of I-80), not at Antelope Island State Park ")])])]),_c('div',{staticClass:"passContainer"},[_c('img',{attrs:{"src":require("../../assets/images/phone.svg")}}),_c('div',{staticClass:"iconMargin"},[_c('div',{staticClass:"eventTitle"},[_vm._v("Event Contact Info")]),_c('p',[_vm._v(" For more information about this event, contact the Great Salt Lake State Park visitor center at 801-828-0787 ")])])])])
}]

export { render, staticRenderFns }