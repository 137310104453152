<template>
  <div style="background-color: white; min-height: 100vh">
    <Header></Header>
    <!-- <div class="paintbrush-hero">
      <div class="hero-text">
        <div>2023</div>
        <div>Paint Night at the Park</div>
        <div>Information and Tickets</div>
      </div>
    </div> -->
    <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <!-- <img src="../../../assets/images/paintbrush-set.png" class="eventImg" /> -->
      <div class="paintbrush-hero">
        <!-- <div class="hero-text">
        <div>2023</div>
        <div>Paint Night at the Park</div>
        <div>Information and Tickets</div>
      </div> -->
      </div>
      <div>
        <div class="bottom-left">
          <p class="eventSmallSlab whiteText">2023</p>
          <h3 class="eventLargeSlab whiteText">Paint Night at the Park</h3>
          <p class="eventSub whiteText">Information and Tickets</p>
        </div>
      </div>
    </div>
    <div class="page-wrap">
      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>
      <div class="topCon">
        <div style="display: flex; align-items: flex-start">
          <v-icon id="backArrow" v-on:click="goBack()">mdi-arrow-left</v-icon>
          <div>
            <h4 class="date-head">{{ displayMonth }} {{ displayDay }}</h4>
            <p id="date-sub">
              The event is $10 per person + the $5 (per vehicle) to enter the
              park.
              <a class="greenLink" @click="goToDayUse">
                Click here to purchase a $5 vehicle pass.
              </a>
            </p>
          </div>
        </div>
      </div>

      <div id="shopifyCollectionWrap" class="shopifyCollectionWrap">
        <div
          id="collection-component-1616176464282"
          class="collectionDiv"
        ></div>
      </div>
      <v-divider class="divider" />

      <p class="passTitle">Event Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img src="../../../assets/images/flame.svg" alt="flame icon" />
            <div class="iconMargin">
              <div class="eventTitle">Overview</div>
              <p>
                This is a unique opportunity to Paint with a Ranger at Great
                Salt Lake State Park and Marina. This will be focused mostly on
                beginner skills but people of all skill levels are welcome to
                join. Paint what inspires you! We will be providing the
                watercolor paper, paintbrushes, and watercolor paint palettes.
                We will be set up by the Pavilion/ Lawn area. This event is $10
                per person + the $5 (per vehicle) entry fee.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <div style="display: flex">
              <img
                src="../../../assets/images/pawprint.svg"
                style="margin-right: 14px"
                alt="pawprint icon"
              />
              <p>Dogs are welcome but must be on leash.</p>
            </div>
            <div class="iconMargin"></div>
          </div>
          <div class="passContainer">
            <img
              src="../../../assets/images/checkmark-person.svg"
              alt="person with checkmark in bottom right corner icon"
            />
            <div class="iconMargin">
              <div class="eventTitle">Tickets Available</div>
              <p>
                Everyone must pre-register, we will only have 20 people max
                capacity for each event. If you are not pre-registered we can
                not ensure there will be room for the event. Tickets cost $10
                per person
              </p>
            </div>
          </div>
        </div>

        <div class="infoMargin2">
          <div class="passContainer">
            <img
              src="../../../assets/images/location-gsl.svg"
              alt="location icon"
            />
            <div class="iconMargin">
              <div class="eventTitle">Location</div>
              <p>
                Meet at the Group Pavilion / Lawn area of the Great Salt Lake
                State Park (Exit 104 off of I-80). This event is not on Antelope
                Island
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img
              src="../../../assets/images/lantern-gsl.svg"
              alt="lantern icon"
            />
            <div class="iconMargin">
              <div class="eventTitle">What to bring</div>
              <p>
                Bring your own snacks or drinks if you'd like. If you have your
                own supplies you would like to use you are welcome to bring
                them. We will be providing watercolor paper sheets,
                paintbrushes, basic watercolor paint palette, and pencils for
                those registered.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../../assets/images/error.svg" alt="error icon" />
            <div class="iconMargin">
              <div class="eventTitle">Disclaimer</div>
              <p>*This event may be canceled if the weather is stormy</p>
              <p>
                **This is event is held at the Great Salt Lake Marina in Magna,
                and NOT on Antelope Island.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../../assets/images/call.svg" alt="phone icon" />
            <div class="iconMargin">
              <div class="eventTitle">Event Contact Info</div>
              <p>
                For more information about this event, contact the Great Salt
                Lake State Park Visitor Center at 801-828-0787
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../../components/Header.vue";
import $ from "jquery";
export default {
  name: "Paint Night at the Park",
  metaInfo: {
    title: "Paint Night at the Park",
    meta: [
      {
        name: "description",
        content:
          "Purchase your day use pass for Paint Night at the Park Event.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      collectionId: "",
      month: this.$route.params.month,
      day: this.$route.params.day,
      type: this.$route.params.type,
      displayMonth: "",
      displayDay: "",
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "Great Salt Lake State Park",
        disabled: false,
        href: "/parks/" + this.$route.params.name + "/dayuse",
      },
      {
        text: "Paint Night at the Park",
        disabled: false,
        href: "/parks/UTGREA/paintnight",
      },
      {
        text:
          this.$route.params.type +
          " " +
          this.$route.params.month +
          " " +
          this.$route.params.day,
        disabled: true,
        href:
          "/parks/UTGREA/paintnight/" +
          this.$route.params.type +
          "/" +
          this.$route.params.month +
          "/" +
          this.$route.params.day,
      },
    ];
  },
  mounted() {
    document.getElementById("header").scrollIntoView();
    console.log("day", this.day);
    console.log(this.month);
    console.log(this.type);

    if (this.month == "April" && this.day == "22" && this.type == "Child") {
      this.displayMonth = "April";
      this.displayDay = "22, 3:00 pm";
      this.collectionId = "418200060126";
    } else if (
      this.month == "April" &&
      this.day == "22" &&
      this.type == "Adult"
    ) {
      this.displayMonth = "April";
      this.displayDay = "22, 5:00 pm";
      this.collectionId = "418200158430";
    } else if (
      this.month == "April" &&
      this.day == "23" &&
      this.type == "Child"
    ) {
      this.displayMonth = "April";
      this.displayDay = "23, 3:00 pm";
      this.collectionId = "418200322270";
    } else if (
      this.month == "April" &&
      this.day == "23" &&
      this.type == "Adult"
    ) {
      this.displayMonth = "April";
      this.displayDay = "23, 5:00 pm";
      this.collectionId = "418200289502";
    } else {
      console.log("error in date");
    }
    console.log(this.collectionId);
    this.loadShopifyCollection();
  },
  methods: {
    loadShopifyCollection: function () {
      if (window.Evey) {
        if (window.Evey.buy_button) {
          $(document).ready(() => {
            this.EveyShopifyBuyInit();
          });
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    goBack() {
      this.$router.push("/parks/UTWILL/paintnight");
    },

    goToDayUse() {
      this.$router.push("/parks/UTGREA/dayuse");
    },

    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.EveyShopifyBuyInit;
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
    EveyShopifyBuyInit: function () {
      // this.stopLoader();
      Evey.buy_button.buildShopifyBuyButton({
        shop: "parkstoreutah.myshopify.com",
        collectionId: this.collectionId,
        elementId: "collection-component-1616176464282",
        storefrontAccessToken: "1f4659f29b2004e36d6e8ebd35640005",
        collectLicensePlate: false,
        licensePlateRequired: false,
        options: {
          product: {
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "300px",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                  width: "calc(25% - 20px)",
                },
                img: {
                  height: "calc(100% - 15px)",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                },
                imgWrapper: {
                  "padding-top": "calc(75% + 15px)",
                  position: "relative",
                  height: "0",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          productSet: {
            styles: {
              products: {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px",
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px",
                },
              },
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            text: {
              button: "Add to cart",
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                ":hover": {
                  "background-color": "#87913e",
                },
                "background-color": "#96a145",
                ":focus": {
                  "background-color": "#87913e",
                },
                "border-radius": "40px",
              },
            },
            text: {
              total: "Subtotal",
              button: "Checkout",
            },
          },
          toggle: {
            styles: {
              toggle: {
                "background-color": "#96a145",
                ":hover": {
                  "background-color": "#87913e",
                },
                ":focus": {
                  "background-color": "#87913e",
                },
              },
            },
          },
        },
        calendarCss: `
                      .evey-calendar-container td.selected {
                        background-color: #96A145 !important;
                      }
                      .evey-calendar-container td.selected .markers span {
                        color: #96A145 !important;
                      }
                      .evey-calendar-container td.current-day {
                        background-color: #FFFFFF !important;
                      }
                      .evey-calendar-container td.current-day .cell-date {
                        color: #9aa656 !important;
                      }
                      .evey-calendar-container .available-events .selected {
                        background-color: #96A145 !important;
                      }
                      .evey-calendar-container .modal-footer button {
                        color: #96A145;
                      }
                      .evey-calendar-container:hover .modal-footer button {
                        color: #838c41;
                        cursor: pointer;
                      }
                    `,
        triggerCss: `
                      button[data-evey-trigger="scheduler"] {
                        border: 1px solid #c2c2c2;
                        padding: 10px 30px 10px 7px;
                        width:100%;
                        text-align:left;
                        font-size:inherit;
                        display:flex;
                        align-items:center;
                        position: relative;
                      }
                      button[data-evey-trigger="scheduler"]:after {
                        content: url("data:image/svg+xml; utf8, %3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M17.5%202H15V1a1%201%200%2010-2%200v1H6V1a1%201%200%2000-2%200v1p.5C1.7%202%201%202.7%201%203.5v15c0%20.8.7%201.5%201.5%201.5h15c.8%200%201.5-.7%201.5-1.5v-15c0-.8-.7-1.5-1.5-1.5zM3%2018h14V8H3v10z%22%20fill%3D%22%23849235%22%2F%3E%3C%2Fsvg%3E");
                        display:inline-block;
                        position:absolute;
                        right:10px;
                        padding-left: 16px;
                        width:20px;
                        height:20px;
                      }
                    `,
      });
    },
  },
};
</script>

<style scoped>
.paintbrush-hero {
  background-image: url("../../../assets/images/paintbrush-set.png");
  width: 100%;
  height: 467px;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.eventSub {
  font-size: 36px;
  margin-top: 15px;
}

.hero-text {
  color: white;
}
.link {
  font-size: 16px;
  font-family: "Roboto";
  color: #96a145;
  cursor: pointer;
}
#backArrow {
  padding-right: 30px;
}
#date-sub {
  font-family: "Roboto";
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}
.date-head {
  font-family: "boldRoboto";
  font-size: 36px;
  line-height: 24px;
  padding-bottom: 20px;
}
#nomargin {
  margin-top: 0px;
}
.topCon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
}
.greenCard {
  background-color: #dde0c1 !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  flex-direction: row;
  display: none;
}

#glass-head {
  font-family: "boldRoboto";
  font-size: 20px;
  color: #000000;
}
#glass-sub {
  font-family: "Roboto";
  font-size: 16px;
  color: #000000;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.v-breadcrumbs {
  margin: 0px 12px 18px 12px;
}
p {
  line-height: 22px;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 75px;
  max-width: 60%;
}

a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 16px;
}
.noMarginimg {
  margin-top: 22px !important;
}
@media only screen and (max-width: 850px) {
  .passTitle {
    margin-top: 20px;
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }
  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 450px;
  }
  .date-sub {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .paintbrush-hero {
    height: 190px;
  }

  .eventSmallSlab {
    font-size: 16px;
    line-height: 18px;
  }
  .eventLargeSlab {
    font-size: 24px;
    line-height: 18px;
  }

  .eventSub {
    font-size: 16px;
    margin-top: 0px;
    line-height: 18px;
  }
  .topCon {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: stretch;
    padding-bottom: 0px;
  }

  .greenCard {
    display: flex;
    margin-bottom: 50px;
  }
  .passTitle {
    margin-top: 50px;
  }

  .date-sub {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 425px) {
  #backArrow {
    padding-right: 15px;
  }
  #date-sub {
    font-family: "Roboto";
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 0px;
  }
  .date-head {
    font-family: "boldRoboto";
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 375px) {
  #glass-head {
    font-size: 16px;
  }
  #glass-sub {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  #glassesBig {
    width: 18%;
  }
}
</style>
