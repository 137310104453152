<template>
  <v-app style="background-color: white; min-height: 100vh">
    <Header></Header>
    <div
      style="
        position: relative;
        color: white;
        font-family: 'mediumRoboto';
        background: #1e252b;
      "
    >
      <img src="../../assets/images/haunted.png" class="eventImg" />
      <img src="../../assets/images/ghost-mobile.png" id="mobileImg" alt="" />
      <div>
        <div class="bottom-left">
          <div id="text-cont">
            <p class="eventSmall whiteText">NOVEMBER 2ND</p>
            <h3 class="eventLarge whiteText">Haunted Campground</h3>
            <p class="eventSub whiteText">Information and Registration</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-wrap">
      <div class="topCon">
        <v-breadcrumbs
          divider="/"
          :items="breadcrumbs"
          class="breadcrumbs"
        ></v-breadcrumbs>
      </div>
      <p id="camping-sub">
        Purchase a single or double site for the night. Day-Use passes are
        included in the camping fee.
      </p>
      <div id="product-flex">
        <div id="product-container">
          <img src="../../assets/images/ghost.svg" alt="" id="ghostpic" />
          <p id="product-head">Haunted Campground</p>
          <p id="price">${{ price }}</p>
          <div id="sidebyside">
            <div id="left">
              <v-select
                v-model="selectedSite"
                :items="siteType"
                label="Site Type"
                dense
                outlined
                color="#96a145"
                @change="updatePrice()"
              ></v-select>
              <v-select
                v-model="selectedSiteNumber"
                :items="SiteNumbers"
                label="Site Number"
                dense
                outlined
                color="#96a145"
                @change="cont()"
              ></v-select>
            </div>
            <div id="right">
              <v-text-field
                v-model="name"
                label="First & Last Name"
                outlined
                class="event-input"
                dense
                color="#96a145"
                @change="cont()"
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                outlined
                dense
                class="event-input"
                color="#96a145"
                @change="cont()"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                label="Phone"
                outlined
                dense
                class="event-input"
                color="#96a145"
                @change="cont()"
              ></v-text-field>
            </div>
          </div>
          <div style="display: flex; justify-content: center">
            <button
              v-if="conts == true"
              id="buybutton"
              @click="checkSiteAvailability()"
            >
              Buy Now
            </button>
            <button v-else id="buybutton-grey">Buy Now</button>
          </div>
        </div>
      </div>

      <p class="passTitle">Event Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img src="../../assets/images/lantern-gsl.svg" alt="flame icon" />
            <div class="iconMargin">
              <div class="eventTitle">Overview</div>
              <p>
                Come join us for a haunted night at East Canyon State Park! Book
                a single or double campsite at a discounted price.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/lantern-gsl.svg" alt="flame icon" />
            <div class="iconMargin">
              <div class="eventTitle">Dark Skies Program</div>
              <p>
                U of U Physics Department wil be out for a dark skies program at
                6:30 p.m.
              </p>
            </div>
          </div>
        </div>

        <div class="infoMargin2">
          <div class="passContainer">
            <img src="../../assets/images/call.svg" alt="location icon" />
            <div class="iconMargin">
              <div class="eventTitle">Event Contact Info</div>
              <p>
                For more information about this event, contact East Canyon State
                Park at 801-829-6866
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/trophy.svg" alt="lantern icon" />
            <div class="iconMargin">
              <div class="eventTitle">Prixe</div>
              <p>A prize will be awarded to the best decorated campsite.</p>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <p class="passTitle" style="margin-bottom: 30px">Locations</p>
      <div>
        <img src="../../assets/images/Dixie-Creek.png" id="campImg" alt="" />
      </div>
      <v-divider></v-divider>
      <p class="passTitle" style="margin-bottom: 30px">
        Utah State Parks Rulebook
      </p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/theif.svg" alt="" />
              <p>
                Please report accidents or suspicious activities to a ranger.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/fire.svg" alt="" />
              <p>
                Campfires may be built in specified areas. Most developed
                campsites provide fire pits or grills. Collecting and gathering
                firewood within the park is prohibited. Firewood is available
                for purchase from park personnel.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/red-x.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/firearm.svg" alt="" />
              <p>
                The use of firearms, traps or other devices capable of launching
                a projectile that can immobilize, injure or kill a person or
                animal is prohibited.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/red-x.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/nofireworks.svg" alt="" />
              <p>
                Explosives, fireworks or firecrackers are prohibited unless
                authorized by the park manager.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/trash.svg" alt="" />
              <p>
                Please use waste containers. Please leave the park in better
                condition than you found it.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/parking.svg" alt="" />
              <p>Park only in designated areas.</p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/pets2.svg" alt="" />
              <p>
                Pets are allowed at most state parks, but should be on a maximum
                six-foot leash. Service dogs are the only animals admitted into
                park buildings. Please clean up after pets.
              </p>
            </div>
          </div>
        </div>
        <div class="infoMargin2">
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/permit.svg" alt="" />
              <p>
                A Special Use Permit is required for all special events and
                commercial or professional filming and photography.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/red-x.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/plant.svg" alt="" />
              <p>
                All plants, animals, minerals, and other natural features in
                state parks are protected. It is unlawful to remove, alter, or
                destroy them.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/odometer.svg" alt="" />
              <p>
                Speed limit on state park roads is 15 mph unless otherwise
                posted.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/green-check.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/trail.svg" alt="" />
              <p>Stay On Designated Trails</p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/red-x.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/vandalism.svg" alt="" />
              <p>
                Please help keep our parks beautiful. It is unlawful to mutilate
                or deface any natural or constructed feature.
              </p>
            </div>
          </div>
          <div class="passContainer rule-cont">
            <img src="../../assets/images/red-x.svg" alt="" />
            <div class="inner-rule-cont">
              <img src="../../assets/images/dump.svg" alt="" />
              <p>
                It is unlawful to dump or drain wastewater from campers or
                trailers onto the ground. Utility sinks and a sanitary disposal
                station are provided.
              </p>
            </div>
          </div>
          <div class="inner-rule-cont">
            <p>
              <span id="green-text" @click="goToPage()">Click Here</span> for a
              more detailed list of park rules
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script>

<script>
import Header from "../../components/Header.vue";
import $ from "jquery";
//import Calendar from 'v-calendar/lib/components/calendar.umd'

export default {
  name: "HauntedCamping",
  metaInfo: {
    title: "Haunted Camping",
    meta: [
      {
        name: "description",
        content: "Purchase your pass to the Haunted Camping Event",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      breadcrumbs: [],
      // collectionId: "397134823646",
      date: null,
      selectAttribute: {
        // dot: true,

        highlight: "red",
      },
      price: 25,
      selectedData: null,
      christmasDay: false,
      notAvailable: false,
      pricePerDay: true,
      btnDisabled: false,
      siteType: ["Single Site", "Double Site"],
      singleSites: [
        "2",
        "3",
        "4",
        "5",
        "8",
        "9",
        "12",
        "13",
        "14",
        "17",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "27",
        "28",
        "29",
        "30",
        "32",
      ],
      doubleSites: ["6", "7", "10", "11", "15", "16", "18", "26", "31", "33"],
      selectedSite: "",
      SiteNumbers: [],
      selectedSiteNumber: "",
      conts: false,
      name: "",
      email: "",
      phone: "",
      TakenSingles: [],
      TakenDoubles: [],
      AdultData: {},
    };
    currentDate = new Date();
  },
  created() {
    this.selectedSite = this.siteType[0];
    this.price = 25;
    this.SiteNumbers = this.singleSites;
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "East Canyon State Park",
        disabled: false,
        href: "/parks/" + this.$route.params.name + "/dayuse",
      },
      {
        text: "Haunted Camping",
        disabled: true,
        href: "/parks/UTEAST/camping/haunted",
      },
    ];
    this.uuid = this.generateUUID();
    this.WaiverData();
    this.grabAvailableDoubleSites();
    this.grabAvailableSingleSites();
  },
  mounted() {
    // this.loadShopifyCollection();
    document.getElementById("header").scrollIntoView();
    //console.log(this.price);
  },
  methods: {
    generateUUID() {
      // Public Domain/MIT
      let d = new Date().getTime(); //Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    WaiverData() {
      this.AdultData = {
        passId: this.uuid,
        event: "",
        firstname: "",
        lastname: "",
        email: "",
        minor: false,
        agreed: false,
        guardianFirstName: "",
        guardianLastName: "",
        guardianAgreed: null,
      };
      console.log(this.AdultData);
    },
    updatePrice() {
      if (this.selectedSite === "Single Site") {
        this.price = 25;
        this.SiteNumbers = this.singleSites;
      } else {
        this.price = 35;
        this.SiteNumbers = this.doubleSites;
      }
    },
    goToPage() {
      // redirect a new tab to this url: https://stateparks.utah.gov/resources/policies-and-park-rules/

      window.open(
        "https://stateparks.utah.gov/resources/policies-and-park-rules/",
        "_blank"
      );
    },
    cont() {
      if (
        this.selectedSiteNumber !== "" &&
        this.name !== "" &&
        this.email !== "" &&
        this.phone !== ""
      ) {
        this.conts = true;
      } else {
        this.conts = false;
      }
    },
    grabAvailableDoubleSites() {
      var settings = {
        url: "https://trailwaze.info/waivers/request.php?event=44871892336793",
        method: "GET",
        timeout: 0,
      };
      var _this = this;
      $.ajax(settings).done(function (response) {
        console.log(response.waivers[0]);
        var waivers = response.waivers[0];
        for (var i in waivers) {
          if (waivers[i].guardian_first_name != "") {
            if (waivers[i].order_number != "-1") {
              // this site number is taken
              // add waivers[i].minor to a the TakenSingles list
              _this.TakenDoubles.push(waivers[i].guardian_first_name);
              _this.updateDoubleSiteNumbers();
            }
          }
        }
      });
    },
    updateDoubleSiteNumbers() {
      // remove the taken sites from the singleSites array they won't always be the same indexs. They will be the same values tho
      for (var i in this.TakenDoubles) {
        var index = this.doubleSites.indexOf(this.TakenDoubles[i]);
        if (index > -1) {
          this.doubleSites.splice(index, 1);
        }
      }
    },
    grabAvailableSingleSites() {
      var settings = {
        url: "https://trailwaze.info/waivers/request.php?event=44871884046489",
        method: "GET",
        timeout: 0,
      };
      var _this = this;
      $.ajax(settings).done(function (response) {
        console.log(response.waivers[0]);
        var waivers = response.waivers[0];
        for (var i in waivers) {
          if (waivers[i].guardian_first_name != "") {
            if (waivers[i].order_number != "-1") {
              // this site number is taken
              // add waivers[i].minor to a the TakenSingles list
              _this.TakenSingles.push(waivers[i].guardian_first_name);
            }
          }
        }
        console.log("taken singles: ", _this.TakenSingles);
        _this.updateSingleSiteNumbers();
      });
    },
    updateSingleSiteNumbers() {
      // remove the taken sites from the singleSites array they won't always be the same indexs. They will be the same values tho
      for (var i in this.TakenSingles) {
        var index = this.singleSites.indexOf(this.TakenSingles[i]);
        if (index > -1) {
          this.singleSites.splice(index, 1);
        }
      }
    },
    prepWaiver() {
      this.AdultData.email = this.email;
      this.AdultData.firstname = this.name;
      if (this.selectedSite === "Single Site") {
        this.AdultData.event = "44871884046489";
      } else {
        this.AdultData.event = "44871892336793";
      }
      console.log("this is the event id: ", this.AdultData.event);
      this.AdultData.guardianFirstName = this.selectedSiteNumber;
      this.AdultData.guardianLastName = this.phone;
      console.log("adult data ready to send: ", this.AdultData);
      this.sendWaiver();
    },
    sendWaiver() {
      var data = this.AdultData;
      console.log("data: ", data);
      data = JSON.stringify(data);
      console.log("data: ", data);
      var _this = this;
      $.ajax({
        url: "https://trailwaze.info/waivers/addWaiver.php",
        type: "POST",
        dataType: "json",
        data: data,
        success: function (json) {
          _this.json = json;
          console.log("new json: ", _this.json);
          _this.MakeShiftShopify();
        },
        error: function (xhr, status, error) {
          console.log("Error: " + error);
        },
      });
    },
    MakeShiftShopify() {
      console.log("make shift shopify");
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });

      var variantId = this.AdultData.event; // The variant ID you want to add to the cart
      var quantity1 = 1;
      console.log("quantity: ", quantity1);
      variantId = window.btoa(`gid://shopify/ProductVariant/${variantId}`);

      // Generate a UUID
      const uuid = this.uuid;

      // Create an array to hold custom attributes
      let customAttributes = [
        { key: "UUID", value: uuid },
        { key: "event_date", value: "11/02" },
      ];

      client.checkout
        .create({
          customAttributes: customAttributes,
        })
        .then((checkout) => {
          var lineItemsToAdd = [
            {
              variantId: variantId,
              quantity: quantity1,
            },
          ];
          return client.checkout.addLineItems(checkout.id, lineItemsToAdd);
        })
        .then((checkout) => {
          // Direct the user to the checkout
          window.location.href = checkout.webUrl;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    // I need a function to run when they click the buy now button before the waiver prep function that does a double check to see if that site is available
    checkSiteAvailability() {
      console.log("checking site availability");
      // Grab the latest available sites information
      if (this.selectedSite === "Single Site") {
        console.log("double checking single sites");
        this.grabAvailableSingleSites();
      } else {
        console.log("double checking double sites");
        this.grabAvailableDoubleSites();
      }

      // Perform the double check to see if the selected site is available
      // You can use the TakenSingles and TakenDoubles arrays to check if the site is taken
      setTimeout(() => {
        if (this.selectedSite === "Single Site") {
          if (this.TakenSingles.includes(this.selectedSiteNumber)) {
            // Site is not available
            console.log("Selected single site is not available");
            // show an error message and reload page
            console.log("need to show error message because the site is taken");
          } else {
            this.prepWaiver();
          }
        } else {
          if (this.TakenDoubles.includes(this.selectedSiteNumber)) {
            // Site is not available
            console.log("Selected double site is not available");
            // show an error message and reload page
          } else {
            this.prepWaiver();
          }
        }
      }, 2000);
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-weight: 700;
}
.ticket-button {
  width: 81px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #96a145;
  color: white;
  border-radius: 50px;
  margin-bottom: 5px;
  cursor: pointer;
}
#sidebyside {
  display: flex;
}
#ghostpic {
  width: 350px;
}
#campImg {
  width: 100%;
  height: auto;
}
.date-circle {
  background: #ff8080;
}
#nomargin {
  margin-top: 0px;
}
#camping-sub {
  margin-left: 24px;
}
#text-cont {
  position: relative;
  top: -124px;
}
#left {
  width: 100%;
}
#right {
  width: 100%;
}
#mobileImg {
  display: none;
}
.rule-cont {
  display: flex;
}
.rule-cont > img {
  margin-top: 0px !important;
}
.inner-rule-cont {
  display: flex;
  margin-left: 15px;
  margin-top: 10px;
  align-items: flex-start;
}
.inner-rule-cont > p {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 15px;
}
#product-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}
#product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#product-head {
  color: #494e53;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0px 17px;
}
#green-text {
  color: #96a145;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  text-decoration-line: underline;
}
#price {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
#buybutton {
  border-radius: 50px;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 14px 30px;
}
#buybutton-grey {
  border-radius: 50px;
  background: #9f9f9f;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 14px 30px;
}
.topCon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.greenCard {
  background-color: #dde0c1 !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  flex-direction: row;
}
#glass-head {
  font-family: "boldRoboto";
  font-size: 20px;
  color: #000000;
}
#glass-sub {
  font-family: "Roboto";
  font-size: 16px;
  color: #000000;
}
.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.v-breadcrumbs {
  margin: 0px 12px 0px 12px;
}
p {
  line-height: 22px;
}

.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.select-date-headings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  /* width: 19em; */
}

.select-date-h2 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.select-date-h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

/* .get-dates-wrap {
      display: flex;
      justify-content: flex-end;
    } */

.all-dates-wrap {
  display: flex;
}

.dates-wrap {
  overflow: hidden;
  width: 330px;
  margin-right: 24px;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.painting-date {
  background-color: #e7ebc2;
  width: 330px;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.kids-wrap {
  border-bottom: #e6e6e6 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  padding: 0px 10px 0px 10px;
}

.icon-text-wrap {
  display: flex;
}

.adult-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  padding: 0px 10px 0px 10px;
}

.buy-ticket-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-wrap {
  border-bottom: 1px solid #666;
  border-top: 1px solid #666;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
  width: 20em;
  text-align: center;
}

.vc-container {
  border: none;
}
.divider {
  margin: 24px 0px;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}
.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 75px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}

.link {
  font-size: 16px;
  font-family: "Roboto";
  color: #96a145;
  cursor: pointer;
}

.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
}

.eventImg-mobile {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: bottom;
  height: auto;
  position: relative;
  display: none;
}
.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}
.cloud {
  margin-right: 25px;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* max-width: 1600px; */
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(56, 56, 56, 0));
}

.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.eventText {
  margin: 0px;
}
.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}
.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}
.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}
.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}
.waiverBox {
  margin-left: 32px;
}
.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}
.shopifyBox2 {
  margin-top: 0px;
}
.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}
.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}
.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}
.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}
.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}
.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}
.green-btn:hover {
  background-color: #818a3c;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}
b {
  font-weight: bold;
}
.mobileShow {
  display: none;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
.passContainer {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
}
.passContainer2 {
  display: flex;
  margin: 12px 0px;
}
.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}
#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}
.iconMargin {
  margin-left: 14px;
}
.passMargin {
  margin-left: 34px;
}
.infoMargin {
  max-width: 50%;
}
.infoMargin2 {
  margin-left: 75px;
  max-width: 60%;
}
.price {
  text-align: center;
  display: block;
  margin: 3px;
}
.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}
.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}
a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 16px;
}
.noMarginimg {
  margin-top: 22px !important;
}
@media only screen and (max-width: 850px) {
  .passTitle {
    margin-top: 20px;
  }
  .eventSmall {
    margin-bottom: 0px !important;
  }
  .passContainer2 {
    display: inline;
  }
  .passContainer {
    align-items: flex-start;
  }
  .passContainer > img {
    margin-top: 15px;
  }
  .passMargin {
    margin-left: 16px;
  }
  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }
  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }
  .bottom-left {
    padding: 25px;
  }
  .page-wrap {
    padding: 16px;
  }
  .mobileShow {
    display: block;
  }
  .waiverBox {
    display: none;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .flexWrap {
    width: auto;
  }
  .shopifyFlex {
    display: block;
  }
  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }
  .shopifyWrap2 {
    margin-left: 0px;
  }
  .wrapLeft {
    margin: 8px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .eventInfoTitle {
    font-size: 16px;
  }
  .infoBox {
    margin-top: 8px !important;
  }
  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }
  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }

  .eventImg-mobile {
    /* filter: brightness(80%); */
    display: block;
    object-position: top;
    height: 450px;
  }
}
@media only screen and (max-width: 768px) {
  .all-dates-wrap {
    flex-direction: column;
  }
  #text-cont {
    top: -95px;
  }
  .dates-wrap {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 20px;
  }

  .painting-date {
    background-color: #e7ebc2;
    width: 100%;
  }

  .kids-wrap {
    border-bottom: #e6e6e6 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 108px;
    padding: 0px 10px 0px 10px;
  }

  .icon-text-wrap {
    display: flex;
  }

  .adult-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 108px;
    padding: 0px 10px 0px 10px;
  }
  .topCon {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
  }
  .passTitle {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 425px) {
  .dates-wrap {
    border-bottom: none;
    margin-bottom: 0px;
  }
  .select-date-h3 {
    margin-bottom: 16px;
  }
  .eventImg {
    display: none;
  }
  #mobileImg {
    display: block;
    width: 100%;
  }
  .v-breadcrumbs[data-v-a302ba90] {
    margin: 0px 12px 18px 0px;
  }
  #sidebyside {
    flex-direction: column;
  }
  #ghostpic {
    width: 280px;
  }
  .inner-rule-cont > p {
    color: #494e53;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  #text-cont {
    top: -307px;
  }
  .v-breadcrumbs {
    margin-left: 0px;
  }
  .v-text-field.v-text-field--enclosed {
    margin: unset !important;
  }
  .inner-rule-cont > img {
    width: 24px;
    height: 24px;
  }
  .rule-cont > img {
    width: 16px;
    height: 16px;
  }
  #green-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 375px) {
  #glass-head {
    font-size: 16px;
  }
  #glass-sub {
    font-size: 14px;
  }
  #text-cont {
    top: -262px;
  }
}
@media only screen and (max-width: 320px) {
  #glassesBig {
    width: 18%;
  }
  #text-cont {
    top: -175px;
  }
}
</style>
<style>
.v-list-item__title {
  color: #494e53 !important;
  font-weight: 700 !important;
}
</style>
