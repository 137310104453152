<template>
  <v-app>
    <div class="header">
      <div class="header-cont">
        <img src="../../assets/images/parkspass-logo-2023.svg" alt="" />
        <h3>Check-In</h3>
      </div>
    </div>
    <div class="main-cont">
      <h1>Select Your Pass</h1>
      <v-card class="card1" @click="selectMethod('cash')">
        <div class="img-cont1">
          <img
            src="../../assets/images/entrance-fee-envelope.png"
            alt=""
            class="img1"
          />
        </div>
        <div class="title-cont">
          <h2>Cash Envelope Payment</h2>
        </div>
      </v-card>
      <div class="card2-cont">
        <v-card class="card2" @click="selectMethod('annual')">
          <div class="img-cont2">
            <img
              src="../../assets/images/solo-brands-annual-pass.png"
              alt=""
              class="img2"
            />
          </div>
          <div class="title-cont">
            <h2>Annual Pass</h2>
          </div>
        </v-card>
        <v-card
          class="card2"
          style="margin-left: 4%"
          @click="selectMethod('digitalday')"
        >
          <div class="img-cont2">
            <img
              src="../../assets/images/digital-day-use.png"
              alt=""
              class="img2"
            />
          </div>
          <div class="title-cont">
            <h2>Digital Day-Use Pass</h2>
          </div>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Check-In",
  metaInfo: {
    title: "Check-In",
    meta: [
      {
        name: "description",
        content: "Check-In",
      },
    ],
  },
  data() {
    return {
      park_abbr: this.$route.params.park,
    };
  },
  methods: {
    selectMethod(method) {
      // Handle the selected purchase method here
      console.log("Selected method:", method);
      // You can perform any necessary logic or navigation based on the selected method
      this.$router.push(`/${this.park_abbr}/checkin/` + method);
    },
  },
};
</script>

<style scoped>
h1 {
  color: #444;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
h2 {
  color: #444;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}
h3 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
button {
  margin-right: 8px;
}
.header {
  background: #2e2e31;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
}
.header-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-cont {
  margin: 20px;
}
.img-cont1 {
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  background: #f2f2f2;
  padding: 8px;
}
.card1 {
  width: 100%;
  margin-top: 16px;
}
.title-cont {
  padding: 8px;
}
.card2-cont {
  display: flex;
  margin-top: 16px;
}
.card2 {
  width: 48%;
}
.img-cont2 {
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  background: #f2f2f2;
  padding: 8px;
}
#app {
  height: 100vh;
  background: #fff;
}
</style>
