import { render, staticRenderFns } from "./Oasis.vue?vue&type=template&id=49bf5887&scoped=true&"
import script from "./Oasis.vue?vue&type=script&lang=js&"
export * from "./Oasis.vue?vue&type=script&lang=js&"
import style0 from "./Oasis.vue?vue&type=style&index=0&id=49bf5887&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49bf5887",
  null
  
)

export default component.exports