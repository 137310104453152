<template>
  <div class="set-min-height">
    <Header />
    <!-- <Navbar></Navbar> -->
    <div class="center-page">
      <div class="page-title-container">
        <p style="margin-top: 32px">404... Page Not Found</p>
        <p>
          Sorry, this url doesn't seem to exist. Please try another page or
          <u class="homeLink" @click="$router.push('/')">Click Here</u> to
          return to the home screen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  name: "PageNotFound",
  metaInfo() {
    return {
      title: "Error",
    };
  },
  components: {
    Header,
  },
};
</script>

<style scoped>
h1,
p {
  color: black;
}
.center-page {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 8px;
  max-width: 500px;
  text-align: center;
  margin-top: 15%;
}
.page-title-container p,
.page-title-container p u {
  font-size: 24px;
  font-weight: "boldRoboto";
}
.homeLink {
  color: #96a145;
  cursor: pointer;
}
.homeLink:hover {
  color: #7a8339;
}
</style>
