<template>
  <div>
    <v-app>
      <Header v-if="!mobileSearch"></Header>
      <div v-if="mobileSearch == true" class="blackBack"></div>
      <div
        v-bind:style="{ backgroundImage: 'url(' + homeHeroImg + ')' }"
        v-else
      >
        <!-- <div id="homeImgContainer" v-if="registerHome"> -->
        <div id="homeImgContainer" v-if="currentStep === 'registerHome'">
          <div class="centered-home">
            <!-- <h2 class="home1">PLAN YOUR TRIP AHEAD!</h2> -->
            <div class="home3">Utah State Parks</div>
            <h3 class="home2">
              Register Your<br />
              Annual Pass
            </h3>
            <div class="purchase-pass">Purchased Your Pass?</div>
            <div class="twelve-months">
              Your pass can now be valid for a Full 12 Months<br />
              when Registered
            </div>
            <div class="register-btn" @click="getStarted">Register now</div>
          </div>
        </div>
        <!-- <div v-if="registerPage"> -->
        <div v-if="currentStep === 'registerPage'">
          <div>
            <v-container>
              <div class="code-page">
                <div class="registration-title mt-3">Registration Number</div>
                <div class="registration-wrap">
                  <div class="d-flex registration-flex">
                    <div class="d-flex flex-column align-center mr-4">
                      <img
                        src="../assets/images/Registration Sticker.png"
                        alt="arrow pointing at image to show where the registration code is"
                        class="register-pass-img"
                      />
                    </div>

                    <div class="grey-box">
                      <v-spacer></v-spacer>
                      <div class="d-flex justify-start new-label">
                        <label class="d-flex">Registration Number</label>
                      </div>
                      <div class="input-group" id="registration-number">
                        <input
                          type="text"
                          v-model="part1"
                          maxlength="3"
                          @keyup="autoTab($event, 'part2')"
                          ref="part1"
                          placeholder="###"
                          class="inputs"
                          oninput="this.value = this.value.toUpperCase()"
                        />
                        <!-- <span>-</span>
                        <input
                          type="text"
                          v-model="part2"
                          maxlength="3"
                          @keyup="autoTab($event, 'part3')"
                          ref="part2"
                          placeholder="###"
                          class="inputs"
                          oninput="this.value = this.value.toUpperCase()"
                        />
                        <span>-</span>
                        <input
                          type="text"
                          v-model="part3"
                          maxlength="3"
                          @keyup="autoTab($event, 'part4')"
                          ref="part3"
                          placeholder="###"
                          class="inputs"
                          oninput="this.value = this.value.toUpperCase()"
                        />
                        <span>-</span>
                        <input
                          type="text"
                          v-model="part4"
                          maxlength="3"
                          ref="part4"
                          placeholder="###"
                          class="inputs"
                          oninput="this.value = this.value.toUpperCase()"
                        /> -->
                      </div>
                      <v-spacer v-if="!doesNotExist || !alreadyUsed"></v-spacer>
                      <!-- <div class="registration-error" v-if="doesNotExist">
                        <v-icon color="#D0021B">mdi-alert</v-icon>Registration
                        number not found!
                      </div>
                      <div class="registration-error" v-if="alreadyUsed">
                        <v-icon color="#D0021B">mdi-alert</v-icon>Registration
                        number already used!
                      </div> -->
                      <div class="mb-3">
                        Your registration number is found on the sticker you
                        were given when you purchased you pass.
                      </div>

                      <div class="d-flex justify-end mt-3">
                        <!-- <div class="next-btn" @click="checkCode">Next</div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-container>
          </div>
          <!-- <div v-if="insertBarcodePage"> -->
          <!-- <div v-if="currentStep === 'insertBarcodePage'"> -->
          <!-- <div>
            <v-container class="">
              <div class="barcode-page">
                <div class="registration-title">Annual Pass Card Number</div>
                <div class="barcode-wrap">
                  <div class="d-flex registration-flex">
                    <div class="d-flex flex-column align-center mr-4">
                      <img
                        src="../assets/images/pass-showing-barcode.svg"
                        alt="arrow pointing at image to show where the registration code is"
                        class="register-pass-img"
                      />
                    </div>

                    <div class="barcode-grey-box">
                      <div class="d-flex flex-column">
                        <label>Annual Pass Card Number</label>
                        <input
                          class="barcode-input"
                          placeholder="* Annual pass card number"
                          v-model="barcode"
                          required
                          oninput="this.value = this.value.toUpperCase()"
                        />
                        <div
                          class="registration-error d-flex align-center mt-2"
                          v-if="barcodeError"
                        >
                          <v-icon color="#d0021B">mdi-alert</v-icon>Required
                        </div>
                        <div
                          class="registration-error d-flex align-center mt-2"
                          v-if="barcodeBError"
                        >
                          <v-icon color="#d0021B">mdi-alert</v-icon>Invalid
                          barcode. Please try again.
                        </div>
                        <div class="annual-text">
                          Your annual pass card number is found on the back of
                          your pass next to the barcode.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-container>
          </div> -->
          <!-- <div v-if="infoPage"> -->
          <!-- <div v-if="currentStep === 'infoPage'"> -->
          <div>
            <v-container>
              <div class="">
                <form class="personal-wrap">
                  <div class="registration-title">Personal Information</div>
                  <div>* Required Information</div>
                  <div class="registration-error d-flex flex-column mt-3">
                    <!-- <div>
                    <v-icon color="#d0021b">mdi-alert</v-icon>
                  </div> -->
                    <div class="registration-error mr-1" v-if="fillOutRequired">
                      Please fill out all required fields.
                    </div>
                    <div class="registration-error mr-1" v-if="infoErrors">
                      Please fill out required fields. Missing:
                    </div>
                    <div class="registration-error-wrap">
                      <div
                        class="registration-error d-flex mr-1"
                        v-if="noEmail"
                      >
                        Email,
                      </div>
                      <div class="registration-error mr-1" v-if="noPhone">
                        Phone,
                      </div>
                      <div class="registration-error mr-1" v-if="noFirst">
                        First Name,
                      </div>
                      <div class="registration-error mr-1" v-if="noLast">
                        Last Name,
                      </div>
                      <div class="registration-error mr-1" v-if="noAddress">
                        Address,
                      </div>
                      <div class="registration-error mr-1" v-if="noCity">
                        City,
                      </div>
                      <div class="registration-error mr-1" v-if="noZip">
                        Zip,
                      </div>
                      <div class="registration-error" v-if="noLicense1">
                        License Plate 1
                      </div>
                    </div>
                  </div>
                  <div class="desktop">
                    <div class="d-flex flex-column contact-license">
                      <div class="">
                        <div class="personal-grey-box">
                          <div class="contact-info">Contact</div>
                          <input
                            placeholder="* Email"
                            class="personal-input mb-4"
                            v-model="email"
                            required
                          />
                          <input
                            placeholder="* Phone"
                            class="personal-input"
                            v-model="phone"
                            required
                          />
                        </div>
                        <div class="personal-grey-box">
                          <div class="contact-info">License Plates</div>
                          <input
                            placeholder="* License"
                            v-model="license1"
                            class="personal-input"
                            required
                          />
                          <div class="mb-4 license-reqs">
                            Must be 4-7 digits, no spaces
                          </div>
                          <input
                            placeholder="License 2"
                            v-model="license2"
                            class="personal-input mb-4"
                          />
                          <input
                            placeholder="License 3"
                            v-model="license3"
                            class="personal-input"
                          />
                        </div>
                        <div class="personal-grey-box">
                          <div class="contact-info">Pass Type</div>
                          <p style="margin-bottom: 0px">
                            * Are you 65 or older?
                          </p>
                          <v-radio-group v-model="senior">
                            <v-radio
                              label="Yes"
                              value="yes"
                              color="#96A145"
                            ></v-radio>
                            <v-radio
                              label="No"
                              value="no"
                              color="#96A145"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>

                    <div class="address">
                      <!-- <div class=""> -->
                      <div class="personal-grey-box">
                        <div class="contact-info">Passholder 1</div>
                        <input
                          placeholder="* First Name"
                          class="personal-input mb-4 input-margin"
                          v-model="firstName1"
                          required
                        />
                        <input
                          placeholder="* Last Name"
                          class="personal-input mb-4"
                          v-model="lastName1"
                          required
                        />
                      </div>
                      <div class="personal-grey-box">
                        <div class="contact-info">Passholder 2</div>
                        <input
                          placeholder="* First Name"
                          class="personal-input mb-4 input-margin"
                          v-model="firstName2"
                          required
                        />
                        <input
                          placeholder="* Last Name"
                          class="personal-input mb-4"
                          v-model="lastName2"
                          required
                        />
                      </div>
                      <div class="personal-grey-box">
                        <div class="contact-info">Address</div>
                        <!-- <div class="d-flex first-last-names"> -->
                        <!-- </div> -->
                        <input
                          placeholder="* Address"
                          class="personal-input mb-4"
                          v-model="address"
                          required
                        />
                        <!-- <div class="d-flex city-state-zip"> -->
                        <input
                          placeholder="* City"
                          class="personal-input input-margin mb-4"
                          v-model="city"
                          required
                        />
                        <input
                          placeholder="Utah"
                          class="personal-input input-margin mb-4"
                          v-model="state"
                          required
                          disabled
                          id="utah"
                        />
                        <!-- <span class="tooltip-text"
                        >Only available for Utah residents</span
                      > -->
                        <input
                          placeholder="* Zip"
                          class="personal-input mb-4"
                          v-model="zip"
                          required
                        />
                        <!-- </div> -->
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="mobile">
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">Contact</div>
                        <input
                          placeholder="* Email"
                          class="personal-input mb-4"
                          v-model="email"
                          required
                        />
                        <input
                          placeholder="* Phone"
                          class="personal-input"
                          v-model="phone"
                          required
                        />
                      </div>
                    </div>
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">Passholder 1</div>
                        <div class="d-flex first-last-names">
                          <input
                            placeholder="* First Name"
                            class="personal-input mb-4 input-margin"
                            v-model="firstName1"
                            required
                          />
                          <input
                            placeholder="* Last Name"
                            class="personal-input mb-4"
                            v-model="lastName1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">Passholder 2</div>
                        <div class="d-flex first-last-names">
                          <input
                            placeholder="* First Name"
                            class="personal-input mb-4 input-margin"
                            v-model="firstName2"
                            required
                          />
                          <input
                            placeholder="* Last Name"
                            class="personal-input mb-4"
                            v-model="lastName2"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">Address</div>
                        <input
                          placeholder="* Address"
                          class="personal-input mb-4"
                          v-model="address"
                          required
                        />
                        <div class="d-flex city-state-zip">
                          <input
                            placeholder="* City"
                            class="personal-input input-margin mb-4"
                            v-model="city"
                            required
                          />
                          <input
                            placeholder="Utah"
                            class="personal-input input-margin mb-4"
                            v-model="state"
                            required
                            disabled
                            id="utah"
                          />

                          <input
                            placeholder="* Zip"
                            class="personal-input mb-4"
                            v-model="zip"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">License Plates</div>
                        <input
                          placeholder="* License"
                          v-model="license1"
                          class="personal-input"
                          required
                        />
                        <div class="mb-4 license-reqs">
                          Must be 4-8 digits, no spaces
                        </div>
                        <input
                          placeholder="License 2"
                          v-model="license2"
                          class="personal-input mb-4"
                        />
                        <input
                          placeholder="License 3"
                          v-model="license3"
                          class="personal-input"
                        />
                      </div>
                    </div>
                    <div class="box-wrap">
                      <div class="personal-grey-box">
                        <div class="contact-info">Pass Type</div>
                        <!-- radio -->
                        <p style="margin-bottom: 0px">* Are you 65 or older?</p>
                        <v-radio-group>
                          <v-radio
                            label="Yes"
                            value="yes"
                            color="#96A145"
                          ></v-radio>
                          <v-radio
                            label="No"
                            value="no"
                            color="#96A145"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-end mt-3">
                    <div class="next-btn" @click="submitData">Send</div>
                  </div>
                </form>
              </div>
            </v-container>
          </div>
        </div>
        <div class="steps-full-flex" v-if="currentStep === 'nextStepsPage'">
          <div class="steps-container">
            <div class="steps-wrap">
              <div>
                <div class="steps-title">Next Steps:</div>
                <div>
                  <ol>
                    <li>Download the Utah State Parks App</li>
                    <ol type="a">
                      <li>Click on ADD PASS.</li>
                      <li>
                        Enter your information including order number
                        {{ orderNum }}
                      </li>
                    </ol>
                  </ol>
                </div>
                <div>
                  <div class="steps-title">Download the App</div>
                  <div class="d-flex">
                    <div class="next-btn mr-3" @click="goToIOS">IOS</div>
                    <div class="next-btn" @click="goToAndroid">ANDROID</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps-wrap">
              <div>
                <div class="steps-title">Order Details</div>
                <div>
                  <div class="steps-subtitle">Registration number</div>
                  <div>{{ fullCode }}</div>
                </div>
                <div>
                  <div class="steps-subtitle">Annual pass card number</div>
                  <div>{{ barcode }}</div>
                </div>
                <div>
                  <div class="steps-subtitle">Contact</div>
                  <div>{{ email }}</div>
                  <div>{{ phone }}</div>
                </div>
                <div>
                  <div class="steps-subtitle">Passholders</div>
                  <div>{{ firstName1 }} {{ lastName1 }}</div>
                  <div>{{ firstName2 }} {{ lastName2 }}</div>
                </div>
                <div>
                  <div class="steps-subtitle">Address</div>

                  <div>{{ address }}</div>
                  <div>{{ city }}, Utah {{ zip }}</div>
                  <div>United States</div>
                </div>
                <div>
                  <div class="steps-subtitle">License Plates</div>
                  <div>{{ license1 }}</div>
                  <div>{{ license2 }}</div>
                  <div>{{ license3 }}</div>
                </div>
              </div>
            </div>
            <div class="help">
              Need Help?
              <a
                href="https://parkspass.zendesk.com/hc/en-us"
                class="contact-us"
                >Contact Us</a
              >
            </div>
          </div>
          <div class="order-num-container">
            <div class="order-num-wrap">
              <div class="pass-now-registered">
                Your pass is now registered!
              </div>
              <div class="order-num">
                <div class="order-num-title">Activation Code:</div>
                <div class="mt-1 d-flex" @click="copyToClipboard">
                  <div ref="contentToCopy" class="order-num-text">
                    {{ orderNum }}
                  </div>

                  <div class="copy-button ml-2">
                    <div class="copy-text mr-1">Copy</div>
                    <v-icon color="white" class="copy-icon"
                      >mdi-content-copy</v-icon
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="another-pass mt-6" @click="registerAnother">
              Register Another Pass
            </div>
          </div>
        </div>

        <!-- <Footer></Footer> -->
      </div>
    </v-app>
  </div>
</template>

<script>
// import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

import $ from "jquery";

import { parksJson } from "../assets/ParkFiles/parks";

export default {
  name: "Register",
  components: {
    // Footer,
    Header,
  },
  data() {
    return {
      backgroundImage: "",
      mobile: false,
      locations: "",
      lakeWaiver: false,
      filterSearch: "",
      parks: parksJson,
      // parks_menu: parksJson,
      mobileSearch: false,
      park: "",
      searchFocused: false,
      registerHome: true,
      registerPage: false,
      insertBarcodePage: false,
      infoPage: false,
      nextStepsPage: false,
      part1: "",
      part2: "",
      part3: "",
      part4: "",
      fullCode: "",
      doesNotExist: false,
      alreadyUsed: false,
      orderNum: "",
      barcode: "",
      email: "",
      phone: "",
      firstName1: "",
      lastName1: "",
      firstName2: "",
      lastName2: "",
      address: "",
      city: "",
      state: "Utah",
      zip: "",
      license1: "",
      license2: "",
      license3: "",
      currentStep: "registerHome",
      barcodeError: false,
      noEmail: false,
      noPhone: false,
      noFirst: false,

      noLast: false,
      noAddress: false,
      noCity: false,
      noZip: false,
      noLicense1: false,
      infoErrors: false,
      barcodeBError: false,
      fillOutRequired: false,
      id: "",
      senior: "",
    };
  },
  metaInfo() {
    return {
      title: "Parkspass",
      titleTemplate: null,
      meta: [{ name: "description", content: this.currentParkMeta }],
    };
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.parks);
    this.orderNum = localStorage.getItem("orderNum");
    this.fullCode = localStorage.getItem("fullCode");
    this.barcode = localStorage.getItem("barcode");
    this.barcode = this.id;
    this.email = localStorage.getItem("email");
    this.phone = localStorage.getItem("phone");
    this.firstName1 = localStorage.getItem("firstName1");
    this.lastName1 = localStorage.getItem("lastName1");
    this.firstName2 = localStorage.getItem("firstName2");
    this.lastName2 = localStorage.getItem("lastName2");
    this.address = localStorage.getItem("address");
    this.city = localStorage.getItem("city");
    // this.state = localStorage.getItem("state");
    this.zip = localStorage.getItem("zip");
    this.license1 = localStorage.getItem("license1");
    this.license2 = localStorage.getItem("license2");
    this.license3 = localStorage.getItem("license3");

    /// console.log("ORDER NUM", this.orderNum);

    // When the component is created, check if there's a saved step in localStorage

    // console.log("current step", this.currentStep);
    this.currentStep = localStorage.getItem("currentStep") || "registerHome";

    var newParks = [];
    for (let i in this.parks) {
      if ((this.parks[i].abbr != "DANUAL") & (this.parks[i].abbr != "ANNUAL")) {
        newParks.push(this.parks[i]);
      }
    }
    this.parks = newParks;
    // PARKS PASS APP
    if ($(window).width() <= 720) {
      this.mobile = true;
    }
    if (!this.parkFromArg()) {
      window.addEventListener("popstate", this.statePopped);
    }

    //this.homeHeroImg = "../assets/images/snowcanyon-large.png";
    this.homeHeroImg = "../assets/images/register-home-small.png";

    // this.loadHomeImg();
    // this.parks_menu.shift()
  },
  updated() {
    if (this.mobileSearch) {
      document.getElementById("autocomplete-mobile").click();
      this.searchFocused = true;
    }
  },
  methods: {
    async copyToClipboard() {
      try {
        // Use the ref to access the content and then copy it using the Clipboard API
        const textToCopy = this.$refs.contentToCopy.innerText;
        await navigator.clipboard.writeText(textToCopy);
        // Optional: Alert or indicate to the user that the text has been copied
        // alert("Text copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("is mobile");
        return true;
      } else {
        console.log("desktop");
        return false;
      }
    },
    searchFocus() {
      this.searchFocused = true;
      if (this.mobile) {
        this.mobileSearch = true;
      }
    },
    searchBlur() {
      this.searchFocused = false;
    },
    loadHomeImg: function () {
      var screenWidth = window.innerWidth;
      if (screenWidth < 500) {
        this.homeHeroImg = "../assets/images/register-home-small.png";
      } else if (screenWidth < 800) {
        this.homeHeroImg = "../assets/images/register-home-large.png";
      } else if (screenWidth < 1400) {
        this.homeHeroImg = "../assets/images/register-home-large.png";
      }
    },
    //get park from arguments
    parkFromArg: function () {
      // This function is solely used for the QR codes that are advertised in the parks to rederict them to the right place.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("p")) {
        const parkAbbr = urlParams.get("p").toUpperCase();
        const reg = /^[A-Z]{6}$/;
        if (!reg.test(parkAbbr)) {
          return false;
        }
        var parkName = null;
        var abbr = null;
        for (var i = 0; i < this.parks.length; i++) {
          if (this.parks[i].abbr == parkAbbr) {
            parkName = this.parks[i].name;
            abbr = this.parks[i].abbr;
            this.routerPush(abbr);
            break;
          }
        }
        if (parkName != null && abbr != null) {
          return true;
        }
        /*if (parkName !== null) {
            this.park = parkName;
            var abbr;
            for (var j = 0; j < this.parks.length; j++) {
              if (this.parks[j].name == this.park) {
                abbr = this.parks[j].abbr;
                this.routerPush(abbr);
                break;
              }
            }
            return true;
          } else {
            return false;
          }*/
      }
      return false;
    },
    locationSelected: function () {
      var abbr;
      for (var i = 0; i < this.parks.length; i++) {
        if (this.parks[i].name == this.park) {
          abbr = this.parks[i].abbr;
          this.routerPush(abbr);
          break;
        }
      }
    },
    routerPush(abbr) {
      if (abbr == "UTFLIG") {
        this.$router.push(`/parks/${abbr}/camping`);
      } else if (abbr == "UTRAPT") {
        this.$router.push(`/parks/${abbr}/camping`);
      }
      // else if (abbr == "UTJOHV") {
      //   this.$router.push("/parks/UTJOHV/jordan");
      // }
      else {
        this.$router.push(`/parks/${abbr}/dayuse`);
      }
    },
    getStarted() {
      this.registerHome = false;
      this.registerPage = true;
      this.currentStep = "registerPage";
      localStorage.setItem("currentStep", this.currentStep);
      //   console.log("button pushed");
    },
    autoTab(event, nextRef) {
      if (event.target.value.length === event.target.maxLength) {
        if (nextRef) {
          this.$refs[nextRef].focus();
        } else {
          event.target.blur();
        }
      }
    },

    submitData() {
      this.fullCode = `${this.part1}-${this.part2}-${this.part3}-${this.part4}`;

      // Simplify null checks with a loop to clean up the code
      [
        "email",
        "phone",
        "firstName1",
        "lastName1",
        "firstName2",
        "lastName2",
        "address",
        "city",
        "zip",
        "license1",
        "license2",
        "license3",
      ].forEach((field) => {
        if (this[field] == null) {
          this[field] = "";
        }
      });

      // Validate the barcode before making the API call
      if (this.barcode === "" || this.barcode === null) {
        console.log("Barcode is empty or null");
        this.barcodeError = true;
        return false; // Prevent API call if barcode is invalid
      } else if (this.barcode.charAt(0) !== "B" || this.barcode.length !== 9) {
        console.log("Barcode format error:", this.barcode);
        this.barcodeBError = true;
        return false; // Prevent API call if barcode format is incorrect
      }

      const encodedAddress = encodeURIComponent(this.address);

      // API settings configuration
      // need to fix this to take 2 passholders
      var settings = {
        url: `https://trailwaze.info/parkspass/api/addMAnnualPass_Combined.php?code=${this.fullCode}&barcode=${this.barcode}&email=${this.email}&phone=${this.phone}&first_name=${this.firstName}&last_name=${this.lastName}&address=${encodedAddress}&city=${this.city}&state=Utah&zip=${this.zip}&lp1=${this.license1}&lp2=${this.license2}&lp3=${this.license3}`,
        method: "GET",
        timeout: 0,
        headers: {
          PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
        },
      };

      // AJAX call to the server
      $.ajax(settings).done((response) => {
        console.log(response);

        // Error handling based on the server response
        if (
          response.includes("Invalid code") ||
          response.includes("Code already used") ||
          response.includes("Empty required variables")
        ) {
          console.log("Error from server:", response);
          this.barcodeError = this.barcodeBError = false; // Reset previous errors
          // Set appropriate flags based on errors
          this.doesNotExist = response.includes("Invalid code");
          this.alreadyUsed = response.includes("Code already used");
          this.fillOutRequired = response.includes("Empty required variables");
          return false; // Stop further processing if any error is found
        }

        if (response.includes("success")) {
          const responseObject = JSON.parse(response);
          this.orderNum = responseObject.order_number;
          localStorage.setItem("orderNum", this.orderNum);
          // if (response.order_number) {
          //   console.log("Order Number:", response.order_number);
          // }

          // Successful response handling
          this.storeUserData();
          this.navigateToNextStep();
        }
      });
    },

    // Function to store user data to localStorage
    storeUserData() {
      const fields = [
        "orderNum",
        "fullCode",
        "barcode",
        "email",
        "phone",
        "firstName1",
        "lastName1",
        "firstName2",
        "lastName2",
        "address",
        "city",
        "state",
        "zip",
        "license1",
        "license2",
        "license3",
      ];
      fields.forEach((field) => localStorage.setItem(field, this[field]));
    },

    // Function to handle navigation to next steps
    navigateToNextStep() {
      this.registerPage = false;
      this.insertBarcodePage = false;
      this.nextStepsPage = true;
      this.currentStep = "nextStepsPage";
      localStorage.setItem("currentStep", this.currentStep);
    },

    // submitData() {
    //   this.fullCode = `${this.part1}-${this.part2}-${this.part3}-${this.part4}`;

    //   if (this.email == null) {
    //     this.email = "";
    //   }
    //   if (this.phone == null) {
    //     this.phone = "";
    //   }
    //   if (this.firstName == null) {
    //     this.firstName = "";
    //   }
    //   if (this.lastName == null) {
    //     this.lastName = "";
    //   }
    //   if (this.address == null) {
    //     this.address = "";
    //   }
    //   if (this.city == null) {
    //     this.city = "";
    //   }
    //   if (this.zip == null) {
    //     this.zip = "";
    //   }
    //   if (this.license1 == null) {
    //     this.license1 = "";
    //   }
    //   if (this.license2 == null) {
    //     this.license2 = "";
    //   }
    //   if (this.license3 == null) {
    //     this.license3 = "";
    //   }

    //   var settings = {
    //     url: `https://trailwaze.info/parkspass/api/addMAnnualPass_Combined.php?code=${this.fullCode}&barcode=${this.barcode}&email=${this.email}&phone=${this.phone}&first_name=${this.firstName}&last_name=${this.lastName}&address=${this.address}&city=${this.city}&state=Utah&zip=${this.zip}&lp1=${this.license1}&lp2=${this.license2}&lp3=${this.license3}`,
    //     method: "GET",
    //     timeout: 0,
    //     headers: {
    //       PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
    //     },
    //   };

    //   $.ajax(settings).done((response) => {
    //     // Arrow function used here
    //     console.log(response);

    //     if (response.includes("Invalid code")) {
    //       this.doesNotExist = true;
    //     } else if (response.includes("Code already used")) {
    //       this.alreadyUsed = true;
    //     } else {
    //       this.doesNotExist = this.alreadyUsed = false;
    //     }

    //     if (this.barcode === "" || this.barcode === null) {
    //       console.log("Barcode is empty or null");
    //       this.barcodeError = true;
    //       return false;
    //     } else if (
    //       this.barcode.charAt(0) !== "B" ||
    //       this.barcode.length !== 9
    //     ) {
    //       console.log("Barcode format error:", this.barcode);
    //       this.barcodeBError = true;
    //       return false;
    //     }
    //     this.barcodeError = this.barcodeBError = false;

    //     if (response.includes("Empty required variables")) {
    //       this.fillOutRequired = true;
    //     }
    //     localStorage.setItem("orderNum", this.orderNum);
    //     localStorage.setItem("fullCode", this.fullCode);
    //     localStorage.setItem("barcode", this.barcode);
    //     localStorage.setItem("email", this.email);
    //     localStorage.setItem("phone", this.phone);

    //     localStorage.setItem("firstName", this.firstName);
    //     localStorage.setItem("lastName", this.lastName);
    //     localStorage.setItem("address", this.address);
    //     localStorage.setItem("city", this.city);
    //     localStorage.setItem("state", this.state);
    //     localStorage.setItem("zip", this.zip);
    //     localStorage.setItem("license1", this.license1);

    //     localStorage.setItem("license2", this.license2);
    //     localStorage.setItem("license3", this.license3);

    //     this.registerPage = false;

    //     this.insertBarcodePage = false;

    //     this.nextStepsPage = true;
    //     this.currentStep = "nextStepsPage";
    //     localStorage.setItem("currentStep", this.currentStep);
    //   });
    // },

    // async submitAll() {
    //   console.log("Starting submitAll...");

    //   // Validate barcode and info first before the code
    //   if (!this.validateBarcode() || !this.validateInfo()) {
    //     console.log("Barcode or Info validation failed");
    //     return; // Stop if barcode or info validation fails
    //   }

    //   // If barcode and info validations pass, then validate and submit the code
    //   try {
    //     console.log("Barcode and Info Valid, proceeding with checkCode...");
    //     await this.checkCode();
    //     console.log("checkCode completed");

    //     if (this.doesNotExist || this.alreadyUsed) {
    //       console.log(
    //         "Code validation issue:",
    //         this.doesNotExist ? "Code does not exist" : "Code already used"
    //       );
    //       return; // Stop if the code check fails
    //     }

    //     // Since code is now verified, proceed with adding the barcode and info officially
    //     console.log("Code valid, proceeding with addBarcode...");
    //     await this.addBarcode();
    //     console.log("Barcode added, proceeding with addInfo...");
    //     await this.addInfo();
    //     console.log("Info added");

    //     // Transition to next step after all done
    //     this.insertBarcodePage = false;
    //     this.nextStepsPage = true;
    //     this.currentStep = "nextStepsPage";
    //     localStorage.setItem("orderNum", this.orderNum);
    //     localStorage.setItem("email", this.email);
    //     localStorage.setItem("phone", this.phone);
    //     localStorage.setItem("firstName", this.firstName);

    //     localStorage.setItem("currentStep", this.currentStep);
    //     console.log("All steps completed successfully.");
    //   } catch (error) {
    //     console.error("Error in submitAll:", error);
    //     // Handle any other errors or setup a general error state
    //   }
    // },

    // validateAllInputs() {
    //   console.log("Validating all inputs...");
    //   const codeValid = this.validateCodeParts();
    //   const barcodeValid = this.validateBarcode();
    //   const infoValid = this.validateInfo();
    //   console.log("Code Parts Valid:", codeValid);
    //   console.log("Barcode Valid:", barcodeValid);
    //   console.log("Info Valid:", infoValid);
    //   return codeValid && barcodeValid && infoValid;
    // },

    // validateCodeParts() {
    //   const valid = this.part1 && this.part2 && this.part3 && this.part4;
    //   if (!valid) {
    //     console.log("Code Parts Validation Failed:", {
    //       part1: this.part1,
    //       part2: this.part2,
    //       part3: this.part3,
    //       part4: this.part4,
    //     });
    //   }
    //   return valid; // Adapt with actual validation rules
    // },

    // validateBarcode() {
    //   if (this.barcode === "" || this.barcode === null) {
    //     console.log("Barcode is empty or null");
    //     this.barcodeError = true;
    //     return false;
    //   } else if (this.barcode.charAt(0) !== "B" || this.barcode.length !== 9) {
    //     console.log("Barcode format error:", this.barcode);
    //     this.barcodeBError = true;
    //     return false;
    //   }
    //   this.barcodeError = this.barcodeBError = false;
    //   return true;
    // },

    // validateInfo() {
    //   if (
    //     !this.email ||
    //     !this.phone ||
    //     !this.firstName ||
    //     !this.lastName ||
    //     !this.address ||
    //     !this.city ||
    //     !this.zip ||
    //     !this.license1 ||
    //     this.email === null ||
    //     this.phone === null ||
    //     this.firstName === null ||
    //     this.lastName === null ||
    //     this.address === null ||
    //     this.city === null ||
    //     this.zip === null ||
    //     this.license1 === null
    //   ) {
    //     console.log("Info Validation Failed:", {
    //       email: this.email,
    //       phone: this.phone,
    //       firstName: this.firstName,
    //       lastName: this.lastName,
    //       address: this.address,
    //       city: this.city,
    //       zip: this.zip,
    //       license1: this.license1,
    //     });
    //     this.infoErrors = true;
    //     this.noEmail = !this.email;
    //     this.noPhone = !this.phone;
    //     this.noFirst = !this.firstName;
    //     this.noLast = !this.lastName;
    //     this.noAddress = !this.address;
    //     this.noCity = !this.city;
    //     this.noZip = !this.zip;
    //     this.noLicense1 = !this.license1;

    //     return false;
    //   }
    //   this.infoErrors = false;
    //   this.noEmail =
    //     this.noPhone =
    //     this.noFirst =
    //     this.noLast =
    //     this.noAddress =
    //     this.noCity =
    //     this.noZip =
    //     this.noLicense1 =
    //       false;
    //   return true;
    // },

    // checkCode() {
    //   console.log("Starting checkCode...");
    //   return new Promise((resolve, reject) => {
    //     this.fullCode = `${this.part1}-${this.part2}-${this.part3}-${this.part4}`;
    //     console.log("Constructed fullCode:", this.fullCode);
    //     $.ajax({
    //       url: `https://trailwaze.info/parkspass/api/addMAnnualPass.php?code=${this.fullCode}`,
    //       method: "GET",
    //       timeout: 0,
    //       headers: { PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0" },
    //       success: (response) => {
    //         console.log("checkCode response:", response);
    //         const responseObject = JSON.parse(response);
    //         if (response.includes("Invalid code")) {
    //           this.doesNotExist = true;
    //           this.alreadyUsed = false;
    //           reject("Invalid code");
    //         } else if (response.includes("Code already used")) {
    //           this.alreadyUsed = true;
    //           this.doesNotExist = false;
    //           reject("Code already used");
    //         } else {
    //           this.orderNum = responseObject.order_number;
    //           localStorage.setItem("orderNum", this.orderNum);
    //           this.registerPage = false;
    //           this.insertBarcodePage = true;
    //           localStorage.setItem("fullCode", this.fullCode);
    //           resolve(responseObject);
    //         }
    //       },
    //       error: (error) => {
    //         console.error("checkCode error:", error);
    //         reject(error);
    //       },
    //     });
    //   });
    // },

    // addBarcode() {
    //   return new Promise((resolve, reject) => {
    //     this.orderNum = localStorage.getItem("orderNum");
    //     $.ajax({
    //       url: `https://trailwaze.info/parkspass/api/addMAnnualBarcode.php?order_number=${this.orderNum}&barcode=${this.barcode}`,
    //       method: "GET",
    //       timeout: 0,
    //       headers: {
    //         PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
    //       },
    //       success: (response) => {
    //         console.log(response);
    //         this.barcodeError = false;
    //         this.barcodeBError = false;
    //         this.insertBarcodePage = false;
    //         this.infoPage = true;
    //         localStorage.setItem("barcode", this.barcode);
    //         resolve(response);
    //       },
    //       error: (error) => {
    //         reject(error);
    //       },
    //     });
    //   });
    // },

    // addInfo() {
    //   if (this.license2 === null) {
    //     this.license2 = "";
    //   }
    //   if (this.license3 === null) {
    //     this.license3 = "";
    //   }
    //   return new Promise((resolve, reject) => {
    //     $.ajax({
    //       url: `https://trailwaze.info/parkspass/api/addMAnnualPassPersonalInfo.php?order_number=${this.orderNum}&email=${this.email}&phone=${this.phone}&first_name=${this.firstName}&last_name=${this.lastName}&address=${this.address}&city=${this.city}&state=Utah&zip=${this.zip}&lp1=${this.license1}&lp2=${this.license2}&lp3=${this.license3}`,
    //       method: "GET",
    //       timeout: 0,
    //       headers: {
    //         PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
    //       },
    //       success: () => {
    //         this.infoPage = false;
    //         this.nextStepsPage = true;
    //         this.currentStep = "nextStepsPage";

    //         localStorage.setItem("email", this.email);
    //         localStorage.setItem("phone", this.phone);

    //         localStorage.setItem("firstName", this.firstName);
    //         localStorage.setItem("lastName", this.lastName);
    //         localStorage.setItem("address", this.address);
    //         localStorage.setItem("city", this.city);
    //         localStorage.setItem("state", this.state);
    //         localStorage.setItem("zip", this.zip);
    //         localStorage.setItem("license1", this.license1);

    //         resolve();
    //       },
    //       error: (error) => {
    //         reject(error);
    //       },
    //     });
    //   });
    // },

    registerAnother() {
      this.registerHome = true;
      this.nextStepsPage = false;
      this.currentStep = "registerHome";
      localStorage.clear();
      localStorage.setItem("currentStep", this.currentStep);
    },
    goToAndroid() {
      window.location.href =
        "http://play.google.com/store/apps/details?id=edu.utahtech.parkspass.utahstateparks";
    },
    goToIOS() {
      window.location.href =
        "https://apps.apple.com/us/app/utah-state-parks/id1638662132";
    },
  },
};
</script>

<style>
.v-list-item__title {
  color: #96a145 !important;
}
.v-list-item:hover {
  background-color: #96a14520;
}
.v-list-item--highlighted {
  background-color: #96a14540;
}
.v-list-item--link:before {
  background-color: none;
}
</style>
<style scoped>
.mobile {
  display: none;
}

.desktop {
  display: flex;
}
.new-label {
  width: 100%;
  margin-bottom: 8px;
  margin-left: 1em;
}
.select-park,
.select-park-mobile {
  z-index: 1;
  color: white;
  padding: 0px 8px !important;
  margin-top: 32px !important;
}
.select-park-mobile {
  margin-top: 6px !important;
}
.v-select ::v-deep(input) {
  font-size: 1.2em !important;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
html,
body {
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
a:link {
  text-decoration: none;
}
.previewImg {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.purchase-pass {
  color: #fff;

  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 75% */
  margin-top: 20px;
}

.twelve-months {
  color: var(--material-theme-sys-light-on-secondary, #fff);
  text-align: center;

  /* material-theme/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-top: 20px;
  margin-bottom: 20px;
}

.register-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  background: #96a145;
  width: 130px;
  height: 40px;
  /* flex-shrink: 0; */
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  cursor: pointer;
}

.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.personal-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-info {
  color: #494e53;

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  margin-bottom: 16px;
}

.copy-text {
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}

.copy-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
@media only screen and (max-width: 625px) {
  .previewImg {
    width: 200px;
  }
}
#previewImgContainer {
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Home Page */
#homeImgContainer {
  /* Full height */
  height: 100vh;
  background-image: url("../assets/images/register-home-large.png");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.homepageContainer {
  max-width: 1200px;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.home1 {
  color: #ffffff;
  font-size: 24px;
  font-family: "boldRoboto";
  margin: 0px;
}
.home2 {
  color: #ffffff;
  font-size: 64px;
  font-family: "boldRoboto";
  margin: 0px;
  line-height: 72px;
  /* padding-top: 24px; */
}
.home3 {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 30px;  */
}
.navbar {
  position: absolute;
  top: 68px;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.logo,
.logoSmall {
  width: auto;
  height: 62px;
}
.logoSmall {
  vertical-align: middle;
  width: auto;
  margin-top: 14px;
  cursor: pointer;
  margin-left: 20px;
}
.centered-home {
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: 700px;
  font-family: "boldRoboto";
  z-index: 1;
  padding-top: 200px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.destinationsBtn {
  display: inline;
  font-size: 24px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.helpBtn {
  float: right;
  height: 24px;
  width: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 16px;
}
.searchContainer {
  position: relative;
  top: 32px;
  bottom: 0;
  width: 600px;
  margin: 0 auto;
  display: block;
}
.searchWrapper {
  position: relative;
  bottom: 0;
  height: 170px;
}
.singleBar:focus {
  outline: 0 none;
}
.search-page-wrap {
  padding: 8px;
  box-sizing: border-box;
}
.locationsWrap {
  background-color: white;
  width: 420px;
  height: 34vh;
  min-height: 200px;
  -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
  z-index: 50000;
  position: relative;
  padding-top: 0px;
  text-align: left;
  margin: 0 auto;
  border-radius: 20px;
}
.locationTextWrap {
  width: 100%;
  height: auto;
  padding: 0px;
}
.locationText {
  font-size: 24px;
  line-height: 32px;
  margin: -16px 0px;
  padding: 8px 4px;
  color: #96a145;
  list-style: none;
  cursor: pointer;
  padding-left: 26px;
}
.locationText:hover {
  background: #eaecda;
}
.search {
  background: #96a145;
  color: white;
  font-size: 24px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 26px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  height: 72px;
  width: 356px;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
.searchHeader {
  margin-top: 0px;
  color: white;
  font-size: 18px;
  margin-left: 16px;
  display: inline;
  padding-top: 16px;
}
.white-x {
  float: right;
  vertical-align: middle;
  height: 24px;
  margin-right: 16px;
  margin-top: -2px;
}
.mobileSearch {
  background: #96a145;
  color: white;
  font-size: 20px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 20px;
  height: 56px;
  width: 100%;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  padding-left: 26px !important;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.footer {
  width: 100%;
  height: auto;
  background: #1e252b;
  position: relative;
  vertical-align: middle;
  text-align: center;
  bottom: 0;
  padding-bottom: 10px;
}
.footerText {
  color: white;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}
.footerText a {
  font-family: "boldRoboto";
  font-size: 16px;
  cursor: pointer;
}
.social-button {
  background: #96a145;
  color: white;
  font-size: 18px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  padding: 4px 24px;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}
.homeMessage,
.homeMessage2 {
  width: 564px;
  height: 72px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  position: relative;
  padding: 0px 16px;
  padding-top: 2px;
  display: table-cell;
  vertical-align: middle;
}
.homeMessage2 {
  position: absolute;
  bottom: 30px;
  color: white;
  display: table;
}
.searchBtn {
  position: absolute;
  top: 0;
  height: 48px;
  width: 48px;
  background-color: #96a145;
  display: inline-block;
  cursor: pointer;
}
.searchImg {
  margin-top: 16px;
  margin-left: 16px;
  height: 15px;
  width: 15px;
}
.homeMessage p {
  color: white;
  font-size: 14px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  transform: translate(0, -50%);
}
.blackRect {
  width: 100%;
  height: 112px;
  background-color: #000000;
  display: block;
  position: relative;
  margin-top: -4px;
}
.blackRectLogo {
  color: white;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translate(0, -50%);
  height: 56px;
}
.blackRectLogo b {
  font-size: 18px;
  font-family: "boldRoboto";
}
.center {
  text-align: center;
}

h1 {
  font-family: "boldRoboto";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.86);
}
h2 {
  font-family: "boldRoboto";
  font-size: 36px;
}

.parkOption {
  width: 100%;
  padding: 12px;
  margin: 12px 0px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}
.parkOptionTitle {
  display: inline-block;
  font-size: 16px;
  font-family: "boldRoboto";
}
.arrow {
  float: right;
  height: 6px;
  margin-top: 8px;
  transform: rotate(180deg);
}
.flipArrow {
  transform: rotate(0deg);
}
.mapBanner {
  margin: 0 auto;
  margin-top: 32px;
}
#mapContainer {
  height: 80vh;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-top: 32px;
}
.mapIframe {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: block;
}
.insta {
  background: #1e252b;
  padding: 20px;
}
#instaHead {
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.instaText {
  color: #ffffff;
}
.instaMid {
  display: -webkit-box;
  width: 310px;
  margin: auto;
  margin-top: 10px;
}
.instaIcon {
  padding: 5px 12px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 4px 20px;
}
#signUpButton {
  letter-spacing: 0.3px;
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 40px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 1000;
}

.registration-flex {
  display: flex;
  justify-content: space-between;
}
.grey-box {
  /* height: 289px; */
  /* flex-shrink: 0; */
  /* border-radius: 4px; */
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px 0px 32px;
}

.annual-text {
  width: 70%;
}

.barcode-grey-box {
  /* width: 350px; */
  /* height: 211px; */
  /* flex-shrink: 0; */
  /* border-radius: 4px; */
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 32px 0px 32px;
}

.personal-grey-box {
  width: 100%;
  /* height: 211px; */
  /* flex-shrink: 0; */
  border-radius: 4px;
  background: #f5f5f5;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 32px 32px 32px 32px;
  margin-bottom: 16px;
}

.contact-license {
  width: 50%;
}

.address {
  width: 50%;
  height: 100%;
  margin-left: 1em;
}

.box-wrap {
  display: flex;
  justify-content: center;
}

.check-barcode {
  width: 350px;
  height: 211px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputs {
  width: 72px;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  margin: 0px 5px 0px 5px;
}

.barcode-input {
  width: 317px;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  padding-left: 16px;
  border-bottom: 1px solid #8f8f8f;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.personal-input {
  width: 100%;
  height: 48px;
  /* flex-shrink: 0; */
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  padding-left: 16px;
  border-bottom: 1px solid #8f8f8f;
  color: black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
}

#utah:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.input-margin {
  margin-right: 16px;
}

.license-reqs {
  color: var(--material-theme-sys-light-on-background, #212121);
  /* material-theme/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.registration-wrap {
  border: 1px solid black;
}

.register-pass-img {
  /* margin: 20px 0px 20px 0px; */
  margin: 20px;
  width: 85%;
}

.registration-error {
  color: #d0021b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  margin-bottom: 16px;
}

.registration-error-wrap {
  display: flex;
  flex-wrap: wrap;
}
.next-btn {
  width: 94px;
  height: 40px;
  /* flex-shrink: 0; */
  border-radius: 54px;
  background: #96a145;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copy-button {
  /* width: 98px; */
  padding: 16px 16px 16px 16px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 23px;
  background: #96a145;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration-title {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
}

.steps-full-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.steps-container {
  width: 65%;
  height: 100vh;
  margin-left: 32px;
  margin-right: 32px;
  padding: 32px 32px 32px 128px;
}

.order-num-container {
  width: 35%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.order-num-wrap {
  border-radius: 4px;
  border: 1px solid #8f8f8f;
  padding: 16px;
}

.steps-wrap {
  border-radius: 4px;
  border: 1px solid #8f8f8f;
  padding: 16px;
  margin-bottom: 32px;
}

.pass-now-registered {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}

.order-num {
  color: #494e53;

  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
}

.order-num-text {
  color: #494e53;

  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
}

.order-num-title {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
}

.steps-wrap {
  color: #494e53;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.steps-title {
  color: #494e53;

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 220% */
}

.steps-subtitle {
  color: #494e53;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
}

.order-num-container {
  padding: 32px;
}

.help {
  display: flex;
  justify-content: center;
  color: #494e53;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.contact-us {
  color: #96a145;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-decoration-line: underline;
  margin-left: 5px;
}

.another-pass {
  color: #96a145;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  cursor: pointer;
  border-radius: 23px;
  border: 2px solid #96a145;
  width: 197px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .code-page {
  height: 100vh;
  border: 1px solid black;
} */

/* .barcode-page {
  height: 100vh;
  border: 1px solid black;
} */

.barcode-wrap {
  border: 1px solid black;
}
@media only screen and (max-width: 940px) {
  .inputs {
    width: 48px;
    height: 48px;
    /* flex-shrink: 0; */
    border-radius: 4px 4px 0px 0px;
    background: #fff;
    text-align: center;
    border-bottom: 1px solid #8f8f8f;
    margin: 0px 5px 0px 5px;
  }
}

@media only screen and (max-width: 850px) {
  /*.annual-text {
     width: 100%;
  }*/

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .steps-full-flex {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .order-num-container {
    width: 100%;
    height: auto;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
  }

  .steps-container {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: 32px 32px 32px 32px;
  }
  .registration-flex {
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
  }

  .grey-box {
    height: 144px;
  }

  .barcode-grey-box {
    height: 144px;
  }

  .inputs {
    width: 72px;
  }

  #instaHead {
    font-size: 24px;
  }
  .buttonContainer {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  #mapContainer {
    width: 90%;
    text-align: center;
  }
  .passMargin {
    margin-left: 16px;
  }
  .passTextRed {
    font-size: 16px;
    left: 16px;
    right: 16px;
  }
  .shopifyBox2 a {
    font-size: 16px;
  }
  .locationsWrap {
    height: 30vh;
  }
  .search {
    width: -webkit-fill-available;
    height: 48px;
    font-size: 16px;
    background-size: 16px;
  }
  .locationText {
    font-size: 16px;
    line-height: 24px;
  }
  .searchContainer {
    width: 90%;
  }
  .locationsWrap {
    width: 100%;
  }
  #homeImgContainer {
    background-image: url("../assets/images/register-home-small.png");
    height: 100vh;
    overflow-y: hidden !important;
  }
  .home1,
  .home3 {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    margin-bottom: 10px;
  }
  .home2 {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;
    /* material-theme/display/small */
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 122.222% */
  }

  .twelve-months {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;

    /* material-theme/body/large */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }

  .purchase-pass {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;

    /* material-theme/title/medium */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }

  .register-page {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    /* height: 100vh; */
  }

  .info-page {
    display: flex;
    flex-direction: column;
    padding: 32px;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
}

@media only screen and (max-width: 450px) {
  .inputs {
    width: 55px;
  }
}
/* Galaxy fold */
@media only screen and (max-width: 345px) {
  .instaMid {
    width: 85%;
    margin: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .annual-text {
    width: 100%;
    margin-bottom: 16px;
  }
  .first-last-names {
    flex-direction: column;
  }

  .city-state-zip {
    flex-direction: column;
  }
  #overlay-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
  }
  .home2 {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;

    /* material-theme/display/small */
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 122.222% */
  }

  .home3 {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
  }

  .purchase-pass {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;

    /* material-theme/title/medium */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  .twelve-months {
    color: var(--material-theme-sys-light-on-secondary, #fff);
    text-align: center;

    /* material-theme/body/large */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
}
@media only screen and (max-height: 1000px) {
  .centered-home {
    margin-top: 0px;
    padding-top: 120px;
  }
}
@media only screen and (max-height: 800px) {
  .centered-home {
    margin-top: 0px;
    padding-top: 20px;
  }
}
@media only screen and (max-height: 1200px) {
  .locationsWrap {
    max-height: 30vh;
  }
}
@media only screen and (max-height: 900px) {
  .locationsWrap {
    max-height: 20vh;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  #homeImgContainer {
    background-image: url("../assets/images/register-home-large.png");
    height: 100vh;
  }
}
</style>
